export const ccfRoutes = {
  COMPANY_REPORTS: '/company-reports',
  COMPANY_REPORT_ACCESS: '/company-reports/access',
  COMPANY_REPORT: '/company-reports/:report_uuid/summary',
  FULL_REPORT: '/company-reports/:report_uuid/report',
  REPORT_DOWNLOAD: '/company-reports/:report_uuid/report/download',
  IMPORT_FOOD: '/company-reports/:report_uuid/food-and-drink/import',
  IMPORTING_FOOD:
    '/company-reports/:report_uuid/food-and-drink/import/importing',
  IMPORT_DOWNLOAD:
    '/company-reports/:report_uuid/food-and-drink/import/download',
  FOOD: '/company-reports/:report_uuid/food',

  // NEW
  FOOD_IMPORTS_LIST: '/company-reports/:report_uuid/food/imports',
  FOOD_IMPORTS_REVIEW:
    '/company-reports/:report_uuid/food-and-drink/import/review/:import_uuid',

  REPORT_SETUP: '/company-reports/setup',
  REPORT_SETUP_WELCOME: '/company-reports/welcome',
  STATIONARY_COMBUSTION: '/company-reports/:report_uuid/stationary-combustion',
  MOBILE_COMBUSTION: '/company-reports/:report_uuid/mobile-combustion',
  OTHER_GAS: '/company-reports/:report_uuid/direct-gas',
  ELECTRICITY: '/company-reports/:report_uuid/electricity',
  HEATING: '/company-reports/:report_uuid/heating-and-steam',
  SITES: '/sites',
  ELECTRICITY_SUPPLIES: '/sites/electricity-supplies',
  REPORT_SETUP_SITES: '/company-reports/:report_uuid/sites',
  PUBLISH: '/company-reports/:report_uuid/publish',
  PUBLISH_REVIEW_SCOPE_1:
    '/company-reports/:report_uuid/publish/review-scope-1',
  PUBLISH_REVIEW_SCOPE_2:
    '/company-reports/:report_uuid/publish/review-scope-2',
  PUBLISH_REVIEW_SCOPE_3:
    '/company-reports/:report_uuid/publish/review-scope-3',
  REPORT_RESULT: '/company-reports/:report_uuid/report',
};
