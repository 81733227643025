import useSimpleDialog from '@app/hooks/use-simple-dialog';
import { useGetFoodActivityList } from '@shared/api/hooks/ccf/activities/food';
import {
  CompanyReportsActivitiesFoodListSortItem,
  FoodActivitiesList,
  InputModeEnum,
  PaginatedFoodActivitiesListListFilters,
} from '@shared/api/types';
import { DataTable } from '@shared/components/data-table/data-table';
import { DataTableToolbar } from '@shared/components/data-table/data-table-toolbar';
import { useServerDataTable } from '@shared/components/data-table/hooks/use-server-data-table';
import { baseTableSchema } from '@shared/components/data-table/schema';
import {
  DataTableFilterField,
  DataTableProps,
} from '@shared/components/data-table/types';
import { Row } from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { z } from 'zod';
import { useCCF } from '../../ccf-context';
import CCFFoodEditForm from './ccf-food-edit-form';
import { foodTableColumns } from './ccf-food-table-columns';
import FoodTableToolbar from './ccf-food-table-toolbar';

interface FoodTableProps extends Partial<DataTableProps> {}

export const schema = baseTableSchema.extend({
  food: z.string().optional(),
});

export default function CCFFoodTable({
  enableRowClick = true,
}: FoodTableProps) {
  const [filters, setFilters] =
    useState<PaginatedFoodActivitiesListListFilters>({});
  const { showSimpleDialog, SimpleDialogComponent, closeSimpleDialog } =
    useSimpleDialog();
  const [selectedActivityUuid, setSelectedActivityUuid] =
    useState<FoodActivitiesList['uuid']>();
  const [selectedRow, setSelectedRow] = useState<
    Row<FoodActivitiesList> | undefined
  >();

  const [searchParams] = useSearchParams();
  const search = schema.parse(Object.fromEntries(searchParams));
  const { uuid: reportUuid } = useCCF();

  const supplier = searchParams.get('supplier')?.split('.');
  const period = searchParams.get('period')?.split('.');
  const { data, isPreviousData, isFetchedAfterMount, isLoading, isError } =
    useGetFoodActivityList(reportUuid!, {
      page: search.page,
      page_size: search.page_size,
      suppliers: supplier,
      time_periods: period,
      search: search.food || '',
      sort: [search.sort] as CompanyReportsActivitiesFoodListSortItem[],
    });

  if (isError) throw new Error();

  const { results: activities, count } = data || {};

  const pageCount = count ? Math.ceil(count / (search.page_size || 10)) : 0;

  const useFilterOptions = useMemo(() => {
    const supplierFilter = filters.suppliers?.map((filter) => {
      return {
        label: filter.name,
        value: filter.uuid,
      };
    });

    const timePeriodFilter = filters.timePeriods?.map((filter) => {
      return {
        label: filter.name,
        value: filter.uuid,
      };
    });

    return {
      suppliers: supplierFilter || [],
      timePeriods: timePeriodFilter || [],
    };
  }, [filters]);

  useEffect(() => {
    if (data && !isPreviousData && isFetchedAfterMount) {
      setFilters({
        suppliers: data.filters?.suppliers,
        timePeriods: data.filters?.timePeriods,
      });
    }
  }, [data, isFetchedAfterMount, isPreviousData]);

  const filterFields: DataTableFilterField<FoodActivitiesList>[] = [
    {
      label: 'Name',
      value: 'food',
      placeholder: 'Search item...',
    },
    {
      label: 'Supplier',
      value: 'supplier',
      options: useFilterOptions.suppliers,
    },

    {
      label: 'Time period',
      value: 'period',
      options: useFilterOptions.timePeriods,
    },
  ];

  const { table } = useServerDataTable({
    data: activities || [],
    columns: foodTableColumns,
    pageCount,
    filterFields,
    defaultPerPage: 10,
    rowId: 'uuid',
    columnVisibilityState: {
      uuid: false,
      period: false,
    },
  });

  return (
    <>
      <DataTable
        table={table}
        enablePagination
        className="bg-gray-00"
        rowClassName={() =>
          enableRowClick ? 'cursor-pointer hover:bg-gray-50' : 'cursor-default'
        }
        onRowClick={(row) => {
          if (!enableRowClick) return;
          setSelectedActivityUuid(row?.original.uuid);
          setSelectedRow(row);
          showSimpleDialog();
        }}
        isLoading={(!isFetchedAfterMount && isPreviousData) || isLoading}
      >
        <DataTableToolbar table={table} filterFields={filterFields}>
          <FoodTableToolbar />
        </DataTableToolbar>
      </DataTable>
      {SimpleDialogComponent({
        header: 'Edit food & drink item',
        content: (
          <CCFFoodEditForm
            foodActivityUuid={selectedActivityUuid}
            closeDialog={closeSimpleDialog}
            mode={selectedRow?.original.inputMode as InputModeEnum}
          />
        ),
      })}
    </>
  );
}
