import { ccfRoutes } from '@app/ccf/ccf-routes';
import Page from '@app/components/layout/page/page';
import { useIntercom } from '@app/services/intercom/IntercomContext';
import { Icons } from '@shared/components/content/icons';
import Text from '@shared/components/content/text';
import TextLink from '@shared/components/content/text-link';
import GuideTemplate from '@shared/components/guide-template';
import { generatePath, useNavigate } from 'react-router-dom';
import { useFoodImport } from '../food-import-layout';

export default function FoodDownloadTemplatePage() {
  const { reportUuid } = useFoodImport();
  const { showIntercomArticle } = useIntercom();
  const navigate = useNavigate();
  const goToNextPage = () =>
    navigate(generatePath(ccfRoutes.IMPORT_FOOD, { report_uuid: reportUuid }));

  return (
    <Page name="Food and drink import begin page">
      <GuideTemplate
        preTitle="IMPORT FOOD PURCHASES"
        title="The import process"
        description={
          <div className="space-y-4 ">
            <Text>
              Our food and drink import tool is designed to make the process as
              simple as possible for you. We highly recommend reading our{' '}
              <TextLink onClick={() => showIntercomArticle('foodImport')}>
                data guide
              </TextLink>{' '}
              before starting for the first time.
            </Text>
          </div>
        }
        primaryActionProps={{
          onClick: goToNextPage,
        }}
        primaryActionText="I'm ready to import"
      >
        <div className="flex space-x-1 py-12">
          <div className="flex flex-1 flex-col items-center rounded-lg border border-teal-200 bg-gray-00 px-4 py-6 shadow-lg">
            <div className="mb-3 flex items-center space-x-2">
              <Icons.upload className="size-5 text-secondary-500" />
              <Text className="text-md font-medium">
                Upload your spreadsheet
              </Text>
            </div>
            <Text>
              Select the relevant columns to upload,{' '}
              <span className="font-semibold">
                we require a name column and a quantity or total weight column
              </span>
              . The data in these columns can be in any format.
            </Text>
          </div>
          <div className="flex  items-center justify-center">
            <Icons.chevronRight className="size-8 text-teal-500" />
          </div>
          <div className="flex flex-1 flex-col items-center rounded-lg border border-teal-200 bg-gray-00 px-4 py-6 shadow-lg">
            <div className="mb-3 flex items-center space-x-2">
              <Icons.coffee className="size-5 text-secondary-500" />
              <Text className="text-md font-medium">
                Let us process your data
              </Text>
            </div>
            <Text>
              Our AI-powered import tool will process your upload, extracting
              relevant data and converting units to our standard format. Come
              back when it has finished!
            </Text>
          </div>
          <div className="flex  items-center justify-center">
            <Icons.chevronRight className="size-8 text-teal-500" />
          </div>
          <div className="flex flex-1 flex-col items-center rounded-lg border border-teal-200 bg-gray-00 px-4 py-6 shadow-lg">
            <div className="mb-3 flex items-center space-x-2">
              <Icons.check className="size-5 text-secondary-500" />
              <Text className="text-md font-medium">Review and import</Text>
            </div>
            <Text>
              You can review the processed data, checking any estimated unit
              weights and make any corrections before adding it to your report.
            </Text>
          </div>
        </div>
      </GuideTemplate>
    </Page>
  );
}
