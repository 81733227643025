import {
  ActivityImportList,
  ActivityImportStatusEnum,
  TimePeriod,
} from '@shared/api/types';
import { Icons } from '@shared/components/content/icons';
import { HeaderCell } from '@shared/components/data-table/cells/header-cell';
import { Progress } from '@shared/components/ui/progress';
import { formatDate, getObjectFromArrayWithKey } from '@shared/lib/utils';
import { ColumnDef } from '@tanstack/react-table';
import { ImportRowActions } from './food-import-row-actions';

export function foodImportTableColumns(
  periodOptions: TimePeriod[]
): ColumnDef<ActivityImportList>[] {
  return [
    {
      accessorKey: 'uuid',
      enableHiding: true,
    },

    {
      accessorKey: 'timePeriod',
      accessorFn: (row) =>
        getObjectFromArrayWithKey('uuid', row.timePeriod, periodOptions),
      header: ({ column }) => <HeaderCell column={column} name="Period" />,
      cell: ({ getValue }) => {
        const name = getValue<TimePeriod>().name;
        return <span>{name}</span>;
      },
    },
    {
      accessorKey: 'created',
      header: ({ column }) => <HeaderCell column={column} name="Upload date" />,
      cell: ({ getValue }) => {
        const date = getValue<string>();
        return <span>{formatDate(date, 'do MMM yyyy')}</span>;
      },
    },
    {
      accessorKey: 'numberOfRows',
      header: ({ column }) => (
        <HeaderCell column={column} name="Number of rows" />
      ),
    },
    {
      accessorKey: 'status',
      header: ({ column }) => <HeaderCell column={column} name="Status" />,
      cell: ({ getValue, row }) => {
        const status = getValue<ActivityImportStatusEnum>();
        const {
          original: { numberOfRows, numberOfProcessedRows },
        } = row;
        const completionPercentage = Math.floor(
          (Number(numberOfProcessedRows) * 100) / Number(numberOfRows)
        );
        switch (status) {
          case 'processing':
            if (completionPercentage === 0) {
              return (
                <div className="flex flex-row items-center space-x-2">
                  <Icons.clock className="size-5 text-gray-500" />
                  <span className="text-sm">Queued for processing</span>
                </div>
              );
            }
            return (
              <div className="flex flex-row items-center space-x-2">
                <Progress
                  value={completionPercentage}
                  indicatorBgColor="bg-teal-400"
                />
                <span className="w-8 text-right text-sm text-gray-600">
                  {completionPercentage}%
                </span>
              </div>
            );
          case 'processed':
            return (
              <div className="flex flex-row items-center space-x-2">
                <Icons.listChecks className="size-5 text-secondary-500" />
                <span className="text-sm">Ready to review</span>
              </div>
            );
          case 'completed':
            return (
              <div className="flex flex-row items-center space-x-2">
                <Icons.check className="size-5 text-secondary-500" />
                <span className="text-sm">Imported</span>
              </div>
            );
          case 'import failed':
            return (
              <div className="flex flex-row items-center space-x-2">
                <Icons.x className="size-5 text-error-500" />
                <span className="text-sm">Import failed</span>
              </div>
            );
          case 'processing failed':
            return (
              <div className="flex flex-row items-center space-x-2">
                <Icons.x className="size-5 text-error-500" />
                <span className="text-sm">Failed to process</span>
              </div>
            );
          case 'importing':
            return (
              <div className="flex flex-row items-center space-x-2">
                <Icons.loader2 className="size-5 animate-spin text-warning-500" />
                <span className="text-sm">Importing...</span>
              </div>
            );
        }
      },
    },
    {
      accessorKey: 'actions',
      header: () => null,
      cell: ({ row }) => {
        const { status, uuid: importUuid } = row.original;
        return <ImportRowActions importUuid={importUuid} status={status!} />;
      },
    },
  ];
}
