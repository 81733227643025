export const articles = {
  whatIsCCF: '10116423',
  reportingPeriod: '10279619',
  managingSites: '10393662',
  electricitySupplies: '10393857',
  scope1: '10117368',
  scope2: '10117571',
  scope3: '10117592',
  stationaryCombustion: '10371104',
  mobileCombustion: '10371331',
  directGas: '10374513',
  electricity: '10374580',
  heating: '10374711',
  food: '10279681',
  foodImport: '10295931',
  foodMatching: '10296459',
};

export const tours = {};

export const checklists = {};

export type IntercomArticle = keyof typeof articles;

export type IntercomTour = keyof typeof tours;

export type IntercomChecklist = keyof typeof checklists;
