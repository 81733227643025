import { useUnitOptions } from '@app/ccf/reports/utils';
import { zodResolver } from '@hookform/resolvers/zod';
import { useGetAvailableIngredients } from '@shared/api';
import { useGetActivityTypeUnits } from '@shared/api/hooks/ccf/units';
import { ImportRowProcess, InputModeEnum } from '@shared/api/types';
import Text from '@shared/components/content/text';
import InputFormField from '@shared/components/form/input-form-field';
import SelectFormField from '@shared/components/form/select-form-field';
import Loader from '@shared/components/loader';
import { Button } from '@shared/components/ui/button';
import { Form } from '@shared/components/ui/form';
import { useToast } from '@shared/components/ui/use-toast';
import sentry from '@shared/services/sentry';
import { Row, Table } from '@tanstack/react-table';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { FoodImportReviewRow } from './food-import-review-table';

interface FoodImportReviewEditFormProps {
  table: Table<FoodImportReviewRow>;
  data: FoodImportReviewRow[];
  closeDialog: () => void;
  mode: InputModeEnum;
  row?: Row<FoodImportReviewRow>;
}

export const baseSchema = z.object({
  name: z.string().min(1),
  remoteId: z.string().optional().nullable(),
  supplier: z.string().optional().nullable(),
  inputUnit: z.string().min(1).nullable(),
  itemUnit: z.string().min(1),
});

const totalWeightSchema = baseSchema.extend({
  inputTotalWeight: z.coerce.number().min(0.001),
  totalMassOrVolume: z.coerce.number().min(0.001),
});

const packSchema = baseSchema.extend({
  inputQuantity: z.string().nullable(),
  quantity: z.coerce.number().min(0.001),
  itemMassOrVolume: z.coerce.number().min(0.001),
  itemsPerPack: z.coerce.number().min(1),
  totalWeight: z.coerce.number().optional(),
});

export default function FoodImportReviewEditForm({
  data,
  table,
  closeDialog,
  row,
  mode,
}: FoodImportReviewEditFormProps) {
  const { toast } = useToast();
  const tableMeta = table.options.meta;
  const schema = mode === 'packs' ? packSchema : totalWeightSchema;

  const { data: units, isLoading: isUnitsLoading } =
    useGetActivityTypeUnits('food');
  const formattedUnits = useUnitOptions(units!);

  const getUnitObject = (unit: string) => {
    return units?.find((u) => u.uuid === unit);
  };

  const rowData = {
    ...row?.original,
    itemUnit: row?.original.itemUnit?.uuid,
  };

  const {
    data: availableIngredients,
    isLoading: availableIngredientsLoading,
    error: availableIngredientsError,
  } = useGetAvailableIngredients();

  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: rowData!,
  });
  const { reset } = form;

  const onSubmit = async (data: z.infer<typeof schema>) => {
    const updatedTotalWeightData: ImportRowProcess = {
      ...rowData,
      totalMassOrVolume: data.totalMassOrVolume,
      itemUnit: getUnitObject(data.itemUnit),
      missingWeight: !data.totalMassOrVolume,
    };

    const updatedPacksData: ImportRowProcess = {
      ...rowData,
      quantity: data.quantity,
      itemsPerPack: data.itemsPerPack,
      itemMassOrVolume: data.itemMassOrVolume,
      itemUnit: getUnitObject(data.itemUnit),
      missingWeight:
        !data.quantity || !data.itemsPerPack || !data.itemMassOrVolume,
    };

    const updateData =
      mode === 'packs' ? updatedPacksData : updatedTotalWeightData;

    try {
      await tableMeta?.updateRow(row!.index, updateData);
      toast({
        title: 'Item updated',
        variant: 'success',
      });
      closeDialog();
    } catch (error) {
      sentry.log(error);
      toast({
        title: 'Failed to update item',
        variant: 'destructive',
      });
    }
  };

  const handleDelete = async () => {
    try {
      await tableMeta?.deleteRow(row!.index);
      toast({
        title: 'Row removed from import',
        variant: 'success',
      });
      closeDialog();
    } catch (error) {
      sentry.log(error);
      toast({
        title: 'Failed to remove row',
        variant: 'destructive',
      });
    }
  };

  if (!availableIngredients && !availableIngredientsLoading) {
    throw new Error(
      `Failed to load available ingredients for ingredient rows: ${availableIngredientsError || 'No data'}`
    );
  }

  if (!data || availableIngredientsLoading) {
    return <Loader />;
  }

  return (
    <Form {...form}>
      <form className="space-y-6" onSubmit={form.handleSubmit(onSubmit)}>
        <div className="space-y-3">
          <div className="pb-1">
            <Text className="text-md font-medium text-primary-700">
              Uploaded data
            </Text>
            <Text variant="subtle">
              The data as it was uploaded from your spreadsheet
            </Text>
          </div>
          <div className="grid grid-cols-12 gap-2">
            <InputFormField
              name="name"
              label="Food name"
              className="col-span-6"
              data-1p-ignore
            />
            <InputFormField
              name="remoteId"
              label="Your ID"
              className="col-span-2 max-w-fit"
              includeErrorMessage
            />
            <InputFormField
              name="supplier"
              label="Supplier"
              className="col-span-4"
            />
          </div>
          <div className="grid grid-cols-12 gap-2">
            <InputFormField
              name={mode === 'packs' ? 'inputQuantity' : 'inputTotalWeight'}
              label={mode === 'packs' ? 'Quantity' : 'Total weight'}
              className="col-span-3"
              disabled
              includeErrorMessage
            />
            <InputFormField
              name="inputUnit"
              label="Unit"
              className="col-span-3"
              disabled
            />
          </div>
        </div>

        <div className="space-y-3">
          <div className="pb-1">
            <Text className="text-md font-medium text-primary-700">
              Processed data
            </Text>
            <Text variant="subtle">
              The data we have interpreted from your upload and will use to
              calculate the emissions
            </Text>
          </div>
          <div className="grid grid-cols-12 gap-2">
            {mode === 'packs' && (
              <>
                <InputFormField
                  name="quantity"
                  label="Packs purchased"
                  className="col-span-3"
                />
                <InputFormField
                  name="itemsPerPack"
                  label="Items per pack"
                  className="col-span-3"
                />
                <InputFormField
                  name="itemMassOrVolume"
                  label="Item weight"
                  className="col-span-3"
                  type="number"
                />

                <SelectFormField
                  name="itemUnit"
                  label="Item unit"
                  options={formattedUnits}
                  useShortLabel
                  className="col-span-3"
                  loading={isUnitsLoading}
                  disabled={isUnitsLoading || !units}
                />
              </>
            )}
            {mode === 'total-weight' && (
              <>
                <InputFormField
                  name="totalMassOrVolume"
                  label="Total weight"
                  className="col-span-3"
                />
                <SelectFormField
                  name="itemUnit"
                  label="Unit"
                  options={formattedUnits}
                  useShortLabel
                  className="col-span-3"
                  loading={isUnitsLoading}
                  disabled={isUnitsLoading || !units}
                />
              </>
            )}
          </div>
        </div>

        <div className="mt-12 flex justify-between">
          <div className="col-span-4">
            <Button
              type="button"
              size="lg"
              variant="outline-destructive"
              onClick={handleDelete}
            >
              Remove row
            </Button>
          </div>
          <div className="flex space-x-2">
            <div className="col-span-4">
              <Button
                type="button"
                size="lg"
                variant="secondary"
                onClick={() => {
                  closeDialog();
                  reset();
                }}
              >
                Cancel
              </Button>
            </div>
            <div className="col-span-4">
              <Button type="submit" size="lg">
                Update
              </Button>
            </div>
          </div>
        </div>
      </form>
    </Form>
  );
}
