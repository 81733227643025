import { useAxiosConfig } from '@shared/api/AxiosConfigProvider';
import {
  getCompanyReportsActivitiesFoodListQueryKey,
  getCompanyReportsRetrieveQueryKey,
  useCompanyReportsActivitiesDestroy,
  useCompanyReportsActivitiesFoodList,
  useCompanyReportsActivitiesFoodRetrieve,
  useCompanyReportsActivitiesFoodUpdate,
  useCompanyReportsImportsFoodCreate,
} from '@shared/api/lib/company-reports/company-reports';
import {
  CompanyReportsActivitiesFoodListParams,
  FoodActivityRetrieve,
  FoodActivityUpdate,
  PaginatedFoodActivitiesListList,
} from '@shared/api/types';
import { useQueryClient } from '@tanstack/react-query';

export const useGetFoodActivityList = (
  companyReportUuid: string,
  params?: CompanyReportsActivitiesFoodListParams
) => {
  return useCompanyReportsActivitiesFoodList<PaginatedFoodActivitiesListList>(
    companyReportUuid,
    params,
    {
      request: useAxiosConfig(),
      query: {
        keepPreviousData: true,
        cacheTime: 1000 * 60 * 60 * 24,
      },
    }
  );
};

export const useGetFoodActivity = ({
  foodActivityUuid,
  reportUuid,
  params,
}: {
  foodActivityUuid: string;
  reportUuid: string;
  params?: CompanyReportsActivitiesFoodListParams;
}) => {
  return useCompanyReportsActivitiesFoodRetrieve<FoodActivityRetrieve>(
    reportUuid,
    foodActivityUuid,
    params,
    {
      request: useAxiosConfig(),
    }
  );
};

export const useUpdateFoodActivity = () => {
  const queryClient = useQueryClient();
  return useCompanyReportsActivitiesFoodUpdate<FoodActivityUpdate>({
    request: useAxiosConfig(),
    mutation: {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries(
          getCompanyReportsActivitiesFoodListQueryKey(
            variables.companyReportUuid
          )
        );

        queryClient.invalidateQueries(
          getCompanyReportsActivitiesFoodListQueryKey(
            variables.companyReportUuid
          )
        );

        queryClient.removeQueries([
          getCompanyReportsRetrieveQueryKey(variables.companyReportUuid),
        ]);
        queryClient.invalidateQueries(
          getCompanyReportsRetrieveQueryKey(variables.companyReportUuid)
        );
      },
    },
  });
};

export const useDeleteFoodActivity = () => {
  const queryClient = useQueryClient();
  return useCompanyReportsActivitiesDestroy({
    request: useAxiosConfig(),
    mutation: {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries(
          getCompanyReportsActivitiesFoodListQueryKey(
            variables.companyReportUuid
          )
        );
        queryClient.removeQueries(
          getCompanyReportsRetrieveQueryKey(variables.companyReportUuid)
        );
      },
    },
  });
};

export const useCreateFoodImport = () => {
  return useCompanyReportsImportsFoodCreate({
    request: useAxiosConfig(),
  });
};
