import Text from '@shared/components/content/text';

interface MethodologyRowProps {
  methodology: {
    title: string;
    scope1: string;
    scope2: string;
    scope3: string;
  };
  scope?: 'scope1' | 'scope2' | 'scope3';
}

export function MethodologyRow({
  methodology,
  scope = 'scope1',
}: MethodologyRowProps) {
  const { title, scope1, scope2, scope3 } = methodology;
  return (
    <div className="grid grid-cols-9 gap-x-6 gap-y-7">
      <div className="col-span-3">
        {methodology[scope] && (
          <Text className="text-md font-medium text-primary">{title}</Text>
        )}
      </div>
      <div className="col-span-6">
        {scope === 'scope1' && <Text variant="reportBody">{scope1}</Text>}
        {scope === 'scope2' && <Text variant="reportBody">{scope2}</Text>}
        {scope === 'scope3' && <Text variant="reportBody">{scope3}</Text>}
      </div>
    </div>
  );
}
