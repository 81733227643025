import useSimpleDialog from '@app/hooks/use-simple-dialog';
import { useGetElectricityActivityList } from '@shared/api/hooks/ccf/activities/electricity';
import {
  CompanyReportsActivitiesElectricityListSortItem,
  ElectricityActivitiesList,
  PaginatedElectricityActivitiesListListFilters,
} from '@shared/api/types';
import { SupportButton } from '@shared/components/buttons/support-button';
import { DataTable } from '@shared/components/data-table/data-table';
import { DataTableToolbar } from '@shared/components/data-table/data-table-toolbar';
import { useServerDataTable } from '@shared/components/data-table/hooks/use-server-data-table';
import { baseTableSchema } from '@shared/components/data-table/schema';
import { DataTableFilterField } from '@shared/components/data-table/types';
import { Button } from '@shared/components/ui/button';
import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { z } from 'zod';
import { useCCF } from '../../ccf-context';
import ElectricityEditForm from './electricity-edit-form';
import { electricityTableColumns } from './electricity-table-columns';

export const schema = baseTableSchema.extend({
  uuid: z.string().optional(),
});

export default function ElectricityTable() {
  const { uuid: reportUuid } = useCCF();
  const [filters, setFilters] =
    useState<PaginatedElectricityActivitiesListListFilters>({});
  const [selectedActivityUuid, setSelectedActivityUuid] =
    useState<ElectricityActivitiesList['uuid']>();
  const [searchParams] = useSearchParams();
  const search = schema.parse(Object.fromEntries(searchParams));
  const {
    showSimpleDialog: showElectricityDialog,
    SimpleDialogComponent: ElectricityDialog,
    closeSimpleDialog: closeElectricityDialog,
  } = useSimpleDialog();

  const suppliers = searchParams.get('supplier')?.split('.');
  const time_periods = searchParams.get('period')?.split('.');
  const sites = searchParams.get('site')?.split('.');
  const { data, isPreviousData, isFetchedAfterMount, isLoading, isError } =
    useGetElectricityActivityList(reportUuid!, {
      page: search.page,
      page_size: search.page_size,
      suppliers,
      time_periods,
      sites,
      search: search.uuid || '',
      sort: [search.sort] as CompanyReportsActivitiesElectricityListSortItem[],
    });

  if (isError) throw new Error();

  const { results: activities, count } = data || {};

  const pageCount = count ? Math.ceil(count / (search.page_size || 10)) : 0;

  const filterOptions = useMemo(() => {
    const sites =
      filters.sites?.map((filter) => {
        return {
          label: filter.name,
          value: filter.uuid,
        };
      }) || [];

    return {
      sites,
    };
  }, [filters]);

  useEffect(() => {
    if (data && !isPreviousData && isFetchedAfterMount) {
      setFilters({
        timePeriods: data.filters?.timePeriods,
        sites: data.filters?.sites,
      });
    }
  }, [data, isFetchedAfterMount, isPreviousData]);

  const filterFields: DataTableFilterField<ElectricityActivitiesList>[] = [
    {
      label: 'Search',
      value: 'uuid',
      placeholder: 'Search sites...',
    },
    {
      label: 'Site',
      value: 'site',
      options: filterOptions.sites,
    },
  ];

  const { table } = useServerDataTable({
    data: activities || [],
    columns: electricityTableColumns,
    pageCount,
    filterFields,
    defaultPerPage: 10,
    rowId: 'uuid',
    columnVisibilityState: {
      uuid: false,
      period: false,
    },
  });

  return (
    <>
      <DataTable
        table={table}
        enablePagination
        className="bg-gray-00"
        onRowClick={(row) => {
          setSelectedActivityUuid(row?.original.uuid);
          showElectricityDialog();
        }}
        isLoading={(!isFetchedAfterMount && isPreviousData) || isLoading}
      >
        <DataTableToolbar table={table} filterFields={filterFields}>
          <SupportButton article="electricity" size="sm" />
          <Button
            size="sm"
            onClick={() => {
              setSelectedActivityUuid(undefined);
              showElectricityDialog();
            }}
          >
            Add data
          </Button>
        </DataTableToolbar>
      </DataTable>
      {ElectricityDialog({
        header: 'Add data',
        content: (
          <ElectricityEditForm
            activityUuid={selectedActivityUuid}
            closeDialog={closeElectricityDialog}
          />
        ),
      })}
    </>
  );
}
