import { ccfRoutes } from '@app/ccf/ccf-routes';
import Navbar from '@app/components/layout/nav/navbar';
import { useDownloadReportResults } from '@shared/api/hooks/ccf/results';
import { Icons } from '@shared/components/content/icons';
import { Button } from '@shared/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@shared/components/ui/dropdown-menu';
import { downloadCSV } from '@shared/lib/utils';
import sentry from '@shared/services/sentry';
import { useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { useCCFReportResult } from '../ccf-report-result-context';

export default function CcfReportLayoutHeader() {
  const navigate = useNavigate();
  const {
    result: { reportUuid, title },
  } = useCCFReportResult();
  const [isDownloadCSV, setIsDownloadCSV] = useState(false);

  const { data, refetch } = useDownloadReportResults(reportUuid, {
    enabled: isDownloadCSV,
  });

  async function handleDownloadCSV() {
    setIsDownloadCSV(true);
    try {
      const res = !data && (await refetch());
      const CSVdata: string = data || res?.data;
      downloadCSV(CSVdata, `${title}.csv`);
    } catch (error) {
      sentry.log(error);
    } finally {
      setIsDownloadCSV(false);
    }
  }

  const handleDownloadPDF = () => {
    const path = generatePath(ccfRoutes.REPORT_DOWNLOAD, {
      report_uuid: reportUuid,
    });
    window.open(path, '_blank');
  };

  return (
    <header className="flex h-[--header-height] w-full items-center justify-between border-b bg-primary">
      <Navbar
        logo={{
          variant: 'name',
          color: 'light',
        }}
      />
      <div className="flex items-center space-x-2 p-4">
        <Button
          icon={<Icons.chevronLeft className="size-4" />}
          variant="ghost"
          className="hover:bg-transparent text-primary-50 hover:text-primary-300"
          disabled={isDownloadCSV}
          onClick={() =>
            navigate(
              generatePath(ccfRoutes.COMPANY_REPORT, {
                report_uuid: reportUuid,
              })
            )
          }
        >
          Back to dashboard
        </Button>
        <DropdownMenu>
          <DropdownMenuTrigger className="flex items-center" asChild>
            <Button
              variant="outline"
              disabled={isDownloadCSV}
              loading={isDownloadCSV}
            >
              Download
              <Icons.chevronDown className="ml-1 size-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            <DropdownMenuItem onClick={handleDownloadPDF}>
              Download report as PDF
            </DropdownMenuItem>
            <DropdownMenuItem onClick={handleDownloadCSV}>
              Export data as CSV
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </header>
  );
}
