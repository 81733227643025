import { useGetCollections, useUpdateProduct } from '@shared/api';
import { CollectionList } from '@shared/api/types';
import { Icons } from '@shared/components/content/icons';
import { Badge } from '@shared/components/ui/badge';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from '@shared/components/ui/command';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@shared/components/ui/popover';
import { Skeleton } from '@shared/components/ui/skeleton';
import { useToast } from '@shared/components/ui/use-toast';
import sentry from '@shared/services/sentry';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface ProductDetailCollectionsProps {
  productUuid: string;
  collections: CollectionList[];
}

export default function ProductDetailCollections({
  productUuid,
  collections,
}: ProductDetailCollectionsProps) {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState('');
  const { toast } = useToast();
  const navigate = useNavigate();

  const {
    data: collectionsList,
    isLoading: loadingCollectionsList,
    isError: collectionsListError,
  } = useGetCollections();
  const { mutateAsync: updateProduct, isLoading: updatingProduct } =
    useUpdateProduct();

  const filteredCollections = collectionsList?.filter((collection) => {
    return !collections.some((c) => c.uuid === collection.uuid);
  });

  const handleDeleteCollection = async (
    collectionUuid: string,
    collectionName: string
  ) => {
    try {
      await updateProduct({
        productUuid,
        data: {
          collections: collections
            .map((collection) => collection.uuid)
            .filter((uuid) => uuid !== collectionUuid),
        },
      });
      toast({
        variant: 'success',
        title: 'Collection removed',
        description: `This product has been removed from the collection '${collectionName}'`,
      });
    } catch (error) {
      sentry.log(error);
      toast({
        variant: 'destructive',
        title: 'An error occurred',
        description: `An error occurred while removing the product from the collection '${collectionName}'`,
      });
    }
  };

  if (loadingCollectionsList)
    return <Skeleton className="h-6 w-40 rounded-xl bg-primary-200" />;

  return (
    <div className="flex flex-wrap">
      {collections.map((collection) => {
        return (
          <Badge
            key={collection.uuid}
            className="mb-2 mr-2 flex cursor-pointer items-center text-nowrap"
          >
            <span
              className="hover:underline"
              onClick={() => navigate(`/collections/${collection.uuid}`)}
            >
              {collection.name}
            </span>
            <button
              disabled={updatingProduct}
              className="ml-1 rounded-full outline-none ring-offset-background focus:ring-2 focus:ring-ring focus:ring-offset-2"
              onClick={() =>
                handleDeleteCollection(collection.uuid, collection.name)
              }
            >
              <Icons.x className="mt-px size-3 text-muted-foreground hover:text-foreground" />
            </button>
          </Badge>
        );
      })}
      <Popover open={open} onOpenChange={setOpen}>
        {!collectionsListError && (
          <PopoverTrigger>
            <Badge
              variant="outline"
              className="mb-2 cursor-pointer flex-nowrap items-center text-nowrap"
              onClick={() => !updatingProduct && setOpen(true)}
            >
              {updatingProduct
                ? 'Updating collections...'
                : '+ Add to collection'}
            </Badge>
          </PopoverTrigger>
        )}

        <PopoverContent className="w-[200px] p-0">
          <Command
            filter={(value, search) => {
              const nameSearch = filteredCollections
                ?.find((collection) => collection.uuid === value)
                ?.name.toLowerCase();

              if (nameSearch!.includes(search.toLowerCase())) return 1;
              return 0;
            }}
          >
            <CommandInput placeholder="Search collection..." />
            <CommandEmpty>No collection found.</CommandEmpty>
            <CommandGroup>
              {filteredCollections?.map((collection) => (
                <CommandItem
                  key={collection.uuid}
                  value={collection.uuid}
                  disabled={updatingProduct}
                  onSelect={async (currentValue) => {
                    setValue(currentValue === value ? '' : currentValue);
                    try {
                      await updateProduct({
                        productUuid,
                        data: {
                          collections: [
                            ...collections.map((c) => c.uuid),
                            collection.uuid,
                          ],
                        },
                      });
                      toast({
                        variant: 'success',
                        title: 'Collection added',
                        description: `This product has been added to the collection '${collection.name}'`,
                      });
                      setOpen(false);
                    } catch (error) {
                      sentry.log(error);
                      toast({
                        variant: 'destructive',
                        title: 'An error occurred',
                        description: `An error occurred while adding the product to the collection '${collection.name}'`,
                      });
                    }
                  }}
                >
                  {collection.name}
                </CommandItem>
              ))}
            </CommandGroup>
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  );
}
