import { type Table } from '@tanstack/react-table';

import { Icons } from '@shared/components/content/icons';
import { Button } from '@shared/components/ui/button';
import { FoodImportReviewRow } from './food-import-review-table';

interface FoodImportReviewTableToolbarActionsProps {
  table: Table<FoodImportReviewRow>;
  handleImport: () => void;
  loading?: boolean;
  showHelp: () => void;
}

export function FoodImportReviewTableToolbarActions({
  handleImport,
  showHelp,
  loading = false,
  table,
}: FoodImportReviewTableToolbarActionsProps) {
  const { rowsById } = table.getRowModel();
  const rows = Object.values(rowsById);

  const checkIfAllRowsHaveWeight = (): boolean => {
    return rows.every((row) => row.original.missingWeight === false);
  };

  return (
    <div className="flex items-center gap-2">
      <Button
        size="sm"
        variant="outline"
        icon={<Icons.help className="mr-2 size-4" />}
        onClick={showHelp}
      >
        Instructions
      </Button>
      <Button
        size="sm"
        icon={<Icons.download className="mr-2 size-4" aria-hidden="true" />}
        onClick={handleImport}
        loading={loading}
        disabled={!checkIfAllRowsHaveWeight()}
      >
        Import data
      </Button>
    </div>
  );
}
