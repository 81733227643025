import { useGetCompanyReportPeriods } from '@shared/api';
import { useGetActivityTypeUnits } from '@shared/api/hooks/ccf/units';
import { InputModeEnum } from '@shared/api/types';
import { Icons } from '@shared/components/content/icons';
import InputFormField from '@shared/components/form/input-form-field';
import SelectFormField from '@shared/components/form/select-form-field';
import { RepeatableRowComponentProps } from '@shared/components/form/types';
import { Button } from '@shared/components/ui/button';
import { FormLabel } from '@shared/components/ui/form';
import { Skeleton } from '@shared/components/ui/skeleton';
import { cn, getObjectFromArrayWithKey } from '@shared/lib/utils';
import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useCCF } from '../../ccf-context';
import { useGetPeriodOptions, useUnitOptions } from '../../utils';

interface CCFFoodActivityRowProps extends RepeatableRowComponentProps {
  mode?: InputModeEnum;
}

export default function CCFFoodActivityRow({
  index,
  remove,
  setDisableAdd,
  mode,
}: CCFFoodActivityRowProps) {
  const { uuid: reportUuid } = useCCF();
  const {
    data: units,
    isLoading: unitsIsLoading,
    isError: unitsError,
  } = useGetActivityTypeUnits('food');
  const { data: periods, isLoading: isPeriodsLoading } =
    useGetCompanyReportPeriods(reportUuid);

  const getPeriodOptions = useGetPeriodOptions(periods!);
  const unitOptions = useUnitOptions(units!);

  const { getValues, setValue, watch } = useFormContext();
  const quantity = watch(`records.${index}.quantity`);
  const itemsPerPack = watch('itemsPerPack');
  const itemWeight = watch('itemWeight');
  const itemUnit = watch('unit');

  const setTotalWeight = useMemo(() => {
    return (
      quantity: number,
      itemsPerPack: number,
      itemMassOrVolume: number
    ) => {
      setValue(
        `records.${index}.totalMassOrVolume`,
        quantity * itemsPerPack * itemMassOrVolume
      );
    };
  }, [setValue, index]);

  useEffect(() => {
    setTotalWeight(quantity, itemsPerPack, itemWeight);
  }, [quantity, itemsPerPack, setTotalWeight, itemUnit, itemWeight]);

  useEffect(() => {
    setDisableAdd(true);
  });

  if (unitsIsLoading || isPeriodsLoading) {
    return (
      <div className="grid grid-cols-12 items-end gap-2">
        <Skeleton className="col-span-7 h-10 rounded-lg" />
        <Skeleton className="col-span-2 h-10 rounded-lg" />
        <Skeleton className="col-span-2 h-10 rounded-lg" />
      </div>
    );
  }

  if (!units) {
    throw new Error(
      `Failed to load units for ccf food activity rows ${unitsError || 'No data'}`
    );
  }

  if (!periods) {
    throw new Error(
      `Failed to load periods for food activity rows ${isPeriodsLoading || 'No data'}`
    );
  }

  const unit = getObjectFromArrayWithKey('uuid', getValues(`unit`), units);

  return (
    <div className="grid grid-cols-12 items-start gap-2">
      <SelectFormField
        name={`records.${index}.timePeriod`}
        options={getPeriodOptions}
        disabled
        label={index === 0 ? 'Period' : ''}
        className="col-span-4"
      />
      <InputFormField
        name={`records.${index}.quantity`}
        label={
          index === 0
            ? mode === 'packs'
              ? 'Packs purchased'
              : 'Total weight'
            : ''
        }
        className="col-span-4"
        type="number"
      />
      {mode === 'packs' && (
        <InputFormField
          name={`records.${index}.totalMassOrVolume`}
          label={index === 0 ? 'Total weight' : ''}
          className="col-span-3"
          disabled
          adornmentRight={unit?.symbol}
        />
      )}

      {mode === 'total-weight' && (
        <SelectFormField
          name={`records.${index}.unit`}
          options={unitOptions}
          label={index === 0 ? 'Unit' : ''}
          className="col-span-2"
          useShortLabel
        />
      )}

      <div className="col-span-1 grid items-end">
        {index === 0 && (
          <FormLabel className="mb-0 opacity-0">Remove</FormLabel>
        )}
        <Button
          type="button"
          variant="outline"
          size="sm"
          className={cn('size-10 disabled:opacity-30', {
            'mt-2': index === 0,
          })}
          onClick={() => remove(index)}
          disabled={getValues('records').length === 1}
        >
          <Icons.x className="size-4" />
        </Button>
      </div>
    </div>
  );
}
