import sentry from '@shared/services/sentry';
import { FC } from 'react';
import { ReactSpreadsheetImport } from 'react-spreadsheet-import';
import { Result } from 'react-spreadsheet-import/types/types';
import { getCleanupFoodFields } from '../fields';
import { FoodImportData, FoodImportRow } from '../types';
import { useImporterTheme } from '../useImporterTheme';
import { useImporterTranslations } from '../useImporterTranslations';
import { validateFoodRow } from '../utils';

interface CsvImporterFoodProps {
  isOpen: boolean;
  onClose: () => void;
  handleSubmit: (rows: FoodImportData) => Promise<void>;
}

const CsvImporterFood: FC<CsvImporterFoodProps> = ({
  isOpen,
  onClose,
  handleSubmit,
}: CsvImporterFoodProps) => {
  const theme = useImporterTheme();
  const translations = useImporterTranslations();

  const onSubmit = async (data: Result<keyof FoodImportRow>) => {
    try {
      await handleSubmit(data.validData);
    } catch (error) {
      sentry.log(error);
      throw new Error('Failed importing food data, please try again');
    }
  };

  return (
    <ReactSpreadsheetImport<keyof FoodImportRow>
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={onSubmit}
      fields={getCleanupFoodFields()}
      autoMapSelectValues
      allowInvalidSubmit={false}
      rowHook={validateFoodRow}
      customTheme={theme}
      translations={translations}
      uploadStepHook={async (data) => {
        if (data.length > 5001) {
          onClose();
          throw new Error(
            'Data limit exceeded: Maximum of 5,000 rows allowed.'
          );
        }
        return data;
      }}
    />
  );
};

export default CsvImporterFood;
