export const methodology = [
  {
    title: 'Methodology',
    scope1:
      'Average Method. The Average Method, as outlined by the GHG Protocol, involves calculating emissions by applying average emission factors to activity data.',
    scope2:
      'Average Method. The Average Method, as outlined by the GHG Protocol, involves calculating emissions by applying average emission factors to activity data.',
    scope3:
      'Average Method. The Average Method, as outlined by the GHG Protocol, involves calculating emissions by applying average emission factors to activity data.',
  },
  {
    title: 'Emission factors used',
    scope1:
      'Our emissions database includes emission factors from a variety of sources, covering a range of countries and years. The highest-quality emission factor is selected based on the company or site location and the reporting year. All data sources are visible in the emissions data export.',
    scope2:
      'Our emissions database includes emission factors from a variety of sources, covering a range of countries and years. The highest-quality emission factor is selected based on the company or site location and the reporting year. All data sources are visible in the emissions data export.',
    scope3:
      'Our emissions database includes emission factors from a variety of sources, covering a range of countries and years. The highest-quality emission factor is selected based on the company or site location and the reporting year. All data sources are visible in the emissions data export.',
  },
  {
    title: 'Supplier-specific emission factors',
    scope1: '',
    scope2: '',
    scope3:
      'Our emissions database uses factors that represent average values, therefore 0% of emissions are calculated with supplier-specific emission factors.',
  },
  {
    title: 'Activity data used',
    scope1:
      'The activity data used was collected and uploaded by the reporting company, focusing on invoices, bills and meter readings data.',
    scope2:
      'The activity data used was collected and uploaded by the reporting company, focusing on invoices, bills and meter readings data.',
    scope3:
      'The activity data used was collected and uploaded by the reporting company, focusing on invoices and purchasing data.',
  },
  {
    title: 'Quality of reported activity data',
    scope1:
      'Activity data as physical data (e.g. quantity of fuel used in litres) is preferred to spend-based data (e.g. monetary values of the fuel used).',
    scope2:
      'Activity data as physical data (e.g. quantity of electricity used in kWh) is preferred to spend-based data (e.g. monetary values of the electricity used).',
    scope3:
      'Activity data as physical data (e.g purchased quantities in kg) is preferred over spend-based data (e.g. monetary values of the food purchased).',
  },
  {
    title: 'Calculation assumptions',
    scope1:
      'The data collected is primary data, therefore there are no assumptions.',
    scope2:
      'It is assumed that the reporting company has no purchased cooling.',
    scope3:
      'The data collected is primary data, meaning that no assumptions are made unless an emission factor for a specific food or beverage is unavailable. In such cases, a proxy is used to calculate the emissions.',
  },
  {
    title: 'Allocation methods',
    scope1:
      'The activity data used is primary data, representing the total amount of fuels and refrigerant gases purchased, with no allocation required for emissions calculation.',
    scope2:
      'The activity data used is primary data, representing the total amount of energy purchased, with no allocation required for emissions calculation.',
    scope3:
      'The activity data used is primary data, representing the total amount of food or drinks purchased, with no allocation required for emissions calculation. The Agribalyse emission factors use economic allocation.',
  },
];
