import Text from '@shared/components/content/text';
import Unit from '@shared/components/content/unit';
import {
  capitalizeFirstLetter,
  cn,
  roundNumberToNDecimalPlaces,
} from '@shared/lib/utils';
import React from 'react';

interface CCFPieChartLegendProps extends React.HTMLAttributes<HTMLDivElement> {
  name: string;
  emissions: number;
  percentage: number;
  color: string;
}

export default function CCFPieChartLegend({
  name,
  emissions,
  percentage,
  color,
}: CCFPieChartLegendProps) {
  return (
    <div className="mb-3 grid w-full grid-cols-12 items-center gap-1">
      <div className="col-span-4 flex items-center space-x-2 lg:col-span-5">
        <div
          className={cn(`size-6 rounded-sm`)}
          style={{
            backgroundColor: color,
          }}
        ></div>
        <Text className="text-md">{capitalizeFirstLetter(name)}</Text>
      </div>
      <div className="col-span-7 grid grid-cols-12 items-baseline gap-6 space-x-3">
        <div className="col-span-6 flex items-baseline justify-end space-x-1 ">
          <Text variant="reportBody">
            {roundNumberToNDecimalPlaces(emissions, 2)}
          </Text>
          <Unit variant="tCO2e" />
        </div>

        <div className="col-span-6 flex items-baseline justify-end space-x-1">
          <Text variant="reportBody">{percentage}</Text>
          <Unit variant="% of emissions" className=" text-nowrap" />
        </div>
      </div>
    </div>
  );
}
