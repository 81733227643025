import { zodResolver } from '@hookform/resolvers/zod';
import { useGetAvailableIngredients } from '@shared/api';
import {
  useDeleteFoodActivity,
  useGetFoodActivity,
  useUpdateFoodActivity,
} from '@shared/api/hooks/ccf/activities/food';
import { useGetActivityTypeUnits } from '@shared/api/hooks/ccf/units';
import {
  FoodActivitiesList,
  FoodActivityRecord,
  FoodActivityUpdate,
  InputModeEnum,
} from '@shared/api/types';
import Text from '@shared/components/content/text';
import InputFormField from '@shared/components/form/input-form-field';
import { RepeatableFormRows } from '@shared/components/form/repeatable-form-rows';
import SelectFormField from '@shared/components/form/select-form-field';
import Loader from '@shared/components/loader';
import { Button } from '@shared/components/ui/button';
import { Form } from '@shared/components/ui/form';
import { useToast } from '@shared/components/ui/use-toast';
import sentry from '@shared/services/sentry';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { z } from 'zod';
import { useCCF } from '../../ccf-context';
import { useUnitOptions } from '../../utils';
import CCFFoodActivityRow from './ccf-food-activity-row';
import MatchedFoodPicker from './matched-food-picker';
import {
  PackCCFFoodForm,
  packCCFFoodFormSchema,
  TotalWeightCCFFoodForm,
  totalWeightCCFFoodFormSchema,
} from './schema';

interface CCFFoodEditFormProps {
  foodActivityUuid: FoodActivitiesList['uuid'] | undefined;
  closeDialog: () => void;
  mode: InputModeEnum;
}

export default function CCFFoodEditForm({
  foodActivityUuid,
  closeDialog,
  mode,
}: CCFFoodEditFormProps) {
  const [searchParams] = useSearchParams();
  const period = searchParams.get('period')?.split('.');

  const schema =
    mode === 'packs' ? packCCFFoodFormSchema : totalWeightCCFFoodFormSchema;

  const { uuid: reportUuid } = useCCF();
  const { toast } = useToast();
  const {
    data: availableIngredients,
    isLoading: availableIngredientsLoading,
    error: availableIngredientsError,
  } = useGetAvailableIngredients();

  const { data, status } = useGetFoodActivity({
    foodActivityUuid: foodActivityUuid!,
    reportUuid: reportUuid!,
    params: {
      time_periods: period,
    },
  });

  const { data: units, isLoading: unitsIsLoading } =
    useGetActivityTypeUnits('food');

  const unitOptions = useUnitOptions(units!);

  const { mutateAsync: updateFoodActivity, isLoading: isUpdatingFoodActivity } =
    useUpdateFoodActivity();
  const { mutateAsync: deleteFoodActivity, isLoading: isDeletingFoodActivity } =
    useDeleteFoodActivity();

  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
  });
  const { reset } = form;

  if (!availableIngredients && !availableIngredientsLoading) {
    throw new Error(
      `Failed to load available ingredients for ingredient rows: ${availableIngredientsError || 'No data'}`
    );
  }

  const onSubmit = async (data: z.infer<typeof schema>) => {
    const packsData = data as PackCCFFoodForm;
    const totalWeightData = data as TotalWeightCCFFoodForm;

    const updatedPacksData = {
      itemMassOrVolume: packsData.itemWeight,
      itemsPerPack: packsData.itemsPerPack,
      itemUnit: packsData.unit,
      matchedBaseFood: packsData.matchedFoodUuid,
      records: packsData.records.map((record) => {
        return {
          timePeriod: record.timePeriod,
          quantity: record.quantity,
          unit: packsData.unit,
          uuid: record.uuid,
        };
      }),
    } as FoodActivityUpdate;

    const updatedTotalWeightData = {
      matchedBaseFood: totalWeightData.matchedFoodUuid,
      records: totalWeightData.records.map((record) => {
        return {
          timePeriod: record.timePeriod,
          quantity: record.quantity,
          unit: record.unit,
          uuid: record.uuid,
        };
      }),
    } as FoodActivityUpdate;

    const importData =
      mode === 'packs' ? updatedPacksData : updatedTotalWeightData;

    try {
      await updateFoodActivity({
        activityUuid: foodActivityUuid!,
        companyReportUuid: reportUuid!,
        data: importData,
      });
      toast({
        title: 'Item updated',
        variant: 'success',
      });
      closeDialog();
    } catch (error) {
      sentry.log(error);
      toast({
        title: 'Failed to update item',
        variant: 'destructive',
      });
    }
  };

  const handleDelete = async () => {
    try {
      await deleteFoodActivity({
        activityUuid: foodActivityUuid!,
        companyReportUuid: reportUuid!,
      });
      toast({
        title: 'Food deleted',
        variant: 'success',
      });
      closeDialog();
    } catch (error) {
      sentry.log(error);
      toast({
        title: 'Failed to delete food',
        variant: 'destructive',
      });
    }
  };

  useEffect(() => {
    if (data) {
      reset({
        remoteId: data.remoteId || '',
        name: data.organizationFood.name,
        matchedFoodUuid: data.matchedBaseFood.uuid,
        itemsPerPack: data.itemsPerPack!,
        itemWeight: data.itemMassOrVolume!,
        unit: mode === 'packs' ? data.itemUnit.uuid : '',

        records: data.records.map((record: FoodActivityRecord) => ({
          timePeriod: record.timePeriod.uuid,
          quantity: record.quantity,
          unit: record.unit.uuid,
          uuid: record.uuid,
          totalMassOrVolume: record.totalMassOrVolume as number,
        })),
      });
    }
  }, [data, reset, foodActivityUuid, mode]);

  if (
    status === 'loading' ||
    !data ||
    availableIngredientsLoading ||
    unitsIsLoading
  ) {
    return <Loader />;
  }

  return (
    <Form {...form}>
      <form className="space-y-4" onSubmit={form.handleSubmit(onSubmit)}>
        <>
          <div className="grid grid-cols-12 gap-2">
            <InputFormField
              name="remoteId"
              disabled
              label="Your ID"
              className="col-span-2 max-w-fit"
            />
            <InputFormField
              name="name"
              disabled
              label="Food name"
              className="col-span-6"
            />
            <MatchedFoodPicker
              name="matchedFoodUuid"
              availableIngredients={availableIngredients}
              className="col-span-4"
              showLabel
              modal={true}
            />
          </div>
          {mode === 'packs' && (
            <div className="grid grid-cols-12 gap-3">
              <InputFormField
                name="itemsPerPack"
                label="Items per pack"
                className="col-span-3"
              />
              <InputFormField
                name="itemWeight"
                label="Item weight"
                className="col-span-3"
              />
              <SelectFormField
                name="unit"
                options={unitOptions}
                label="Unit"
                className="col-span-2"
                useShortLabel
              />
            </div>
          )}
        </>
        <div className="space-y-4">
          {period && (
            <Text>
              You are viewing a filtered version of food activities based on the
              time period you selected
            </Text>
          )}
          <RepeatableFormRows
            name="records"
            includeErrorMessage
            showAddButton={false}
            component={CCFFoodActivityRow}
            mode={mode}
            newRowObject={{
              quantity: 0,
              unitUuid: '',
              uuid: '',
            }}
          />
        </div>
        <div className="mt-12 flex justify-between">
          <div className="col-span-4">
            <Button
              type="button"
              size="lg"
              variant="destructive"
              loading={isDeletingFoodActivity}
              disabled={isDeletingFoodActivity || isUpdatingFoodActivity}
              onClick={handleDelete}
            >
              Delete item
            </Button>
          </div>
          <div className="flex space-x-2">
            <div className="col-span-4">
              <Button
                type="button"
                size="lg"
                variant="secondary"
                onClick={() => {
                  closeDialog();
                  reset();
                }}
                disabled={isDeletingFoodActivity || isUpdatingFoodActivity}
              >
                Cancel
              </Button>
            </div>
            <div className="col-span-4">
              <Button
                type="submit"
                size="lg"
                loading={isUpdatingFoodActivity}
                disabled={isDeletingFoodActivity || isUpdatingFoodActivity}
              >
                Update
              </Button>
            </div>
          </div>
        </div>
      </form>
    </Form>
  );
}
