import { array, object, string, z } from 'zod';

export const baseCCFFoodFormSchema = object({
  name: string().min(1),
  remoteId: string().optional(),
  matchedFoodUuid: string().min(1, {
    message: 'Please select a food',
  }),

  records: array(
    object({
      uuid: string().min(1),
      quantity: z.coerce.number().min(0.001),
      timePeriod: string().min(1),
      unit: string().min(1),
    })
  ),
});

export const packCCFFoodFormSchema = baseCCFFoodFormSchema.extend({
  unit: string().min(1),
  itemsPerPack: z.coerce.number().min(0.001),
  itemWeight: z.coerce.number().min(0.001),
});

export const totalWeightCCFFoodFormSchema = baseCCFFoodFormSchema.extend({});

export type TotalWeightCCFFoodForm = z.infer<
  typeof totalWeightCCFFoodFormSchema
>;

export type PackCCFFoodForm = z.infer<typeof packCCFFoodFormSchema>;
