import { Icons } from '@shared/components/content/icons';
import ProgressBarWithDuration from '@shared/components/progress-bar-with-duration';

interface ReportPublishLoaderProps {
  forceComplete?: boolean;
  duration?: number;
}

export default function ReportPublishLoader({
  forceComplete,
  duration = 4000,
}: ReportPublishLoaderProps) {
  return (
    <div>
      <div className="flex w-[320px] items-center justify-center">
        <ProgressBarWithDuration
          duration={duration}
          statusMessages={[
            {
              message: 'Calculating emissions...',
              icon: <Icons.calculator className="size-5" />,
            },
            {
              message: 'Generating charts...',
              icon: <Icons.pieChart className="size-5" />,
            },
            {
              message: 'Finalising report...',
              icon: <Icons.stamp className="size-5" />,
            },
          ]}
          forceComplete={forceComplete}
        />
      </div>
    </div>
  );
}
