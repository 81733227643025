import { CompanyReportResult } from '@shared/api/types';
import { Icons } from '@shared/components/content/icons';
import Text from '@shared/components/content/text';
import { Card, CardContent } from '@shared/components/ui/card';
import { formatDate } from '@shared/lib/utils';
import { useMemo } from 'react';
import { useCCFReportResult } from '../../ccf-report-result-context';
import CCFReportMetric from '../../components/ccf-report-metric';
import ReportContent from '../../components/report-content';

function CustomCard({
  title,
  description,
  icon,
}: {
  title: string;
  description: string;
  icon: React.ReactNode;
}) {
  return (
    <Card className="border-teal bg-primary-50 text-primary-800">
      <CardContent className="space-y-2 px-4 py-3">
        <Text className="font-heading font-bold">{title}</Text>
        <div className="flex items-center space-x-2">
          {icon}
          <Text variant="reportBody">{description}</Text>
        </div>
      </CardContent>
    </Card>
  );
}

type TAboutYourReportData = {
  start_date: CompanyReportResult['startDate'];
  end_date: CompanyReportResult['endDate'];
  first_published_at: CompanyReportResult['firstPublishedAt'];
  last_updated_at: CompanyReportResult['lastPublishedAt'];
  sites: CompanyReportResult['sites'];
};

export default function AboutYourReport() {
  const { result } = useCCFReportResult();

  const data: TAboutYourReportData = {
    start_date: result.startDate,
    end_date: result.endDate,
    first_published_at: result.firstPublishedAt,
    last_updated_at: result.lastPublishedAt,
    sites: result.sites,
  };

  const siteCountryText = useMemo(() => {
    const numSites = data.sites.length;
    const siteText = `${numSites} site${numSites !== 1 ? 's' : ''}`;

    const uniqueCountries = [
      ...new Set(data.sites.map((site) => site.country)),
    ];

    if (uniqueCountries.length === 0) {
      return siteText;
    }

    if (uniqueCountries.length === 1) {
      return `${siteText} in ${uniqueCountries[0]}`;
    }

    return `${siteText} across ${uniqueCountries.slice(0, -1).join(', ')} and ${
      uniqueCountries[uniqueCountries.length - 1]
    }`;
  }, [data.sites]);

  return (
    <ReportContent>
      <>
        <Text variant="reportBody">
          This report has been completed using the My Emissions platform. The
          calculation results included in this report are based on the data
          available upon completion. The accuracy of the data inputted is solely
          the responsibility of the reporting company.
        </Text>
        <CCFReportMetric
          title="Reporting period"
          value={`${formatDate(data.start_date, 'do MMMM yyyy')} - ${formatDate(
            data.end_date,
            'do MMMM yyyy'
          )}`}
        />
        <div className="flex space-x-10">
          <CCFReportMetric
            title="First completed"
            value={formatDate(data.first_published_at, 'do MMMM yyyy')}
          />
          <CCFReportMetric
            title="Last updated"
            value={formatDate(data.last_updated_at, 'do MMMM yyyy')}
          />
        </div>

        <CCFReportMetric title="Sites" value={siteCountryText} />

        <CCFReportMetric
          title="Organisational boundary"
          value="Operational-control approach"
        />
      </>
      <>
        <Text variant="h3">Reporting guidelines</Text>
        <Text variant="reportBody">
          The structure and calculation framework of this report are based on
          the Greenhouse Gas (GHG) Protocol. The emissions categories covered
          meet the requirements for the GHG Protocol Corporate Standard.
        </Text>
        <div className="space-y-4">
          <CustomCard
            title="Scope 1 - Direct emissions"
            description="Included"
            icon={<Icons.check size={20} />}
          />
          <CustomCard
            title="Scope 2 - Indirect emissions from energy"
            description="Included"
            icon={<Icons.check size={20} />}
          />
          <CustomCard
            title="Scope 3 - Other indirect emissions"
            description="Purchased goods and services (food & drink only)"
            icon={<Icons.carrot size={20} />}
          />
        </div>
        {/* <div className="flex flex-row items-center space-x-4">
          <img
            src={
              new URL(
                `@shared/assets/client-assets/wrap-logo.png`,
                import.meta.url
              ).href
            }
            className="w-28"
            alt="wrap logo"
          />
          <Text variant="reportBody" className="text-sm">
            This report includes the essential Scope 3 categories as defined by
            WRAP
          </Text>
        </div> */}
      </>
    </ReportContent>
  );
}
