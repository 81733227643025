import Page from '@app/components/layout/page/page';
import { useIntercom } from '@app/services/intercom/IntercomContext';
import { Icons } from '@shared/components/content/icons';
import Text from '@shared/components/content/text';
import TextLink from '@shared/components/content/text-link';
import { Accordion } from '@shared/components/ui/accordion';
import { generatePath } from 'react-router-dom';
import { ccfRoutes } from '../../ccf-routes';
import { useCCF } from '../ccf-context';
import HubAccordionItem from '../components/hub-accordion-item';
import HubCard from '../components/hub-card';
import { useProcessScopes } from '../utils';

export default function ReportHubPage() {
  const { uuid: reportUuid } = useCCF();
  const { showIntercomArticle } = useIntercom();

  const { scope1, scope2, scope3 } = useProcessScopes();

  return (
    <Page name="Company carbon footprint hub">
      <div className="flex">
        <div className="w-full min-w-fit max-w-form grow">
          <Accordion
            type="multiple"
            defaultValue={['scope1', 'scope2', 'scope3']}
            className="w-full space-y-2"
          >
            <HubAccordionItem
              id="section-scope-1"
              title="Scope 1 - Direct emissions"
              value="scope1"
            >
              <Text variant="subtle" className="mb-2">
                The emissions from any process or activity within your company
                that directly produces greenhouse gases.{' '}
                <TextLink onClick={() => showIntercomArticle('scope1')}>
                  Learn more.
                </TextLink>
              </Text>

              {scope1.map((item) => {
                return (
                  <HubCard
                    key={item.slug}
                    slug={item.slug}
                    name={item.name}
                    totalEmissions={item.totalEmissions!}
                    description={item.description}
                    statusDescription={item.statusDescription!}
                    status={item.status}
                    cardStatus={item.cardStatus}
                    href={item.href}
                    icon={item.icon}
                  />
                );
              })}
            </HubAccordionItem>
            <HubAccordionItem
              id="section-scope-2"
              title="Scope 2 - Indirect emissions from energy"
              value="scope2"
            >
              <Text variant="subtle" className="mb-2">
                The emissions from the energy your business purchases, generated
                by energy providers on your behalf.{' '}
                <TextLink onClick={() => showIntercomArticle('scope2')}>
                  Learn more.
                </TextLink>
              </Text>
              {scope2
                .filter(({ isSupported }) => !!isSupported)
                .map((item) => {
                  return (
                    <HubCard
                      key={item.slug}
                      slug={item.slug}
                      name={item.name}
                      totalEmissions={item.totalEmissions!}
                      description={item.description}
                      statusDescription={item.statusDescription!}
                      status={item.status}
                      cardStatus={item.cardStatus}
                      href={item.href}
                      icon={item.icon}
                    />
                  );
                })}
            </HubAccordionItem>

            <HubAccordionItem
              id="section-scope-3"
              title="Scope 3 - Other indirect emissions"
              value="scope3"
            >
              <Text variant="subtle" className="mb-2">
                The emissions that occur across your supply chain, from the
                production of the ingredients you purchase to waste disposal.{' '}
                <TextLink onClick={() => showIntercomArticle('scope3')}>
                  Learn more.
                </TextLink>
              </Text>
              {scope3
                .filter(({ isSupported }) => !!isSupported)
                .map((item) => {
                  return (
                    <HubCard
                      key={item.slug}
                      slug={item.slug}
                      name={item.name}
                      scope3Category={item.scope3Category}
                      totalEmissions={item.totalEmissions!}
                      description={item.statusDescription!}
                      status={item.status}
                      statusDescription={item.statusDescription!}
                      href={generatePath(ccfRoutes.FOOD, {
                        report_uuid: reportUuid,
                      })}
                      icon={
                        <Icons.carrot className="text-orange-400" size={20} />
                      }
                    />
                  );
                })}
            </HubAccordionItem>
          </Accordion>
        </div>
      </div>
    </Page>
  );
}
