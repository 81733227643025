import { ApiError } from '@app/api/errors';
import { ccfRoutes } from '@app/ccf/ccf-routes';
import { zodResolver } from '@hookform/resolvers/zod';
import { useGetCompanyReportPeriods, useGetSites } from '@shared/api';
import {
  useCreateElectricityActivity,
  useDeleteElectricityActivity,
  useGetElectricityActivity,
  useUpdateElectricityActivity,
} from '@shared/api/hooks/ccf/activities/electricity';
import { useGetActivityTypeUnits } from '@shared/api/hooks/ccf/units';
import { TimePeriodTypeEnum } from '@shared/api/types';
import Text from '@shared/components/content/text';
import TextLink from '@shared/components/content/text-link';
import { RepeatableFormRows } from '@shared/components/form/repeatable-form-rows';
import SelectFormField from '@shared/components/form/select-form-field';
import Loader from '@shared/components/loader';
import SimpleSelect from '@shared/components/simple-select';
import { Button } from '@shared/components/ui/button';
import { Form } from '@shared/components/ui/form';
import { useToast } from '@shared/components/ui/use-toast';
import sentry from '@shared/services/sentry';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { array, object, string, z } from 'zod';
import { useCCF } from '../../ccf-context';
import { emptyReadingRow, renderRows, useUnitOptions } from '../../utils';
import ElectricityReadingRow from './electricity-reading-row';

const electricityFormSchema = object({
  site: string({
    required_error: 'Please provide a site',
  }).min(1),
  unit: string().min(1),
  readings: array(
    object({
      uuid: string().optional(),
      quantity: z.coerce.number().min(0),
      timePeriod: string().min(1),
    })
  ),
});

interface ElectricityEditFormProps {
  activityUuid?: string;
  closeDialog: () => void;
}

export default function ElectricityEditForm({
  activityUuid,
  closeDialog,
}: ElectricityEditFormProps) {
  const { uuid: reportUuid } = useCCF();
  const { toast } = useToast();
  const [timePeriod, setTimePeriod] = useState<string>('Year');

  const { data: units, isLoading: isUnitsLoading } =
    useGetActivityTypeUnits('electricity');

  const unitOptions = useUnitOptions(units!);

  const { mutateAsync: createElectricityActivity, isLoading: isCreating } =
    useCreateElectricityActivity();
  const { mutateAsync: updateElectricityActivity, isLoading: isUpdating } =
    useUpdateElectricityActivity();
  const { mutateAsync: deleteElectricityActivity, isLoading: isDeleting } =
    useDeleteElectricityActivity();

  const isSubmitting = isCreating || isUpdating;
  const siteHasActivity = !!activityUuid;

  const { data: activity, isLoading: isActivityLoading } =
    useGetElectricityActivity({
      reportUuid,
      electricityActivityUuid: activityUuid,
    });

  const { data: sites, isLoading: isSitesLoading } = useGetSites({
    page: 1,
    page_size: 1000,
  });

  const { data: periodOptions, isLoading: isPeriodsLoading } =
    useGetCompanyReportPeriods(reportUuid!);

  const form = useForm<z.infer<typeof electricityFormSchema>>({
    resolver: zodResolver(electricityFormSchema),
  });

  const { getValues, reset, handleSubmit, watch } = form;

  const onSubmit = async (data: z.infer<typeof electricityFormSchema>) => {
    try {
      if (siteHasActivity) {
        await updateElectricityActivity({
          companyReportUuid: reportUuid!,
          activityUuid,
          data: {
            records: data.readings.map((reading) => ({
              uuid: reading.uuid!,
              quantity: reading.quantity,
              unit: data.unit,
              timePeriod: reading.timePeriod,
            })),
          },
        });
      } else {
        await createElectricityActivity({
          companyReportUuid: reportUuid!,
          data: {
            site: data.site,
            records: data.readings.map((reading) => ({
              quantity: reading.quantity,
              unit: data.unit,
              timePeriod: reading.timePeriod,
            })),
          },
        });
      }

      toast({
        title: `${siteHasActivity ? 'Updated' : 'Added'} electricity data for site`,
        variant: 'success',
      });
      closeDialog();
    } catch (error) {
      sentry.log(error);

      let message;

      if (
        error instanceof ApiError &&
        error.type === 'activity-already-exists'
      ) {
        message =
          'This site already has electricity data, please update the existing records instead';
      }

      toast({
        title: `Failed to ${siteHasActivity ? 'update' : 'add'} electricity data`,
        description: message,
        variant: 'destructive',
      });
    }
  };

  const handleDelete = async () => {
    if (!activityUuid) return;
    try {
      await deleteElectricityActivity({
        companyReportUuid: reportUuid!,
        activityUuid,
      });
      toast({
        title: 'Deleted electricity data',
        variant: 'success',
      });
      closeDialog();
    } catch (error) {
      sentry.log(error);
      toast({
        title: 'Failed to delete electricity data',
        variant: 'destructive',
      });
    }
  };

  const formattedSites = useMemo(
    () =>
      sites?.results?.map((site) => ({
        label: site.name,
        value: site.uuid,
      })),
    [sites]
  );

  const getRowTimePeriods = useCallback(
    (timePeriod: string) => {
      return periodOptions?.filter((period) => period.type === timePeriod);
    },
    [periodOptions]
  );

  const selectedSite = watch('site');

  const selectedSiteData = useMemo(() => {
    return sites?.results?.find((site) => site.uuid === selectedSite);
  }, [selectedSite, sites?.results]);

  useEffect(() => {
    if (timePeriod) {
      const rowTimePeriods = getRowTimePeriods(timePeriod);
      reset({
        site: getValues('site'),
        unit: getValues('unit'),
        readings: renderRows({
          timePeriod: timePeriod,
          unit: getValues('unit'),
          timePeriods: rowTimePeriods!,
        }),
      });
    }
  }, [getRowTimePeriods, getValues, reset, timePeriod]);

  useEffect(() => {
    if (activity) {
      reset({
        site: activity.site.uuid,
        unit: activity.records[0].unit.uuid,
        readings: activity.records.map((record) => ({
          uuid: record.uuid,
          quantity: record.quantity,
          unit: record.unit.uuid,
          timePeriod: record.timePeriod.uuid,
        })),
      });
    }
  }, [activity, activityUuid, reset]);

  if (
    isSitesLoading ||
    isUnitsLoading ||
    isPeriodsLoading ||
    (siteHasActivity && isActivityLoading)
  ) {
    return <Loader />;
  }

  return (
    <Form {...form}>
      <form className="mt-2 space-y-6" onSubmit={handleSubmit(onSubmit)}>
        <div className="space-y-2">
          <div className="grid grid-cols-12 gap-2">
            <SelectFormField
              name="site"
              label="Site"
              options={formattedSites!}
              className={'col-span-4'}
              disabled={siteHasActivity || isSubmitting}
            />
            {!siteHasActivity && (
              <SimpleSelect
                options={Object.keys(TimePeriodTypeEnum)}
                disabled={siteHasActivity || isSubmitting}
                value={timePeriod}
                onValueChange={(value) => {
                  setTimePeriod(value);
                }}
                className="col-span-4"
                label="Time period"
              />
            )}
            <SelectFormField
              name="unit"
              label="Unit"
              options={unitOptions}
              className="col-span-4"
              disabled={siteHasActivity || isSubmitting}
            />
          </div>
          {selectedSiteData && !selectedSiteData.electricitySupply && (
            <div className="flex items-center">
              <Text variant="subtle">
                This site uses standard location-based emissions factors, we
                recommend adding your specific electricity supply data to get
                more accurate results.{' '}
                <TextLink to={ccfRoutes.ELECTRICITY_SUPPLIES} newTab>
                  + Add electricity supply
                </TextLink>
              </Text>
            </div>
          )}
        </div>
        <div>
          {(timePeriod || siteHasActivity) && (
            <RepeatableFormRows
              name="readings"
              includeErrorMessage
              showAddButton={false}
              component={ElectricityReadingRow}
              newRowObject={emptyReadingRow}
            />
          )}
        </div>
        <div className="mt-12 flex justify-between">
          <div className="col-span-4">
            {activityUuid && (
              <Button
                type="button"
                size="lg"
                variant="outline-destructive"
                loading={isDeleting}
                disabled={isSubmitting || isDeleting}
                onClick={handleDelete}
              >
                Delete electricity data
              </Button>
            )}
          </div>
          <div className="flex space-x-2">
            <div className="col-span-4">
              <Button
                type="button"
                size="lg"
                variant="secondary"
                onClick={() => {
                  closeDialog();
                  reset();
                }}
                disabled={isSubmitting || isDeleting}
              >
                Cancel
              </Button>
            </div>
            <div className="col-span-4">
              <Button
                type="submit"
                size="lg"
                loading={isSubmitting}
                disabled={isSubmitting || isDeleting}
              >
                {siteHasActivity ? 'Update' : 'Add'}
              </Button>
            </div>
          </div>
        </div>
      </form>
    </Form>
  );
}
