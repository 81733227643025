import {
  ActivityFuel,
  ActivityUnit,
  CompanyReportSummary,
  Site,
  SourceEnum,
  TimePeriod,
  TimePeriodTypeEnum,
} from '@shared/api/types';
import { capitalizeFirstLetter } from '@shared/lib/utils';
import { useMemo } from 'react';
import { scroller } from 'react-scroll';
import { SelectOption } from 'react-spreadsheet-import/types/types';
import { useCCF } from './ccf-context';
import { ScopeCategory, useScopeCategories } from './scopes';

interface renderRowsProps {
  timePeriod: string;
  unit: string;
  timePeriods: TimePeriod[];
}

export const emptyReadingRow = {
  uuid: '',
  quantity: 0,
  unit: '',
  timePeriod: '',
};

export const renderRows = ({
  timePeriod,
  unit,
  timePeriods,
}: renderRowsProps) => {
  let number = 0;
  switch (timePeriod) {
    case TimePeriodTypeEnum.year:
      number = 1;
      break;
    case TimePeriodTypeEnum.quarter:
      number = 4;
      break;
    case TimePeriodTypeEnum.month:
      number = 12;
      break;
  }

  const rows = [];
  for (let i = 0; i < number; i++) {
    rows.push({
      uuid: '1',
      quantity: null,
      unit: unit,
      timePeriod: timePeriods[i].uuid,
    });
  }
  return rows;
};

export const useGetPeriodOptions = (periods: TimePeriod[]) => {
  return useMemo(
    () =>
      periods?.map(({ uuid, name }) => ({
        value: uuid,
        label: name,
      })) as SelectOption[] | [],
    [periods]
  );
};

export function useUnitOptions(units: ActivityUnit[]) {
  return useMemo(
    () =>
      units?.map((unit) => ({
        value: unit.uuid,
        label: capitalizeFirstLetter(unit.name),
        shortLabel: unit.symbol,
      })) || [],
    [units]
  );
}

export function useFormattedSites(sites: Site[]): SelectOption[] {
  return useMemo(
    () =>
      sites.map((site) => ({
        label: site.name,
        value: site.uuid,
      })) || [],
    [sites]
  ) as SelectOption[];
}

export function useGetRowTimePeriods(
  timePeriod: string,
  periodOptions: TimePeriod[]
) {
  return useMemo(
    () => periodOptions?.filter((period) => period.type === timePeriod),
    [periodOptions, timePeriod]
  );
}

export function useFormattedFuels(fuels: ActivityFuel[]): SelectOption[] {
  return useMemo(
    () =>
      fuels?.map((fuel) => ({
        label: fuel.name,
        value: fuel.uuid,
      })) || [],
    [fuels]
  ) as SelectOption[];
}

export function useFormattedSources(): SelectOption[] {
  return useMemo(
    () =>
      Object.keys(SourceEnum).map((source) => ({
        label: capitalizeFirstLetter(
          SourceEnum[source as keyof typeof SourceEnum]
        ),
        value: SourceEnum[source as keyof typeof SourceEnum],
      })),
    []
  ) as SelectOption[];
}

export function scrollTo(name: string, options?: object) {
  scroller.scrollTo(name, {
    duration: 500,
    delay: 0,
    smooth: 'true',
    offset: -72,
    ...options,
  });
}

export const ScopeDescription = {
  scope_1: 'Direct emissions',
  scope_2: 'Indirect emissions from purchased energy',
  scope_3: 'Other indirect emissions',
};

export const useProcessScopes = () => {
  const baseCategories = useScopeCategories();
  const { summary, reportCategoryStatuses } = useCCF();
  return useMemo(
    () =>
      baseCategories?.reduce(
        (acc, item) => {
          const matchingLiveCategory = summary?.find(
            (category: CompanyReportSummary) => category.slug === item.slug
          );

          if (matchingLiveCategory) {
            const reportInclusionStatus = reportCategoryStatuses?.find(
              (category) => category.uuid === matchingLiveCategory.uuid
            )?.status;

            item = {
              ...item,
              uuid: matchingLiveCategory.uuid,
              totalEmissions: matchingLiveCategory.totalEmissions!,
              status: matchingLiveCategory.status,
              statusDescription: matchingLiveCategory.statusDescription,
              inclusionStatus: reportInclusionStatus,
              isSupported: matchingLiveCategory.isSupported,
              cardStatus: 'default',
            };
          }

          acc['scope' + item.scope].push(item);
          return acc;
        },
        {
          scope1: [],
          scope2: [],
          scope3: [],
        } as Record<string, ScopeCategory[]>
      ),
    [baseCategories, reportCategoryStatuses, summary]
  );
};
