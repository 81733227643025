import Page from '@app/components/layout/page/page';
import useSimpleDialog from '@app/hooks/use-simple-dialog';
import { useIntercom } from '@app/services/intercom/IntercomContext';
import Text from '@shared/components/content/text';
import TextList from '@shared/components/content/text-list';
import { useMemo } from 'react';
import { useCCF } from '../../ccf-context';
import MobileCombustionEditForm from '../../components/scope-1/mobile-combustion-edit-form';
import MobileCombustionTable from '../../components/scope-1/mobile-combustion-table';
import EmptyCategoryPage from '../ccf-category-intro-page';

export default function MobileCombustionPage() {
  const { showIntercomArticle } = useIntercom();
  const { summary } = useCCF();
  const {
    showSimpleDialog: showMobileCombustionDialog,
    SimpleDialogComponent: MobileCombustionDialog,
    closeSimpleDialog: closeMobileCombustionDialog,
  } = useSimpleDialog();

  const categoryStatus = useMemo(
    () =>
      summary?.filter((item) => item.slug === 'mobile-combustion')[0].status,
    [summary]
  );

  const description = (
    <>
      <Text>
        Mobile combustion refers to the burning of fuels, such as diesel,
        petrol, or biodiesel, in your{' '}
        <span className="font-semibold">company-owned vehicles</span>. These are
        a part of Scope 1 because they are direct emissions produced by your
        operations.
      </Text>
      <Text className="mt-3">Examples include:</Text>
      <TextList>
        <li>
          <span className="font-semibold">Delivery trucks</span> transporting
          goods between sites.
        </li>
        <li>
          <span className="font-semibold">Forklifts</span> operating in
          warehouses where you have operational control.
        </li>
        <li>
          <span className="font-semibold">Company cars</span> used for travel or
          logistics.
        </li>
      </TextList>
    </>
  );

  return (
    <Page name="Scope 1 - Mobile combustion">
      {categoryStatus != 'unstarted' ? (
        <MobileCombustionTable />
      ) : (
        <EmptyCategoryPage
          categorySlug="mobile-combustion"
          description={description}
          primaryActionProps={{
            onClick: showMobileCombustionDialog,
          }}
          supportArticle="mobileCombustion"
        />
      )}
      {MobileCombustionDialog({
        header: 'Add mobile combustion data',
        content: (
          <MobileCombustionEditForm closeDialog={closeMobileCombustionDialog} />
        ),
      })}
    </Page>
  );
}
