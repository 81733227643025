import { reportSlugs } from '@app/ccf/reports/report/report-utils';
import Text from '@shared/components/content/text';
import { ChartConfig } from '@shared/components/ui/chart';
import { capitalizeFirstLetter } from '@shared/lib/utils';
import { useCCFReportResult } from '../../ccf-report-result-context';
import CCFReportMetric from '../../components/ccf-report-metric';
import SimpleStackedBarChart from '../../components/charts/simple-stacked-bar-chart';
import ReportContent from '../../components/report-content';

export default function FoodAndDrink() {
  const {
    result,
    result: { scope3 },
  } = useCCFReportResult();

  const purchasedGoodsAndServices = scope3.categories.find(
    (category) => category.slug === reportSlugs.purchasedGoodsAndServices
  );

  const foodAndDrink = purchasedGoodsAndServices?.subCategories?.find(
    (subCategory) => subCategory.slug === reportSlugs.foodAndDrink
  );

  const data = {
    food_and_drink_emissions: foodAndDrink?.totalSubCategoryEmissionsTonnes,
    share_of_scope_emissions: foodAndDrink?.pctOfScopeEmissions,
    share_of_total_emissions: foodAndDrink?.pctOfCompanyEmissions,
    emissions_by_food_category:
      result.foodAndDrink?.foodEmissionsByCategory.map((category) => {
        return {
          category: capitalizeFirstLetter(category.category),
          emissions: category.totalEmissionsTonnes,
          fill: 'var(--chart-3)',
        };
      }),
  };

  const chartConfig = {
    emissions: {
      label: 'Emissions',
      color: 'hsl(var(--chart-1))',
    },
  } satisfies ChartConfig;

  return (
    <ReportContent>
      <>
        <Text variant="reportBody">
          This category includes all the food, drink, and associated raw
          materials your business buys. For food businesses, this is often the
          biggest contributor to overall emissions due to the energy and
          resources required in farming, production, and transportation.
          <br />
          <br />
          For category 1, purchased goods and services, our report focuses on
          purchased food and drink, which represents the majority of your
          emissions in this category.
        </Text>
        <CCFReportMetric
          title="Category emissions"
          value={data.food_and_drink_emissions!}
          unit="tCO2e"
          size="lg"
        />
        <div className="flex space-x-2">
          <CCFReportMetric
            title="Share of Scope 3 emissions"
            value={data.share_of_scope_emissions!}
            unit="%"
            size="md"
            className="flex-1"
          />
          <CCFReportMetric
            title="Share of total emissions"
            value={data.share_of_total_emissions!}
            unit="%"
            size="md"
            className="flex-1"
          />
        </div>
      </>
      <>
        <Text variant="h3">Emissions by food category</Text>
        <SimpleStackedBarChart
          nameKey="category"
          dataKey="emissions"
          chartData={data.emissions_by_food_category}
          chartConfig={chartConfig}
          className="size-full max-h-[calc(var(--content-height)-15rem)]"
          layout="vertical"
          margin={{ left: 20, right: 80 }}
          XAxisProps={{
            label: {
              value: 'tCO2e',
              position: 'right',
              offset: 20,
            },
          }}
        />
      </>
    </ReportContent>
  );
}
