import {
  ChartConfig,
  ChartContainer,
  ChartLegend,
  ChartLegendContent,
  ChartTooltip,
  ChartTooltipContent,
} from '@shared/components/ui/chart';
import { cn } from '@shared/lib/utils';
import { ClassValue } from 'clsx';
import { Bar, BarChart, XAxis, YAxis } from 'recharts';
import { BaseAxisProps, DataKey, Margin } from 'recharts/types/util/types';

interface BarChartProps<T extends string, U extends string> {
  nameKey: T;
  dataKey: U;
  chartConfig: ChartConfig;
  chartData: any;
  legend?: boolean;
  className?: ClassValue;
  layout?: 'horizontal' | 'vertical';
  margin?: Margin;
  YAxisProps?: BaseAxisProps;
  XAxisProps?: BaseAxisProps;
}

export default function SimpleStackedBarChart<
  T extends DataKey<string>,
  U extends DataKey<string[]>,
>({
  nameKey,
  dataKey,
  chartData,
  chartConfig,
  legend,
  className,
  layout = 'horizontal',
  margin = {
    top: 10,
    right: 10,
    bottom: 10,
    left: 10,
  },
  YAxisProps,
  XAxisProps,
}: BarChartProps<T, U>) {
  return (
    <ChartContainer config={chartConfig} className={cn('', className)}>
      <BarChart
        accessibilityLayer
        data={chartData}
        layout={layout}
        margin={margin}
      >
        {layout === 'horizontal' && (
          <>
            <XAxis
              dataKey={nameKey}
              tickLine={false}
              tickMargin={10}
              type="category"
              {...XAxisProps}
            />
            <YAxis
              tickLine={false}
              tickMargin={10}
              tickFormatter={(value) => value}
              {...YAxisProps}
            />
          </>
        )}

        {layout === 'vertical' && (
          <>
            <XAxis
              type="number"
              dataKey={dataKey}
              orientation="top"
              {...XAxisProps}
            />
            <YAxis
              dataKey={nameKey}
              type="category"
              tickLine={false}
              tickMargin={10}
              {...YAxisProps}
            />
          </>
        )}
        <ChartTooltip content={<ChartTooltipContent className="w-60" />} />
        {legend && (
          <ChartLegend
            content={<ChartLegendContent className="text-md" />}
            verticalAlign="top"
          />
        )}
        {Object.keys(chartConfig).map((key) => {
          return (
            <Bar
              dataKey={key}
              key={key}
              stackId="a"
              fill={chartConfig[key].color}
              radius={[0, 0, 0, 0]}
              maxBarSize={100}
              isAnimationActive={false}
            />
          );
        })}
      </BarChart>
    </ChartContainer>
  );
}
