import PageErrorFallback from '@app/components/layout/page/components/page-error-fallback';
import { ErrorBoundary } from '@sentry/react';
import { useGetCompanyReport } from '@shared/api';
import { CompanyReportDetail, CompanyReportSummary } from '@shared/api/types';
import { createContext, useContext } from 'react';
import { Outlet, useParams } from 'react-router-dom';

export type TContext = CompanyReportDetail & {
  reportStatus?: 'error' | 'success' | 'loading';
};

const Context = createContext<TContext | null>(null);

export function useCCF() {
  return useContext(Context) as TContext;
}

export default function CCFContext() {
  const params = useParams();
  const companyReportUuid = params.report_uuid;

  const { data: companyReport, status: companyReportStatus } =
    useGetCompanyReport(companyReportUuid!);

  const context: TContext = {
    reportStatus: companyReportStatus,
    ...companyReport,
    summary: companyReport?.summary as CompanyReportSummary[],
  };

  return (
    <>
      <ErrorBoundary fallback={<PageErrorFallback />}>
        <Context.Provider value={context}>
          <Outlet />
        </Context.Provider>
      </ErrorBoundary>
    </>
  );
}
