import { ccfRoutes } from '@app/ccf/ccf-routes';
import { useCCF } from '@app/ccf/reports/ccf-context';
import { useDeleteCompanyReportImport } from '@shared/api/hooks/ccf/imports';
import { ActivityImportStatusEnum } from '@shared/api/types';
import { Icons } from '@shared/components/content/icons';
import { Button } from '@shared/components/ui/button';
import { generatePath, useNavigate } from 'react-router-dom';

interface ImportRowActionsProps {
  importUuid: string;
  status: ActivityImportStatusEnum;
}

export const ImportRowActions = ({
  importUuid,
  status,
}: ImportRowActionsProps) => {
  const { uuid: reportUuid } = useCCF();

  const { mutateAsync: deleteImport, isLoading: isDeleting } =
    useDeleteCompanyReportImport();
  const navigate = useNavigate();

  const goToReviewImport = () => {
    navigate(
      generatePath(ccfRoutes.FOOD_IMPORTS_REVIEW, {
        report_uuid: reportUuid,
        import_uuid: importUuid,
      })
    );
  };

  return (
    <div className="flex flex-row items-center justify-end space-x-2">
      {status === 'processed' && (
        <Button
          variant="secondary"
          size="sm"
          disabled={isDeleting}
          onClick={goToReviewImport}
        >
          Review import
        </Button>
      )}
      {status === 'import failed' && (
        <Button
          variant="secondary"
          size="sm"
          disabled={isDeleting}
          onClick={goToReviewImport}
        >
          Retry import
        </Button>
      )}
      <Button
        variant="ghost"
        size="icon"
        icon={<Icons.x />}
        className="size-8"
        disabled={isDeleting}
        onClick={() =>
          deleteImport({
            companyReportUuid: reportUuid!,
            importUuid,
          })
        }
      />
    </div>
  );
};
