import Text from '@shared/components/content/text';
import { ChartConfig } from '@shared/components/ui/chart';
import { roundNumberToNDecimalPlaces } from '@shared/lib/utils';
import { useCCFReportResult } from '../../ccf-report-result-context';
import CCFReportMetric from '../../components/ccf-report-metric';
import CCFPieChartLegend from '../../components/charts/ccf-pie-chart-legend';
import SimplePieChart from '../../components/charts/simple-pie-chart';
import ReportContent from '../../components/report-content';

export default function Scope1() {
  const {
    result: { scope1 },
  } = useCCFReportResult();

  const data = {
    scope1_emissions: Number(scope1.totalScopeEmissionsTonnes),
    pct_of_total_emissions: Number(scope1.pctOfCompanyEmissions),
    emissions_by_category: scope1.categories.map((category, idx) => {
      return {
        category: category.name,
        emissions: category.totalCategoryEmissionsTonnes,
        pct: category.pctOfScopeEmissions,
        fill: `var(--chart-${idx + 2})`,
      };
    }),
  };

  const chartConfig = {
    emissions: {
      label: 'Emissions',
    },
    'stationary combustion': {
      label: 'Stationary combustion',
      color: 'var(--chart-2)',
    },
    'mobile combustion': {
      label: 'Mobile combustion',
      color: 'var(--chart-3)',
    },
    'direct gas emissions': {
      label: 'Direct gas emissions',
      color: 'var(--chart-4)',
    },
  } satisfies ChartConfig;

  return (
    <ReportContent>
      <>
        <Text variant="reportBody">
          Scope 1 emissions are direct emissions from sources your company owns
          or controls. This could include fuel used by your fleet vehicles,
          emissions from on-site equipment, or refrigerants used in cooling
          systems.
          <br />
          <br />
          For food companies, refrigeration leakages are often a significant
          Scope 1 contributor, especially for businesses with cold storage.
        </Text>
        <CCFReportMetric
          title="Scope 1 emissions"
          value={roundNumberToNDecimalPlaces(data.scope1_emissions, 2)}
          unit="tCO2e"
          size="lg"
          className="my-8"
        />
        <CCFReportMetric
          title="Share of total emissions"
          value={data.pct_of_total_emissions}
          unit="%"
          size="md"
          className="my-8"
        />
      </>
      <>
        <Text variant="h3">Scope 1 emissions by category</Text>
        <div className="space-y-3">
          {data.emissions_by_category.map((category) => {
            return (
              <div key={category.category}>
                <CCFPieChartLegend
                  name={category.category}
                  emissions={category.emissions}
                  percentage={category.pct}
                  color={category.fill}
                />
              </div>
            );
          })}
        </div>
        <SimplePieChart
          nameKey="category"
          dataKey="emissions"
          chartData={data.emissions_by_category.reverse()}
          chartConfig={chartConfig}
          innerRadius={80}
          className="flex h-96 w-full"
        />
      </>
    </ReportContent>
  );
}
