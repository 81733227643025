import { ErrorLevel, RowHook } from 'react-spreadsheet-import/types/types';
import { FoodImportRow } from './types';

const sanitizeFoodRow = (row: FoodImportRow) => {
  return {
    ...row,
    name: row.name && row.name.trim(),
    remoteId: row.remoteId && row.remoteId.trim(),
    supplier: row.supplier && row.supplier.trim(),
    quantity: row.quantity && row.quantity.trim(),
    totalWeight: row.totalWeight && row.totalWeight.trim(),
    unit: row.unit && row.unit.trim(),
  };
};

export const validateFoodRow: RowHook<keyof FoodImportRow> = (
  row,
  addError
) => {
  const sanitized = sanitizeFoodRow(row as FoodImportRow);

  const { quantity, totalWeight, unit } = sanitized;
  if (!quantity && !totalWeight)
    addError('quantity', {
      level: 'error' as ErrorLevel,
      message: 'Either a quantity or total weight is required',
    });

  if (totalWeight && !unit) {
    addError('unit', {
      level: 'error' as ErrorLevel,
      message: 'Unit is required when total weight is provided',
    });
  }

  return sanitizeFoodRow(row as FoodImportRow);
};
