import Text from '@shared/components/content/text';
import Unit, { UnitVariant } from '@shared/components/content/unit';
import { cn, roundNumberToNDecimalPlaces } from '@shared/lib/utils';
import { ClassValue } from 'clsx';

type MetricSize = 'lg' | 'md' | 'sm';

interface VariantStyle {
  titleClassName: ClassValue;
  valueClassName?: ClassValue;
  unitClassName?: ClassValue;
}

const sizeStyles: Record<MetricSize, VariantStyle> = {
  lg: {
    titleClassName: 'text-xl ',
    valueClassName: 'text-5xl font-medium',
    unitClassName: 'text-xl',
  },
  md: {
    titleClassName: 'text-md',
    valueClassName: 'text-4xl font-medium',
    unitClassName: 'text-md',
  },
  sm: {
    titleClassName: '',
    valueClassName: '',
  },
};

interface CCFReportMetricProps {
  title?: string;
  value: number | string;
  unit?: UnitVariant;
  size?: MetricSize;
  className?: ClassValue;
}

export default function CCFReportMetric({
  title,
  value,
  unit,
  size = 'sm',
  className,
}: CCFReportMetricProps) {
  const { titleClassName, valueClassName, unitClassName } = sizeStyles[size];

  return (
    <div className={cn('space-y-2', className)}>
      <Text
        className={cn(
          'font-bold text-primary-600 font-heading',
          titleClassName
        )}
      >
        {title}
      </Text>
      <div className="flex items-baseline space-x-2">
        <Text
          variant="reportBody"
          className={cn('text-primary-800', valueClassName)}
        >
          {typeof value === 'string'
            ? value
            : roundNumberToNDecimalPlaces(value as number, 2, false)}
        </Text>
        <Unit variant={unit!} className={unitClassName} />
      </div>
    </div>
  );
}
