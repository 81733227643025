import { Icons } from '@shared/components/content/icons';
import Text from '@shared/components/content/text';
import { HeaderCell } from '@shared/components/data-table/cells/header-cell';
import { TextCell } from '@shared/components/data-table/cells/text-cell';
import { SimpleTooltip } from '@shared/components/simple-tooltip';
import { ColumnDef } from '@tanstack/react-table';
import { FoodImportReviewRow } from './food-import-review-table';

export function foodImportReviewColumns(): ColumnDef<FoodImportReviewRow>[] {
  return [
    {
      accessorKey: 'id',
    },
    {
      accessorKey: 'isEstimated',
    },
    {
      accessorKey: 'missingWeight',
      filterFn: (row, id, value) => row.getValue<boolean>(id) === value,
    },
    {
      accessorKey: 'inputMode',
    },
    {
      id: 'uploadedData',
      accessorKey: 'uploadedData',
      header: ({ column }) => (
        <div className="flex justify-center">
          <HeaderCell column={column} name="Uploaded data" className="" />
        </div>
      ),

      columns: [
        {
          accessorKey: 'name',
          header: ({ column }) => <HeaderCell column={column} name="Name" />,
          cell: ({ getValue, row }) => {
            const isEstimate = row.getValue<boolean>('isEstimated');
            const missingWeight = row.getValue<boolean>('missingWeight');

            return (
              <div className="flex items-center space-x-2">
                <TextCell text={getValue<string>()} />
                {!missingWeight && isEstimate && (
                  <SimpleTooltip
                    trigger={
                      <Icons.warning size={18} className="text-warning-700" />
                    }
                    delayDuration={50}
                    content="This row has an estimated item weight"
                    className="ml-3"
                  />
                )}
                {missingWeight && (
                  <SimpleTooltip
                    trigger={
                      <Icons.alertCircle size={18} className="text-error-700" />
                    }
                    delayDuration={50}
                    content="This row is missing weight data, you must provide it or delete the row"
                    className="ml-3"
                  />
                )}
              </div>
            );
          },
          enableSorting: false,
        },
        {
          accessorKey: 'quantity',
          header: ({ column }) => (
            <HeaderCell column={column} name="Quantity" numberColumn />
          ),
          cell: ({ getValue }) => {
            return (
              <TextCell text={getValue<string>()} className="text-right" />
            );
          },
        },
        {
          accessorKey: 'inputUnit',
          header: ({ column }) => <HeaderCell column={column} name="Unit" />,
          cell: ({ getValue }) => {
            return <TextCell text={getValue<string>()} />;
          },
          enableSorting: false,
        },
        {
          accessorKey: 'itemsPerPack',
        },
      ],
    },
    {
      accessorKey: 'remoteId',
      header: ({ column }) => (
        <HeaderCell column={column} name="ID" className="text-nowrap" />
      ),
      cell: ({ getValue }) => {
        return <Text>{getValue<string>()}</Text>;
      },
    },
    {
      accessorKey: 'supplier',
      header: ({ column }) => (
        <HeaderCell column={column} name="Supplier" className="text-nowrap" />
      ),
      enableSorting: false,
    },

    {
      id: 'processedData',
      accessorKey: 'processedData',
      header: ({ column }) => (
        <div className="flex justify-center">
          <HeaderCell
            column={column}
            name="Processed data"
            className="text-nowrap text-center"
          />
        </div>
      ),
      columns: [
        {
          accessorKey: 'itemsPurchased',
          accessorFn: (row) => row.itemsPerPack,
          header: ({ column }) => (
            <HeaderCell column={column} name="Items purchased" numberColumn />
          ),
          cell: ({ row, getValue }) => {
            const inputMode = row.getValue<string>('inputMode');
            const quantity = row.getValue<string>('quantity');
            const itemsPerPurchase = getValue<number>();

            let text = '-';

            if (inputMode === 'packs' && itemsPerPurchase && quantity) {
              text =
                itemsPerPurchase === 1
                  ? quantity
                  : `${quantity} x ${itemsPerPurchase}`;
            }

            return <TextCell text={text} className="text-right" />;
          },
        },
        {
          accessorKey: 'itemWeight',
          accessorFn: (row) => row.itemMassOrVolume,
          header: ({ column }) => (
            <HeaderCell column={column} name="Item weight" numberColumn />
          ),
          cell: ({ row, getValue }) => {
            const inputMode = row.getValue<string>('inputMode');
            const itemAmount = getValue<number>();
            const unit =
              row.getValue<FoodImportReviewRow['itemUnit']>('itemUnit');

            const text =
              inputMode === 'packs'
                ? itemAmount && `${itemAmount} ${unit?.symbol}`
                : '-';

            return <TextCell text={text || '-'} className="text-right" />;
          },
        },
        {
          accessorKey: 'totalWeight',
          header: ({ column }) => (
            <HeaderCell column={column} name="Total weight" numberColumn />
          ),
          cell: ({ row }) => {
            const {
              itemMassOrVolume,
              quantity,
              itemsPerPack,
              itemUnit,
              inputMode,
              totalMassOrVolume,
            } = row.original;

            let totalWeight = 0;

            if (inputMode === 'packs') {
              const missingWeight =
                !itemMassOrVolume || !itemsPerPack || !quantity;
              totalWeight = missingWeight
                ? 0
                : quantity * itemMassOrVolume * itemsPerPack;
            }

            if (inputMode === 'total-weight' && totalMassOrVolume) {
              totalWeight = totalMassOrVolume;
            }

            return (
              <TextCell
                text={`${totalWeight.toFixed()} ${itemUnit?.symbol}`}
                className="text-right"
              />
            );
          },
        },
      ],
    },
    {
      accessorKey: 'itemUnitWeight',
    },
    {
      accessorKey: 'itemUnit',
    },
  ];
}
