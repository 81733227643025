import Text from '@shared/components/content/text';
import Logo from '@shared/components/logo/logo';
import { Button } from '@shared/components/ui/button';
import { cn, formatDate } from '@shared/lib/utils';
import { useCCFReportResult } from './report/ccf-report-result-context';

export default function CCFReportIntro() {
  const { result, downloadMode } = useCCFReportResult();

  return (
    <div
      className={cn('flex items-center bg-primary h-screen', {
        ' h-[--page-height] w-[--page-width]': downloadMode,
      })}
    >
      <div className="space-y-32 px-20">
        {downloadMode ? (
          <Logo variant="name-white-png" className="w-[6cm]" />
        ) : (
          <Logo variant="name" color="light" className="w-80" />
        )}
        <div className="space-y-4">
          <Text variant="h1" className="font-heading text-4xl text-gray-00">
            {result?.organization.name}
          </Text>
          <Text variant="h1" className="font-heading text-4xl text-teal-400">
            {result?.title}
          </Text>
          <Text className="text-md text-gray-00">
            {formatDate(result?.startDate as string, 'do MMMM yyyy')} -
            {formatDate(result?.endDate as string, 'do MMMM yyyy')}
          </Text>
        </div>
        {!downloadMode && (
          <Button
            variant="support"
            size="lg"
            className="border-none bg-warning-500 text-primary"
            onClick={() => {
              window.location.hash = '#introduction';
            }}
          >
            View report
          </Button>
        )}
        <div>
          <img
            src={
              new URL(
                `@shared/assets/client-assets/ghg-logo.png`,
                import.meta.url
              ).href
            }
            className="w-48"
            alt="greenhouse gas protocol logo"
          />
        </div>
      </div>
    </div>
  );
}
