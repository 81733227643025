import { ccfRoutes } from '@app/ccf/ccf-routes';
import { useCCF } from '@app/ccf/reports/ccf-context';
import Page from '@app/components/layout/page/page';
import { SupportButton } from '@shared/components/buttons/support-button';
import Text from '@shared/components/content/text';
import { Button } from '@shared/components/ui/button';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import CCFFoodImportTable from '../components/table/food-import-table';

export default function CCFFoodImportsListPage() {
  const navigate = useNavigate();
  const { report_uuid: reportUuid } = useParams();
  const { summary } = useCCF();
  const food = summary?.find((s) => s.slug === 'food');

  return (
    <Page name="Scope 3 - Food and drink imports">
      <div className="flex flex-row items-center justify-between space-x-2">
        <Text className="px-4 text-md font-medium">Manage imports</Text>
        <div className="flex items-center gap-2">
          <Button
            size="sm"
            variant="ghost"
            disabled={food?.status === 'unstarted'}
            onClick={() =>
              navigate(
                generatePath(ccfRoutes.FOOD, {
                  report_uuid: reportUuid,
                })
              )
            }
          >
            View food and drink
          </Button>
          <SupportButton article="food" size="sm" />
          <Button
            size="sm"
            onClick={() =>
              navigate(
                generatePath(ccfRoutes.IMPORT_DOWNLOAD, {
                  report_uuid: reportUuid,
                })
              )
            }
          >
            New import
          </Button>
        </div>
      </div>
      <CCFFoodImportTable />
    </Page>
  );
}
