import { ccfRoutes } from '@app/ccf/ccf-routes';
import PageHeaderToolbar from '@app/components/layout/page/components/page-header-toolbar';
import Text from '@shared/components/content/text';
import { Button } from '@shared/components/ui/button';
import { formatDate } from 'date-fns';
import { generatePath, useNavigate } from 'react-router-dom';
import { useCCF } from '../ccf-context';

export default function CCFLayoutToolbar() {
  const navigate = useNavigate();
  const {
    uuid: reportUuid,
    status: reportStatus,
    lastPublishedAt,
    firstPublishedAt,
    summary,
  } = useCCF();
  const reportUnstarted = summary.every(
    ({ status }) => status === 'unstarted' || !status
  );

  return (
    <PageHeaderToolbar>
      <div className="relative text-right md:min-w-96">
        <div className="space-x-2">
          <Button
            onClick={() => {
              navigate(
                generatePath(ccfRoutes.REPORT_RESULT, {
                  report_uuid: reportUuid,
                })
              );
            }}
            variant="outline"
            disabled={reportStatus === 'Unpublished'}
          >
            View report
          </Button>
          <Button
            onClick={() => {
              navigate(
                generatePath(ccfRoutes.PUBLISH, {
                  report_uuid: reportUuid,
                })
              );
            }}
            disabled={reportStatus === 'published' || reportUnstarted}
          >
            Publish
          </Button>
        </div>

        {reportStatus === 'edited' && (
          <Text
            variant="subtle"
            className="absolute left-0 top-0 mt-12 hidden w-full text-nowrap md:block"
          >
            There are unpublished changes not visible in your report
          </Text>
        )}

        <div className="absolute right-0 top-20 flex min-w-max space-x-4">
          {lastPublishedAt && (
            <div>
              <Text className="text-sm font-medium">Last published</Text>
              <Text variant="subtle">
                {formatDate(lastPublishedAt!, 'do MMMM yyyy')}
              </Text>
            </div>
          )}
          {firstPublishedAt && (
            <div>
              <Text className="text-sm font-medium">First published</Text>
              <Text variant="subtle">
                {formatDate(firstPublishedAt, 'do MMMM yyyy')}
              </Text>
            </div>
          )}
        </div>
      </div>
    </PageHeaderToolbar>
  );
}
