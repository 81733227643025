import Page from '@app/components/layout/page/page';
import useSimpleDialog from '@app/hooks/use-simple-dialog';
import Text from '@shared/components/content/text';
import { useMemo } from 'react';
import { useCCF } from '../../ccf-context';
import ElectricityEditForm from '../../components/scope-2/electricity-edit-form';
import ElectricityTable from '../../components/scope-2/electricity-table';
import EmptyCategoryPage from '../ccf-category-intro-page';

export default function ElectricityPage() {
  const { summary } = useCCF();
  const {
    showSimpleDialog: showElectricityDialog,
    SimpleDialogComponent: ElectricityDialog,
    closeSimpleDialog: closeElectricityDialog,
  } = useSimpleDialog();

  const categoryStatus = useMemo(
    () => summary?.filter((item) => item.slug === 'electricity')[0].status,
    [summary]
  );

  const description = (
    <>
      <Text>
        Electricity refers to the{' '}
        <span className="font-semibold">energy your business purchases</span>{' '}
        from external sources to power operations. These purchases lead to
        indirect emissions (Scope 2), which are generated during the production
        of electricity and attributed to your business based on energy
        consumption.
      </Text>
      <Text className="mt-3">Examples include:</Text>
      <Text>
        <ul className="list-disc pl-5">
          <li>
            Power purchased from the{' '}
            <span className="font-semibold">national grid</span> to support
            lighting, heating, and running equipment at your facilities.
          </li>
          <li>
            <span className="font-semibold">
              Renewable energy credits (RECs);
            </span>{' '}
            certificates purchased to offset emissions from grid electricity or
            support clean energy production.
          </li>
        </ul>
      </Text>
    </>
  );

  return (
    <Page name="Scope 2 - Electricity">
      {categoryStatus != 'unstarted' ? (
        <ElectricityTable />
      ) : (
        <EmptyCategoryPage
          categorySlug="electricity"
          description={description}
          primaryActionProps={{
            onClick: showElectricityDialog,
          }}
          supportArticle="electricity"
        />
      )}
      {ElectricityDialog({
        header: 'Add electricity data',
        content: <ElectricityEditForm closeDialog={closeElectricityDialog} />,
      })}
    </Page>
  );
}
