export const useImporterTranslations = () => {
  return {
    uploadStep: {
      title: 'Upload your spreadsheet',
      manifestTitle: (
        <p className="text-lg">What data does it need to contain?</p>
      ),
      manifestDescription: (
        <div className="space-y-6 py-6">
          <div>
            <p>The key columns to include are:</p>
            <ul className="list-disc pl-6">
              <li>
                <b>Name</b> of the purchased item, this can include information
                about weights, units etc.
              </li>
              <li>
                An amount purchased, which can be expressed in one of two ways:{' '}
                <ul className="list-disc pl-6">
                  <li>
                    <b>Total weight</b> - normally in kg or litres - select this
                    if available
                  </li>
                  <li>
                    <b>Quantity</b> - normally expressed in each, packs, or a
                    variety of units - select this if a total weight isn&apos;t
                    available
                  </li>
                </ul>
              </li>
              <li>
                <b>Unit</b> - select if the column exists in your data, but this
                can also be extracted or estimated by our AI tool
              </li>
            </ul>
          </div>
          <div>
            <p>
              You can also include the following columns to improve data
              filtering in our platform:
            </p>
            <ul className="list-disc pl-6">
              <li>ID - your product ID or SKU</li>
              <li>Supplier - the name of your supplier</li>
            </ul>
          </div>
          <p className="font-semibold">
            You will be able to select these columns from your data in Step 3.
          </p>
        </div>
      ),
    },
    matchColumnsStep: {
      title: 'Select the columns',
      userTableTitle: 'Your spreadsheet',
    },
    validationStep: {
      nextButtonTitle: 'Upload data',
    },
    alerts: {
      unmatchedRequiredFields: {
        headerTitle: 'Not all columns matched',
        bodyText:
          'There are required columns that are not matched or ignored. Please match all required columns to continue.',
        listTitle: 'Columns not matched:',
        cancelButtonTitle: 'Cancel',
        continueButtonTitle: 'Continue',
      },
    },
  };
};
