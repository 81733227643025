import { ApiError } from '@app/api/errors';
import { zodResolver } from '@hookform/resolvers/zod';
import { useGetCompanyReportPeriods, useGetSites } from '@shared/api';
import {
  useCreateDirectGasEmissionsActivity,
  useDeleteDirectGasEmissionsActivity,
  useGetDirectGasEmissionsActivity,
  useUpdateDirectGasEmissionsActivity,
} from '@shared/api/hooks/ccf/activities/direct-gas-emissions';
import { useGetGases } from '@shared/api/hooks/ccf/gases';
import { useGetActivityTypeUnits } from '@shared/api/hooks/ccf/units';
import { SourceEnum, TimePeriodTypeEnum } from '@shared/api/types';
import { RepeatableFormRows } from '@shared/components/form/repeatable-form-rows';
import SelectFormField from '@shared/components/form/select-form-field';
import SelectSearchFormField from '@shared/components/form/select-search-form-field';
import Loader from '@shared/components/loader';
import SimpleSelect from '@shared/components/simple-select';
import { Button } from '@shared/components/ui/button';
import { Form } from '@shared/components/ui/form';
import { useToast } from '@shared/components/ui/use-toast';
import sentry from '@shared/services/sentry';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { array, object, string, z } from 'zod';
import { useCCF } from '../../ccf-context';
import {
  emptyReadingRow,
  renderRows,
  useFormattedSites,
  useFormattedSources,
  useGetRowTimePeriods,
  useUnitOptions,
} from '../../utils';
import DirectGasReadingRow from './direct-gas-records-row';

const directGasFormSchema = object({
  site: string({
    required_error: 'Please provide a site',
  }).min(1),
  source: string().min(1),
  gas: string().min(1),
  unit: string().min(1),
  records: array(
    object({
      uuid: string().optional(),
      quantity: z.coerce.number().min(0),
      timePeriod: string().min(1),
    })
  ),
});

interface DirectGasEditFormProps {
  activityUuid?: string;
  closeDialog: () => void;
}

export default function DirectGasEditForm({
  activityUuid,
  closeDialog,
}: DirectGasEditFormProps) {
  const { uuid: reportUuid } = useCCF();
  const { toast } = useToast();
  const [timePeriod, setTimePeriod] = useState<string>('Year');

  const { mutateAsync: createDirectGasActivity, isLoading: isCreating } =
    useCreateDirectGasEmissionsActivity();
  const { mutateAsync: updateDirectGasActivity, isLoading: isUpdating } =
    useUpdateDirectGasEmissionsActivity();
  const { mutateAsync: deleteDirectGasActivity, isLoading: isDeleting } =
    useDeleteDirectGasEmissionsActivity();

  const isSubmitting = isCreating || isUpdating;
  const siteHasActivity = !!activityUuid;

  const form = useForm<z.infer<typeof directGasFormSchema>>({
    resolver: zodResolver(directGasFormSchema),
  });
  const { getValues, reset, handleSubmit, watch } = form;
  const site = watch('site');
  const gas = watch('gas');

  const { data: activity, isLoading: isActivityLoading } =
    useGetDirectGasEmissionsActivity({
      reportUuid,
      directGasActivityUuid: activityUuid,
    });

  const { data: periodOptions, isLoading: isPeriodsLoading } =
    useGetCompanyReportPeriods(reportUuid!);
  const { data: sites, isLoading: isSitesLoading } = useGetSites({
    page: 1,
    page_size: 1000,
  });

  const { data: gases, isLoading: isGasesLoading } = useGetGases(
    {
      site,
    },
    {
      enabled: !!site || siteHasActivity,
    }
  );
  const { data: units, isLoading: isUnitsLoading } = useGetActivityTypeUnits(
    'direct-gas-emissions',
    {
      site,
      gas,
    },
    {
      enabled: !!gas || siteHasActivity,
    }
  );

  const onSubmit = async (data: z.infer<typeof directGasFormSchema>) => {
    try {
      if (siteHasActivity) {
        await updateDirectGasActivity({
          companyReportUuid: reportUuid!,
          activityUuid,
          data: {
            records: data.records.map((reading) => ({
              uuid: reading.uuid!,
              quantity: reading.quantity,
              unit: data.unit,
              timePeriod: reading.timePeriod,
            })),
          },
        });
      } else {
        await createDirectGasActivity({
          companyReportUuid: reportUuid!,
          data: {
            site: data.site,
            gas: data.gas,
            source: data.source as SourceEnum,
            records: data.records.map((reading) => ({
              quantity: reading.quantity,
              unit: data.unit,
              timePeriod: reading.timePeriod,
            })),
          },
        });
      }

      toast({
        title: `${siteHasActivity ? 'Updated' : 'Added'} direct gas data for site`,
        variant: 'success',
      });
      closeDialog();
    } catch (error) {
      sentry.log(error);
      let message;
      if (
        error instanceof ApiError &&
        error.type === 'activity-already-exists'
      ) {
        message =
          'This site already has direct gas data, please update the existing records instead';
      }

      toast({
        title: `Failed to ${siteHasActivity ? 'update' : 'add'} direct gas data`,
        description: message,
        variant: 'destructive',
      });
    }
  };

  const handleDelete = async () => {
    if (!activityUuid) return;
    try {
      await deleteDirectGasActivity({
        companyReportUuid: reportUuid!,
        activityUuid,
      });
      toast({
        title: 'Deleted direct gas data',
        variant: 'success',
      });
      closeDialog();
    } catch (error) {
      sentry.log(error);
      toast({
        title: 'Failed to delete direct gas data',
        variant: 'destructive',
      });
    }
  };

  const formattedSites = useFormattedSites(sites?.results || []);
  const rowTimePeriods = useGetRowTimePeriods(timePeriod, periodOptions!);
  const formattedSources = useFormattedSources();
  const formattedUnits = useUnitOptions(units!);

  const formattedGases = useMemo(
    () =>
      gases?.map((gas) => ({
        label: gas.name,
        value: gas.uuid,
      })),
    [gases]
  );

  useEffect(() => {
    if (timePeriod) {
      reset({
        site: getValues('site'),
        source: getValues('source'),
        gas: getValues('gas'),
        unit: getValues('unit'),
        records: renderRows({
          timePeriod: timePeriod,
          unit: getValues('unit'),
          timePeriods: rowTimePeriods!,
        }),
      });
    }
  }, [getValues, reset, rowTimePeriods, timePeriod]);

  useEffect(() => {
    if (activity) {
      reset({
        site: activity.site.uuid,
        source: activity.source,
        gas: activity.gas.uuid,
        unit: activity.records[0].unit.uuid,
        records: activity.records.map((record) => ({
          uuid: record.uuid,
          quantity: record.quantity,
          unit: record.unit.uuid,
          timePeriod: record.timePeriod.uuid,
        })),
      });
    }
  }, [activity, activityUuid, reset]);

  useEffect(() => {
    const selectedGasNotInGasesList =
      gas && gases && !gases.find((f) => f.uuid === gas);
    if (selectedGasNotInGasesList) {
      reset({
        site,
        gas: null,
        unit: null,
        records: renderRows({
          timePeriod: timePeriod,
          unit: null,
          timePeriods: rowTimePeriods!,
        }),
      });
    }
  }, [gases, gas, reset, rowTimePeriods, timePeriod, site]);
  if (
    isSitesLoading ||
    isPeriodsLoading ||
    (siteHasActivity && (isActivityLoading || isGasesLoading || isUnitsLoading))
  ) {
    return <Loader />;
  }

  const noGasesAvailable = !isGasesLoading && gases?.length === 0;
  const noGasSelected = !noGasesAvailable && !gas;
  const gasPlaceholder = !site
    ? 'Select a site first'
    : noGasesAvailable
      ? 'No gases available'
      : '';

  return (
    <Form {...form}>
      <form className="mt-2 space-y-6" onSubmit={handleSubmit(onSubmit)}>
        <div className="space-y-4">
          <div className="grid grid-cols-12 gap-2">
            <SelectFormField
              name="site"
              label="Site"
              options={formattedSites!}
              className={'col-span-4'}
              disabled={siteHasActivity}
            />
            <SelectFormField
              name="source"
              label="Source"
              options={formattedSources}
              disabled={siteHasActivity}
              className="col-span-4"
            />
            <SelectSearchFormField
              name="gas"
              label="Gas"
              options={formattedGases!}
              loading={!!site && isGasesLoading}
              disabled={
                siteHasActivity || !site || noGasesAvailable || isGasesLoading
              }
              placeholder={gasPlaceholder}
              className="col-span-4"
            />
          </div>
          {!siteHasActivity && (
            <div className="grid grid-cols-12 gap-2">
              <SelectFormField
                name="unit"
                label="Unit"
                options={formattedUnits}
                className="col-span-4"
                loading={!!gas && isUnitsLoading}
                disabled={
                  siteHasActivity ||
                  noGasesAvailable ||
                  noGasSelected ||
                  isUnitsLoading ||
                  !units
                }
                placeholder={
                  noGasesAvailable || noGasSelected ? 'Select a gas first' : ''
                }
              />
              <SimpleSelect
                options={Object.keys(TimePeriodTypeEnum)}
                disabled={siteHasActivity}
                value={timePeriod}
                onValueChange={(value) => {
                  setTimePeriod(value);
                }}
                className="col-span-4"
                label="Time period"
              />
            </div>
          )}
        </div>

        <div>
          {(timePeriod || siteHasActivity) && (
            <RepeatableFormRows
              name="records"
              includeErrorMessage
              showAddButton={false}
              component={DirectGasReadingRow}
              newRowObject={emptyReadingRow}
            />
          )}
        </div>
        <div className="mt-12 flex justify-between">
          <div className="col-span-4">
            {activityUuid && (
              <Button
                type="button"
                size="lg"
                variant="outline-destructive"
                loading={isDeleting}
                disabled={isSubmitting}
                onClick={handleDelete}
              >
                Delete direct gas data
              </Button>
            )}
          </div>
          <div className="flex space-x-2">
            <div className="col-span-4">
              <Button
                type="button"
                size="lg"
                variant="secondary"
                onClick={() => {
                  closeDialog();
                  reset();
                }}
                disabled={isSubmitting || isDeleting}
              >
                Cancel
              </Button>
            </div>
            <div className="col-span-4">
              <Button
                type="submit"
                size="lg"
                loading={isSubmitting}
                disabled={isSubmitting || isDeleting}
              >
                {siteHasActivity ? 'Update' : 'Add'}
              </Button>
            </div>
          </div>
        </div>
      </form>
    </Form>
  );
}
