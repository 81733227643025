import Text from '@shared/components/content/text';
import TextList from '@shared/components/content/text-list';
import ReportContent from '../../components/report-content';

export default function Scope1CoverageAppendix() {
  return (
    <ReportContent variant="appendix" size="md">
      <>
        <Text variant="reportBody">
          Scope 1 encompasses all direct greenhouse gas emissions from owned or
          controlled sources, including stationary and mobile combustion, as
          well as process emissions resulting from business operations, and
          direct gas emissions (fugitive emissions).
        </Text>
        <Text variant="h4">Scope 1 categories</Text>
        <TextList className="space-y-4 text-md font-light">
          <li>
            <span className="font-medium">Stationary combustion</span> refers to
            the burning of fuels, such as natural gas, diesel, or propane, to
            produce energy on the business premises. Examples include heating
            buildings, running boilers, or powering generators.
          </li>
          <li>
            <span className="font-medium">Mobile combustion</span> refers to the
            burning of fuels, such as diesel, gasoline, or biodiesel, in the
            company-owned vehicles.
          </li>
          <li>
            <span className="font-medium">
              Direct gas emissions (fugitive emissions)
            </span>{' '}
            come from the use and leakage of specific gases during business
            operations.
          </li>
        </TextList>
      </>
    </ReportContent>
  );
}
