import Page from '@app/components/layout/page/page';
import { useGetFoodImports } from '@shared/api/hooks/ccf/imports';
import Text from '@shared/components/content/text';
import { useEffect, useMemo } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { ccfRoutes } from '../../../ccf-routes';
import { useCCF } from '../../ccf-context';
import FoodTable from '../../components/scope-3/ccf-food-table';
import EmptyCategoryPage from '../ccf-category-intro-page';

export default function CCFFoodAndDrinkPage() {
  const navigate = useNavigate();
  const { summary, uuid: reportUuid } = useCCF();

  const foodStatus = useMemo(
    () => summary?.filter((item) => item.slug === 'food')[0].status,
    [summary]
  );
  const { data: imports, status } = useGetFoodImports(reportUuid!, {});

  useEffect(() => {
    if (
      status === 'success' &&
      imports?.results &&
      imports.results.length > 0 &&
      foodStatus === 'unstarted'
    ) {
      navigate(
        generatePath(ccfRoutes.FOOD_IMPORTS_LIST, {
          report_uuid: reportUuid,
        })
      );
    }
  }, [foodStatus, imports?.results, navigate, reportUuid, status]);

  return (
    <Page name="Scope 3 - Food and drink" status={status}>
      {foodStatus != 'unstarted' ? (
        <FoodTable />
      ) : (
        <EmptyCategoryPage
          categorySlug="food"
          description={
            <div className="space-y-4">
              <Text>
                Your purchased food & drink falls within Scope 3, Category 1,
                Purchased Goods & Services. This represents{' '}
                <b>the majority of your emissions</b>, and is therefore the only
                <b>Scope 3 Category</b> that Wrap requires you to report in
                their sector-specific guidance.
              </Text>
              <Text>
                We are adding more Categories for your Scope 3, but for now you
                can upload your purchased food and drink data and start
                measuring your Scope 3 emissions.
              </Text>
            </div>
          }
          primaryActionText="Begin import"
          primaryActionProps={{
            onClick: () =>
              navigate(
                generatePath(ccfRoutes.IMPORT_DOWNLOAD, {
                  report_uuid: reportUuid,
                })
              ),
          }}
          supportArticle="food"
        />
      )}
    </Page>
  );
}
