import { ApiError } from '@app/api/errors';
import { useGetReportResults } from '@shared/api/hooks/ccf/results';
import { CompanyReportResult } from '@shared/api/types';
import { createContext, useContext } from 'react';
import { Outlet, useLocation, useParams } from 'react-router-dom';

export type CCFReportContext = {
  result: CompanyReportResult;
  resultStatus: 'error' | 'success' | 'loading';
  resultError?: ApiError;
  downloadMode: boolean;
};

const CCFReportResultContext = createContext<CCFReportContext | null>(null);

export function useCCFReportResult() {
  return useContext(CCFReportResultContext) as CCFReportContext;
}

export default function CCFReportContextProvider() {
  const { pathname } = useLocation();
  const params = useParams();
  const report_uuid = params.report_uuid;

  const {
    data: reportResult,
    status: reportResultStatus,
    error: reportResultError,
  } = useGetReportResults(report_uuid!);

  const context: CCFReportContext = {
    result: reportResult!,
    resultStatus: reportResultStatus,
    resultError: reportResultError as ApiError,
    downloadMode: pathname.endsWith('/download'),
  };

  return (
    <CCFReportResultContext.Provider value={context}>
      <Outlet />
    </CCFReportResultContext.Provider>
  );
}
