import { Column, Table } from '@tanstack/react-table';
import { Label } from '../ui/label';
import { Switch } from '../ui/switch';

interface DataTableBooleanFilterProps<TData, TValue> {
  column?: Column<TData, TValue>;
  title?: string;
  table: Table<TData>;
}

export function DataTableBooleanFilter<TData, TValue>({
  column,
  title,
  table,
}: DataTableBooleanFilterProps<TData, TValue>) {
  return (
    <div className="flex items-center space-x-2">
      <Switch
        id="filter"
        checked={
          column?.getFilterValue() === undefined
            ? false
            : (column?.getFilterValue() as boolean)
        }
        onCheckedChange={() => {
          const filterValue = column?.getFilterValue();
          table.resetPageIndex();
          column?.setFilterValue(filterValue === undefined ? true : undefined);
        }}
      />
      <Label htmlFor="filter">{title}</Label>
    </div>
  );
}
