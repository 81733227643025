import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from '@shared/components/ui/chart';
import { cn } from '@shared/lib/utils';
import { ClassValue } from 'clsx';
import { Label, Pie, PieChart } from 'recharts';
import { DataKey, Margin } from 'recharts/types/util/types';

interface PieChartProps<T extends string, U extends string> {
  nameKey: T;
  dataKey: U;
  chartData: { [key in T | U]: key extends T ? string : number }[];
  chartConfig: ChartConfig;
  className?: ClassValue;
  innerRadius?: number;

  centerLabel?: {
    value: string | number;
    label: string;
  };

  margin?: Margin;
}

export default function SimplePieChart<
  T extends DataKey<string>,
  U extends DataKey<string>,
>({
  nameKey,
  dataKey,
  chartConfig,
  chartData,
  centerLabel,
  className,
  innerRadius = 60,
  margin = {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
}: PieChartProps<T, U>) {
  return (
    <ChartContainer
      config={chartConfig}
      className={cn('min-h-[200px] overflow-hidden p-0', className)}
    >
      <PieChart margin={margin}>
        <ChartTooltip
          cursor={false}
          content={<ChartTooltipContent hideLabel className="w-44" />}
        />
        <Pie
          data={chartData}
          dataKey={dataKey}
          nameKey={nameKey}
          innerRadius={innerRadius}
          strokeWidth={0}
          startAngle={90}
          endAngle={450}
          isAnimationActive={false}
        >
          <Label
            content={({ viewBox }) => {
              if (viewBox && 'cx' in viewBox && 'cy' in viewBox) {
                return (
                  <text
                    x={viewBox.cx}
                    y={viewBox.cy}
                    textAnchor="middle"
                    dominantBaseline="middle"
                  >
                    <tspan
                      x={viewBox.cx}
                      y={viewBox.cy}
                      className="fill-foreground text-3xl font-bold"
                    >
                      {centerLabel?.value.toLocaleString()}
                    </tspan>
                    <tspan
                      x={viewBox.cx}
                      y={(viewBox.cy || 0) + 24}
                      className="fill-muted-foreground"
                    >
                      {centerLabel?.label}
                    </tspan>
                  </text>
                );
              }
            }}
          />
        </Pie>
      </PieChart>
    </ChartContainer>
  );
}
