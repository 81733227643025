import Text from '@shared/components/content/text';
import { ChartConfig } from '@shared/components/ui/chart';
import { useCCFReportResult } from '../../ccf-report-result-context';
import CCFChartLegend from '../../components/charts/ccf-chart-legend';
import SimpleStackedBarChart from '../../components/charts/simple-stacked-bar-chart';
import ReportContent from '../../components/report-content';

export default function Scope1BySite() {
  const {
    result: { scope1 },
  } = useCCFReportResult();

  const chartData = scope1.emissionsBySite?.map((site) => {
    const emissions_data = site.categories.map((category) => {
      const key = category.name.split(' ')[0].toLowerCase();
      return {
        [key]: category.emissionsTonnes,
      };
    });

    return {
      site: site.siteName,
      ...emissions_data.reduce((acc, curr) => ({ ...acc, ...curr }), {}),
    };
  });

  const chartConfig = {
    stationary: {
      label: 'Stationary combustion',
      color: 'var(--chart-2)',
    },
    mobile: {
      label: 'Mobile combustion',
      color: 'var(--chart-3)',
    },
    direct: {
      label: 'Direct gas emissions',
      color: 'var(--chart-4)',
    },
  } satisfies ChartConfig;
  return (
    <ReportContent variant="full">
      <>
        <Text variant="h3">Total emissions per site by category</Text>
        <div className="mb-2 flex space-x-9">
          {Object.entries(chartConfig).map((category, idx) => {
            return (
              <div key={idx}>
                <CCFChartLegend
                  name={category[1].label}
                  color={category[1].color}
                />
              </div>
            );
          })}
        </div>

        <SimpleStackedBarChart
          nameKey="site"
          dataKey=""
          chartData={chartData}
          chartConfig={chartConfig}
          className="h-max max-h-[calc(var(--content-height)-20rem)] w-full"
          margin={{ top: 30 }}
          YAxisProps={{
            label: {
              value: 'tCO2e',
              position: 'top',
              offset: 15,
            },
          }}
        />
      </>
    </ReportContent>
  );
}
