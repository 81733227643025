import Text from '@shared/components/content/text';
import Logo from '@shared/components/logo/logo';
import { Button } from '@shared/components/ui/button';
import { cn } from '@shared/lib/utils';
import { generatePath, useNavigate } from 'react-router-dom';
import { scroller } from 'react-scroll';
import { useCCFReportResult } from '../ccf-report-result-context';

interface CcfReportLayoutTitleProps {
  title: string;
  preTitle?: string;
  link?: {
    title: string;
    scrollTo?: string;
    linkTo?: string;
  };
  scrollContainerId?: string;
}

export default function CcfReportLayoutTitle({
  title,
  preTitle,
  link,
  scrollContainerId,
}: CcfReportLayoutTitleProps) {
  const { downloadMode } = useCCFReportResult();
  const navigate = useNavigate();

  const scrollToPage = (hash: string) => {
    if (!scrollContainerId) return;
    scroller.scrollTo(hash, {
      containerId: scrollContainerId,
      duration: 0,
      smooth: false,
    });
  };

  return (
    <div className="flex h-[--title-height] w-full items-end justify-between bg-teal-50 p-9 pb-4 pt-7">
      <div className="mt-2 flex-col justify-end">
        <Text
          variant="body"
          className="font-heading font-semibold uppercase text-primary-600"
        >
          {preTitle}
        </Text>
        <Text variant="h1" className="font-heading text-primary-800">
          {title}
        </Text>
      </div>
      {!downloadMode && link && (
        <Button
          variant="outline"
          className={cn('self-end bg-teal-50 font-light shadow-none')}
          onClick={() =>
            (link.scrollTo && scrollToPage(link.scrollTo)) ??
            navigate(generatePath(link.linkTo!, { id: link.scrollTo }))
          }
        >
          {link.title}
        </Button>
      )}
      {downloadMode && (
        <div className="w-[5cm]">
          <Logo variant="name-png" />
        </div>
      )}
    </div>
  );
}
