import { Fields } from 'react-spreadsheet-import/types/types';

const maxLengthValidation = (length: number) => ({
  rule: 'regex',
  value: `^.{0,${length}}$`,
  errorMessage: `Must not be longer than ${length} characters`,
  level: 'error',
});

const requiredValidation = (name: string) => ({
  rule: 'required',
  errorMessage: `${name} is required`,
  level: 'error',
});

export const getCleanupFoodFields: () => Fields<string> = () => [
  {
    label: 'Name',
    key: 'name',
    alternateMatches: ['title', 'description'],
    fieldType: {
      type: 'input',
    },
    example: 'Cherry tomatoes',
    validations: [requiredValidation('Name'), maxLengthValidation(240)],
  },
  {
    label: 'Quantity',
    key: 'quantity',
    alternateMatches: ['qty', 'amount'],
    fieldType: {
      type: 'input',
    },
    example: '200 x 10 packs',
    validations: [maxLengthValidation(36)],
  },
  {
    label: 'Total weight',
    key: 'totalWeight',
    alternateMatches: ['weight', 'total'],
    fieldType: {
      type: 'input',
    },
    example: '242.5',
    validations: [maxLengthValidation(36)],
  },
  {
    label: 'Unit',
    key: 'unit',
    alternateMatches: ['uom', 'units'],
    fieldType: {
      type: 'input',
    },
    example: 'kg',
    validations: [maxLengthValidation(36)],
  },
  {
    label: 'ID',
    key: 'remoteId',
    alternateMatches: ['product id', 'sku', 'id'],
    fieldType: {
      type: 'input',
    },
    example: '#HK-TOM-1',
    validations: [maxLengthValidation(240)],
  },
  {
    label: 'Supplier',
    key: 'supplier',
    alternateMatches: ['supplier', 'vendor', 'manufacturer'],
    fieldType: {
      type: 'input',
    },
    example: 'HK Foods',
    validations: [maxLengthValidation(240)],
  },
];
