import { ccfRoutes } from '@app/ccf/ccf-routes';
import GuideTemplate from '@shared/components/guide-template';
import { useToast } from '@shared/components/ui/use-toast';
import { useFormContext } from 'react-hook-form';
import { generatePath, useNavigate } from 'react-router-dom';
import { useCCF } from '../ccf-context';
import { useProcessScopes } from '../utils';
import { ScopeReviewRow } from './components/scope-review-row';

export default function ReportReviewScope3() {
  const navigate = useNavigate();
  const { uuid: reportUuid } = useCCF();

  const { scope3 } = useProcessScopes();
  const { trigger } = useFormContext();
  const { toast } = useToast();

  const validateScope3 = async () => {
    const isValid = await trigger();

    if (!isValid) {
      toast({
        title: 'Please ensure all fields are filled',
        variant: 'destructive',
      });
    }
  };

  return (
    <GuideTemplate
      preTitle=""
      title="Review Scope 3"
      description="For each category, if there is no data please select a reason from the dropdown. For categories with data, you should check that all the relevant data has been included."
      primaryActionText="Publish"
      primaryActionProps={{
        onClick: () => validateScope3(),
        type: 'submit',
      }}
      secondaryActionText="Back"
      secondaryActionProps={{
        onClick: () => {
          navigate(
            generatePath(ccfRoutes.PUBLISH_REVIEW_SCOPE_2, {
              report_uuid: reportUuid,
            })
          );
        },
      }}
    >
      <div className="w-[1000px] max-w-lg space-y-6">
        {scope3.map((item) => {
          return (
            <ScopeReviewRow
              key={item.slug}
              icon={item.icon}
              name={item.name}
              description={item.description}
              slug={item.slug}
              status={item.status!}
              statusDescription={item.statusDescription!}
              isSupported={item.isSupported}
            />
          );
        })}
      </div>
    </GuideTemplate>
  );
}
