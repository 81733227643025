import { Card, CardContent, CardHeader } from '@shared/components/ui/card';

import Text from '@shared/components/content/text';
import Unit from '@shared/components/content/unit';
import { cn } from '@shared/lib/utils';
import { ClassValue } from 'clsx';
import ReportContent from '../../components/report-content';

function CustomCard({
  title,
  description,
}: {
  title: string;
  description: string;
}) {
  return (
    <Card className="border-none bg-primary-50 text-primary-600">
      <CardHeader className="pb-1 pt-4">
        <Text className="font-heading font-bold">{title}</Text>
      </CardHeader>

      <CardContent className="pb-3">
        <Text>{description}</Text>
      </CardContent>
    </Card>
  );
}

function Metric({
  title,
  value,
  unit,
  className,
}: {
  title: string;
  value: number;
  unit: string;
  className?: ClassValue;
}) {
  return (
    <div className={cn('flex flex-col items-center', className)}>
      <Text variant="h1" className="font-medium">
        {value}
      </Text>
      <Text className="text-md">{title}</Text>
      <Unit variant={unit} className="text-md" />
    </div>
  );
}

export default function CalculatingEmissions() {
  return (
    <ReportContent>
      <>
        <Text variant="reportBody">
          Your Company Carbon Footprint (CCF) is calculated by summing up the
          emissions of all of your business activities in the reporting year.
          <br />
          <br />A business activity is any operation or process that generates
          greenhouse gas emissions. These activities are categorised into three
          categories:
        </Text>
        <div className="space-y-4">
          <CustomCard
            title="Scope 1 - Direct emissions"
            description="Direct emissions from sources your company owns
          or controls. This could include fuel used by your fleet vehicles,
          emissions from on-site equipment, or refrigerants used in cooling
          systems."
          />
          <CustomCard
            title="Scope 2 - Indirect emissions from energy"
            description="Indirect emissions from purchased energy, such as
          electricity, heating, or cooling. Though these emissions occur
          off-site, they’re tied to your company’s consumption and are
          influenced by your energy choices."
          />
          <CustomCard
            title="Scope 3 - All other indirect emissions"
            description="Any other emissions that occur in your value chain. For food businesses this will be largely from the food and drink you purchase, but also includes waste disposal and business travel for example."
          />
        </div>
      </>
      <>
        <Text variant="h3">How are emissions calculated?</Text>
        <Text variant="reportBody">
          For each of your business activities, we find the relevant Emission
          Factor from our database of research values. We use this factor to
          calculate the total emissions for that activity, based on the amount
          of the activity you did in the year.
          <br />
          <br />
          For each activity it is actually a relatively simple calculation! For
          example if in this year, you bought in total 100 kilos of ginger:
        </Text>
        <div className="flex w-full space-x-6">
          <Metric title="Quantity" value={100} unit="kg" />
          <Text variant="h3" className="mt-2 font-medium text-primary-500">
            x
          </Text>
          <Metric title="Emission Factor" value={0.44} unit="kgCO2e/kg" />
          <Text variant="h3" className="mt-2 font-medium text-primary-500">
            =
          </Text>
          <Metric title="Total Emissions" value={44} unit="kgCO2e" />
        </div>
        <Text variant="reportBody">
          Your total Company Carbon Footprint is simply the sum of the emissions
          of all of your activities within the year!
        </Text>
      </>
    </ReportContent>
  );
}
