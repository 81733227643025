import {
  AvailableIngredients,
  AvailableIngredientsBaseFood,
} from '@shared/api/types';
import {
  CategorizedPicker,
  Category,
} from '@shared/components/form/categorized-picker';
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@shared/components/ui/form';
import { cn } from '@shared/lib/utils';
import { ClassValue } from 'clsx';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

interface MatchedFoodPickerProps {
  name: string;
  availableIngredients: AvailableIngredients;
  className: ClassValue;
  includeErrorMessage?: boolean;
  showLabel?: boolean;
  modal?: boolean;
}

export default function MatchedFoodPicker({
  name,
  className,
  availableIngredients,
  includeErrorMessage = false,
  showLabel = false,
  modal = false,
}: MatchedFoodPickerProps) {
  const { control, setValue } = useFormContext();

  const categories: Category<AvailableIngredientsBaseFood>[] = useMemo(() => {
    const baseFoodCategory: Category<AvailableIngredientsBaseFood> = {
      name: 'Matched food',
      items: availableIngredients.baseFoods,
      searchableKeys: ['name', 'synonyms.name', 'synonyms.namePlural'],
      valueKey: 'uuid',
      labelKey: 'name',
      requireSearch: true,
      onSelect: (selectedItem: AvailableIngredientsBaseFood) => {
        setValue(`matchedFoodUuid`, selectedItem.uuid);
      },
    };

    return [baseFoodCategory] as Category<AvailableIngredientsBaseFood>[];
  }, [availableIngredients.baseFoods, setValue]);

  return (
    <FormField
      control={control}
      name={name}
      render={({ field, fieldState }) => {
        return (
          <FormItem className={cn('grid flex-1 items-center', className)}>
            {showLabel && <FormLabel>Matched food</FormLabel>}
            <FormControl>
              {field.value && (
                <CategorizedPicker
                  categories={categories}
                  value={field.value}
                  error={fieldState.error?.message}
                  modal={modal}
                />
              )}
            </FormControl>
            {includeErrorMessage && <FormMessage />}
          </FormItem>
        );
      }}
    />
  );
}
