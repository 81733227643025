import { reportSlugs } from '@app/ccf/reports/report/report-utils';
import { Category } from '@shared/api/types';
import Text from '@shared/components/content/text';
import TextList from '@shared/components/content/text-list';
import { ChartConfig } from '@shared/components/ui/chart';
import { useCCFReportResult } from '../../ccf-report-result-context';
import CCFReportMetric from '../../components/ccf-report-metric';
import SimpleStackedBarChart from '../../components/charts/simple-stacked-bar-chart';
import ReportContent from '../../components/report-content';

export default function FuelAndEnergy() {
  const {
    result: { scope3, fuelAndEnergy },
  } = useCCFReportResult();

  const {
    totalCategoryEmissionsTonnes,
    pctOfScopeEmissions,
    pctOfCompanyEmissions,
  } = scope3.categories.find(
    (category) => category.slug === reportSlugs.fuelAndEnergy
  ) as Category;

  const data = {
    fuel_and_energy_emissions: totalCategoryEmissionsTonnes,
    share_of_scope_emissions: pctOfScopeEmissions,
    share_of_total_emissions: pctOfCompanyEmissions,
    emissions_by_category: fuelAndEnergy?.emissionsByCategory.map(
      ({ category, totalEmissionsTonnes }) => ({
        category,
        emissions: totalEmissionsTonnes,
        fill: 'var(--chart-3)',
      })
    ),
  };

  const chartConfig = {
    emissions: {
      label: 'Emissions',
      color: 'hsl(var(--chart-1))',
    },
  } satisfies ChartConfig;

  return (
    <ReportContent>
      <>
        <Text variant="reportBody">
          The direct emissions from fuel and energy are accounted for in Scopes
          1 and 2, however, there are related indirect emissions associated with
          these activities, which are accounted for in Scope 3.
        </Text>
        <CCFReportMetric
          title="Category emissions"
          value={data.fuel_and_energy_emissions!}
          unit="tCO2e"
          size="lg"
        />
        <div className="flex space-x-2">
          <CCFReportMetric
            title="Share of Scope 3 emissions"
            value={data.share_of_scope_emissions!}
            unit="%"
            size="md"
            className="flex-1"
          />
          <CCFReportMetric
            title="Share of total emissions"
            value={data.share_of_total_emissions!}
            unit="%"
            size="md"
            className="flex-1"
          />
        </div>
        <div className="space-y-4">
          <Text variant="h4">Upstream fuel and energy activities</Text>
          <TextList className="space-y-2 text-md">
            <li>
              <span className="font-medium">Well-to-tank</span> - Emissions from
              extraction, processing and transportation of fuels, either for
              direct combustion or for generation of purchased energy{' '}
            </li>
            <li>
              <span className="font-medium">Generation</span> - Emissions from
              the generation of purchased energy
            </li>
            <li>
              <span className="font-medium">Transmission losses</span> -
              Emissions from the losses in the transmission or distribution of
              purchased energy{' '}
            </li>
          </TextList>
        </div>
      </>
      <>
        <Text variant="h3">Emissions by category</Text>
        <SimpleStackedBarChart
          nameKey="category"
          dataKey="emissions"
          chartData={data.emissions_by_category}
          chartConfig={chartConfig}
          className="size-full max-h-[calc(var(--content-height)-15rem)]"
          margin={{ top: 50 }}
          YAxisProps={{
            label: {
              value: 'tCO2e',
              position: 'top',
              offset: 30,
            },
          }}
        />
      </>
    </ReportContent>
  );
}
