import { useGetCompanyReportImport } from '@shared/api/hooks/ccf/imports';
import { getCompanyReportsRetrieveQueryKey } from '@shared/api/lib/company-reports/company-reports';
import { useToast } from '@shared/components/ui/use-toast';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { ccfRoutes } from '../../ccf-routes';
import FoodImportLoader from '../components/food-import-loader';
import { useFoodImport } from '../food-import-layout';

export default function FoodImportLoadingPage() {
  const { importUuid, reportUuid, numberOfActivities } = useFoodImport();
  const navigate = useNavigate();
  const { toast } = useToast();
  const queryClient = useQueryClient();

  const duration =
    numberOfActivities && Math.ceil(numberOfActivities / 100) * 7500;

  const { data: importData } = useGetCompanyReportImport({
    reportUuid,
    importUuid: importUuid!,
    polling: true,
  });

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (importData?.status === 'completed') {
      queryClient.invalidateQueries([
        getCompanyReportsRetrieveQueryKey(reportUuid),
      ]);
      timeoutId = setTimeout(() => {
        navigate(
          generatePath(ccfRoutes.FOOD, {
            report_uuid: reportUuid,
          })
        );
      }, 1000);
    }

    if (importData?.status === 'import failed') {
      toast({
        title: 'Failed to import food data',
        description:
          'Please check the data is correctly formatted and try again',
        variant: 'destructive',
      });
      navigate(
        generatePath(ccfRoutes.FOOD_IMPORTS_REVIEW, {
          report_uuid: reportUuid,
          import_uuid: importUuid,
        })
      );
    }
    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [
    importData?.status,
    importUuid,
    navigate,
    queryClient,
    reportUuid,
    toast,
  ]);

  useEffect(() => {
    if (!importUuid) {
      navigate(
        generatePath(ccfRoutes.IMPORT_FOOD, {
          report_uuid: reportUuid,
        })
      );
    }
  }, [importUuid, navigate, reportUuid]);

  return (
    <div className="flex w-full items-center justify-center pt-6">
      <FoodImportLoader
        forceComplete={importData?.status === 'completed'}
        duration={duration}
      />
    </div>
  );
}
