import { ccfRoutes } from '@app/ccf/ccf-routes';
import Page from '@app/components/layout/page/page';
import Text from '@shared/components/content/text';
import { Button } from '@shared/components/ui/button';
import { SidebarProvider } from '@shared/components/ui/sidebar';
import { cn } from '@shared/lib/utils';
import { useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import {
  generatePath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { Element, scroller } from 'react-scroll';
import CCFReportIntro from '../ccf-report-intro';
import { useCCFReportResult } from './ccf-report-result-context';
import CcfReportLayoutHeader from './components/ccf-report-layout-header';
import CcfReportLayoutTitle from './components/ccf-report-layout-title';
import { CCFReportSidebar } from './components/ccf-report-sidebar';
import { ReportSection, useReportSections } from './report-sections';
import { useFormattedReportSections } from './report-utils';

function ReportPage({
  item,
  setActive,
  containerId,
}: {
  item: ReportSection;
  setActive: React.Dispatch<React.SetStateAction<string>>;
  containerId: string;
}) {
  const { title, preTitle, url, link } = item;
  const { ref, inView } = useInView({
    threshold: 0.3,
  });

  useEffect(() => {
    if (inView) {
      window.location.hash = url;
      setActive(url);
    }
  }, [containerId, inView, setActive, url]);

  return (
    <div ref={ref} className={cn('w-full h-full overflow-hidden')}>
      <Element name={url} key={title} id={url}>
        <CcfReportLayoutTitle
          title={title}
          preTitle={preTitle}
          link={link}
          scrollContainerId={containerId}
        />
        {item.component}
      </Element>
    </div>
  );
}

const useDebouncedScrollSnap = ({
  activePage,
  containerId,
  debounceDuration = 1000,
  scrollDuration = 300,
}: {
  activePage: string;
  containerId: string;
  debounceDuration?: number;
  scrollDuration?: number;
}) => {
  const [stablePage, setStablePage] = useState<string | null>(null);
  const debounceTimeout = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (!activePage) return;
    if (debounceTimeout.current) clearTimeout(debounceTimeout.current);
    debounceTimeout.current = setTimeout(
      () => setStablePage(activePage),
      debounceDuration
    );
    return () => {
      if (debounceTimeout.current) clearTimeout(debounceTimeout.current);
    };
  }, [activePage, debounceDuration]);

  useEffect(() => {
    if (!stablePage) return;
    scroller.scrollTo(stablePage, {
      containerId,
      smooth: 'easeOutQuart',
      duration: scrollDuration,
    });
  }, [stablePage, containerId, scrollDuration]);
};

export default function CCFReport() {
  const { hash } = useLocation();
  const [active, setActive] = useState<string>('Introduction');
  const { result, resultStatus, resultError } = useCCFReportResult();
  const { report_uuid } = useParams();
  const navigate = useNavigate();
  const scrollContainerId = 'main-report';
  useDebouncedScrollSnap({
    activePage: active,
    containerId: scrollContainerId,
  });
  const reportSections = useReportSections(report_uuid!);

  const formattedData = useFormattedReportSections(reportSections, result);

  return (
    <Page
      name={result?.title}
      status={resultStatus}
      fallbackComponent={
        <div className="my-auto flex h-screen flex-col items-center justify-center space-y-4">
          <Text>
            {resultError?.type === 'report-result-not-found'
              ? resultError?.message
              : 'Error getting results for this report'}
          </Text>
          <Button
            onClick={() =>
              resultError?.type === 'report-result-not-found'
                ? navigate(
                    generatePath(ccfRoutes.COMPANY_REPORT, {
                      report_uuid: report_uuid!,
                    })
                  )
                : navigate(generatePath(ccfRoutes.COMPANY_REPORTS))
            }
          >
            Go back to report
          </Button>
        </div>
      }
    >
      {result && (
        <>
          {!hash && <CCFReportIntro />}
          {hash && (
            <SidebarProvider className="flex-col">
              <div
                className="
            overflow-hidden
            [--content-height:calc(100vh-var(--header-height))]
            [--header-height:72px]
            [--title-height:108px]
          "
              >
                <div className="h-[--header-height]">
                  <CcfReportLayoutHeader />
                </div>
                <div className="flex">
                  <div className="h-[--content-height]">
                    <CCFReportSidebar
                      scrollContainerId={scrollContainerId}
                      activeItem={active}
                      data={reportSections}
                    />
                  </div>
                  <main
                    id={scrollContainerId}
                    className="h-full flex-1 overflow-y-scroll lg:h-[--content-height]"
                  >
                    {formattedData.map((item) => {
                      if (item.show) {
                        return (
                          <ReportPage
                            key={item.url}
                            containerId={scrollContainerId}
                            item={item}
                            setActive={setActive}
                          />
                        );
                      }
                    })}
                  </main>
                </div>
              </div>
            </SidebarProvider>
          )}
        </>
      )}
    </Page>
  );
}
