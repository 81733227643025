import Text from '@shared/components/content/text';
import TextList from '@shared/components/content/text-list';
import { ChartConfig } from '@shared/components/ui/chart';
import SimplePieChart from '../../components/charts/simple-pie-chart';
import ReportContent from '../../components/report-content';

const chartData = [
  { industry: 'other', emissions: 66, fill: 'var(--chart-1' },
  { industry: 'food', emissions: 34, fill: 'var(--chart-3)' },
];

const chartData2 = [
  { industry: 'other', emissions: 29, fill: 'var(--chart-1)' },
  {
    industry: 'purchased food and drink',
    emissions: 71,
    fill: 'var(--chart-3)',
  },
];

const chartConfig = {
  emissions: {
    label: 'Emissions',
  },
  food: {
    label: 'Food industry',
    color: 'hsl(var(--chart-1))',
  },
  other: {
    label: 'Other industries',
    color: 'hsl(var(--chart-2))',
  },
} satisfies ChartConfig;

const chartConfig2 = {
  emissions: {
    label: 'Emissions',
  },
  'purchased food and drink': {
    label: 'Food emissions',
  },
  other: {
    label: 'Other emissions',
    color: 'hsl(var(--chart-2))',
  },
} satisfies ChartConfig;

export default function Introduction() {
  return (
    <ReportContent>
      <>
        <Text variant="reportBody" className="text-md">
          A Company Carbon Footprint (CCF) represents the total Greenhouse Gas
          (GHG) emissions associated with your company&apos;s operations in a
          reporting year. This includes everything from energy and fuel use to
          supply chain activities and waste disposal, providing a full picture
          of your carbon impact.
        </Text>
        <div>
          <Text variant="h4" className="mb-4">
            Why is it important?
          </Text>
          <Text variant="reportBody" className="mb-4">
            A CCF is an essential tool for measuring, understanding, and
            managing emissions across your organisation. Calculating your CCF
            allows you to:
          </Text>
          <TextList className="space-y-2 text-md font-light">
            <li>
              Understand your impact: identify emissions hotspots across your
              operations and value chain, like purchased goods and waste
              disposal.
            </li>
            <li>
              Align with regulations (e.g. SECR, CSRD) and meet stakeholder
              requests to report your carbon emissions.
            </li>
            <li>
              Guide meaningful action: with a clear emissions profile, you can
              make targeted reductions and track progress toward sustainability
              goals.
            </li>
          </TextList>
        </div>
      </>
      <>
        <Text variant="h3">The impact of emissions from food</Text>
        <div className="-mt-8">
          <div className="flex items-center">
            <div className="">
              <SimplePieChart
                nameKey="industry"
                dataKey="emissions"
                chartConfig={chartConfig}
                chartData={chartData}
                innerRadius={30}
                className="min-h-[120px] w-32"
                margin={{
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: 0,
                }}
              />
            </div>

            <Text variant="reportBody" className="col-span-3">
              The food industry is responsible for approximately{' '}
              <span className="font-medium">
                34% of global greenhouse gas emissions.
              </span>
            </Text>
          </div>
          <div className="  flex items-center">
            <div className="col-span-2">
              <SimplePieChart
                nameKey="industry"
                dataKey="emissions"
                chartConfig={chartConfig2}
                chartData={chartData2}
                innerRadius={30}
                className="min-h-[120px] w-32"
              />
            </div>
            <Text variant="reportBody" className="col-span-3">
              For food businesses, on average{' '}
              <span className="font-medium">
                71% of your total emissions come from the food and drink you
                purchase.
              </span>
            </Text>
          </div>
        </div>
        <div>
          <Text variant="reportBody">
            My Emissions enables food businesses to easily and accurately
            calculate the emissions from their purchased food and drink. We
            combine this data with your other business activities to provide a
            comprehensive overview of your annual emissions.
          </Text>
        </div>
        <Text variant="reportBody">
          {' '}
          Source:{' '}
          <a
            className="font-medium"
            href="https://www.nature.com/articles/s43016-021-00225-9"
            target="_blank"
            rel="noreferrer"
          >
            Crippa et al (2021)
          </a>
        </Text>
      </>
    </ReportContent>
  );
}
