import Text from '@shared/components/content/text';
import TextList from '@shared/components/content/text-list';
import ReportContent from '../../components/report-content';

export function EmissionsQuantification() {
  return (
    <ReportContent variant="column">
      <>
        <Text variant="reportBody">
          All emissions are quantified using the{' '}
          <span className="font-semibold">My Emissions </span>platform. All
          emissions are reported in metric tonnes of carbon dioxide equivalent
          (tCO₂e) and the calculation is conducted by multiplying the activity
          data values supplied by the reporting company by the corresponding
          representative emission factor.
        </Text>
        <div className="w-full border border-teal px-8 py-3">
          <Text className="text-lg text-primary">
            Activity data * Emission factor = Activity emissions
          </Text>
        </div>
        <Text variant="h4">Activity data</Text>
        <Text variant="reportBody">
          Activity data is a quantitative measure of the level of activity that
          leads to greenhouse gas emissions. An example of activity data is the
          amount of energy a company consumes during the reporting period. This
          data can be represented in two ways: physical data, such as the
          quantity of fuel used (e.g. litres), or spend-based data, which
          reflects the monetary value of the fuel consumed.
          <br />
          <br />
          Physical data is generally preferred, as it directly measures resource
          usage, whereas spend-based data can be influenced by economic factors
          like inflation or price fluctuations, potentially distorting the
          accuracy of emissions calculations.
        </Text>
      </>
      <>
        <Text variant="h3">
          Understanding CO<sub>2</sub>e
        </Text>
        <Text variant="reportBody">
          Carbon dioxide equivalent (CO₂e) is a standardised unit of measurement
          that allows for the comparison of different greenhouse gases based on
          their Global Warming Potential (GWP). The IPCC AR6 methodology
          outlines the GWP values for different gases, including:
        </Text>
        <TextList className="text-md font-light">
          <li>Carbon dioxide (CO₂)</li>
          <li>Methane (CH₄)</li>
          <li>Nitrous oxide (N₂O)</li>
          <li>Fluorinated gases</li>
        </TextList>
        <Text variant="reportBody">
          CO₂e allows for a clear, unified metric to express the total warming
          effect of various gases, factoring in their individual GWP.
        </Text>
      </>
    </ReportContent>
  );
}
