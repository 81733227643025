import { Icons } from '@shared/components/content/icons';
import { Button } from '@shared/components/ui/button';
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetTitle,
  SheetTrigger,
} from '@shared/components/ui/sheet';
import { cn } from '@shared/lib/utils';
import { useState } from 'react';
import {
  CollapsedSidebarContent,
  ExpandedSidebarContent,
} from './components/sidebar-content';
import {
  DESKTOP_COLLAPSED_SIDEBAR_WIDTH,
  DESKTOP_EXPANDED_SIDEBAR_WIDTH,
  MOBILE_SIDEBAR_WIDTH,
} from './styles';
import { useSidebarItems } from './use-sidebar-items';

export function DesktopSidebar() {
  const items = useSidebarItems();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const sidebarWidth = isCollapsed
    ? DESKTOP_COLLAPSED_SIDEBAR_WIDTH
    : DESKTOP_EXPANDED_SIDEBAR_WIDTH;

  const handleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className="hidden overflow-hidden lg:block">
      <div className="sticky h-screen border-r border-teal-100 bg-gray-00">
        <div
          className={cn(
            'flex h-full transition-all duration-300 ease-in-out',
            sidebarWidth
          )}
        >
          <div
            className={cn(
              'absolute inset-y-0 left-0 transition-opacity duration-300 flex h-full flex-col gap-2',
              sidebarWidth,
              isCollapsed ? 'opacity-100' : 'opacity-0 pointer-events-none'
            )}
          >
            <CollapsedSidebarContent items={items} onExpand={handleCollapse} />
          </div>
          <div
            className={cn(
              'absolute inset-y-0 left-0 transition-opacity duration-300 flex h-full flex-col gap-2',
              sidebarWidth,
              isCollapsed ? 'opacity-0 pointer-events-none' : 'opacity-100'
            )}
          >
            <ExpandedSidebarContent items={items} onCollapse={handleCollapse} />
          </div>
        </div>
      </div>
    </div>
  );
}

export function MobileSidebar() {
  const items = useSidebarItems();

  return (
    <div className="flex flex-col">
      <header className="flex items-center gap-4">
        <Sheet>
          <SheetTitle hidden />
          <SheetDescription hidden />
          <SheetTrigger asChild>
            <Button
              variant="ghost"
              size="icon"
              className="-ml-2 shrink-0 xl:hidden"
            >
              <Icons.menu className="size-5" />
              <span className="sr-only">Toggle navigation menu</span>
            </Button>
          </SheetTrigger>
          <SheetContent
            showCloseButton={false}
            side="left"
            className={cn('flex flex-col gap-2 p-0', MOBILE_SIDEBAR_WIDTH)}
          >
            <ExpandedSidebarContent items={items} />
          </SheetContent>
        </Sheet>
      </header>
    </div>
  );
}
