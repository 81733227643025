import { cn } from '@shared/lib/utils';
import React from 'react';
import { useCCFReportResult } from '../ccf-report-result-context';

interface ReportContentProps {
  children: React.ReactNode | React.ReactNode[];
  variant?: 'column' | 'full' | 'appendix';
  size?: 'full' | 'md';
  className?: string;
}

export default function ReportContent({
  children,
  variant = 'column',
  className,
  size = 'full',
}: ReportContentProps) {
  const { downloadMode } = useCCFReportResult();

  if (variant === 'column') {
    const childrenArray = children as React.ReactNode[];
    return (
      <div
        className={cn(
          'grid grid-cols-1 lg:min-h-[--content-height] lg:grid-cols-12 overflow-hidden',
          {
            'grid-cols-12 h-[--content-height]': downloadMode,
          },
          className
        )}
      >
        <div className="col-span-6 space-y-7 p-9">{childrenArray[0]}</div>
        <div className="col-span-6 space-y-7 bg-teal-100 p-9 text-primary-800">
          {childrenArray[1]}
        </div>
      </div>
    );
  }

  const childrenNode = children as React.ReactNode;
  return (
    <div
      className={cn('p-9 lg:min-h-[--content-height] space-y-7', {
        'bg-teal-100': variant === 'full',
        'h-[--content-height]': downloadMode,
        'max-w-2xl': size === 'md',
        className,
      })}
    >
      {childrenNode}
    </div>
  );
}
