import ReportContent from '../../components/report-content';
import { methodology } from './components/methodologies';
import { MethodologyRow } from './components/methodology-row';

export default function Scope3MethodologyAppendix() {
  return (
    <ReportContent variant="appendix">
      {methodology.map((method, idx) => (
        <MethodologyRow key={idx} methodology={method} scope="scope3" />
      ))}
    </ReportContent>
  );
}
