import { useAxiosConfig } from '@shared/api/AxiosConfigProvider';
import {
  getCompanyReportsImportsListQueryKey,
  useCompanyReportsImportsDestroy,
  useCompanyReportsImportsList,
  useCompanyReportsImportsProcessFoodCreate,
  useCompanyReportsImportsRetrieve,
  useCompanyReportsImportsUploadCreate,
} from '@shared/api/lib/company-reports/company-reports';
import { CompanyReportsImportsListParams } from '@shared/api/types';
import { useQueryClient } from '@tanstack/react-query';

export const useProcessFoodImport = () => {
  return useCompanyReportsImportsProcessFoodCreate({
    request: useAxiosConfig(),
  });
};

export const useGetFoodImports = (
  companyReportUuid: string,
  params?: CompanyReportsImportsListParams & {
    polling?: boolean;
  }
) => {
  return useCompanyReportsImportsList(companyReportUuid, params, {
    request: useAxiosConfig(),
    query: {
      refetchInterval: params?.polling && 5000,
      refetchIntervalInBackground: params?.polling,
    },
  });
};

export const useGetFoodImport = ({
  companyReportUuid,
  importUuid,
}: {
  companyReportUuid: string;
  importUuid: string;
}) => {
  return useCompanyReportsImportsRetrieve(companyReportUuid, importUuid, {
    request: useAxiosConfig(),
  });
};

export const useUploadImport = () => {
  return useCompanyReportsImportsUploadCreate({ request: useAxiosConfig() });
};

export const useGetCompanyReportImport = ({
  reportUuid,
  importUuid,
  polling,
}: {
  reportUuid: string;
  importUuid: string;
  polling?: boolean;
}) => {
  return useCompanyReportsImportsRetrieve(reportUuid, importUuid, {
    request: useAxiosConfig(),
    query: {
      enabled: !!importUuid,
      refetchInterval: polling && 1000,
      refetchIntervalInBackground: polling,
    },
  });
};

export const useDeleteCompanyReportImport = () => {
  const queryClient = useQueryClient();
  return useCompanyReportsImportsDestroy({
    request: useAxiosConfig(),
    mutation: {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries([
          getCompanyReportsImportsListQueryKey(variables.companyReportUuid),
        ]);
      },
    },
  });
};
