import { roundNumberToNDecimalPlaces } from '@app/utils/helpers';
import Text from '@shared/components/content/text';
import { ChartConfig } from '@shared/components/ui/chart';
import { useCCFReportResult } from '../../ccf-report-result-context';
import CCFReportMetric from '../../components/ccf-report-metric';
import CCFPieChartLegend from '../../components/charts/ccf-pie-chart-legend';
import SimplePieChart from '../../components/charts/simple-pie-chart';
import ReportContent from '../../components/report-content';

export default function ResultsSummary() {
  const {
    result: { totalCompanyEmissionsTonnes, scope1, scope2, scope3 },
  } = useCCFReportResult();

  const data = {
    total_emissions: Number(totalCompanyEmissionsTonnes),
    emissions_by_scope: [
      {
        scope: 'scope 1',
        emissions: Number(scope1.totalScopeEmissionsTonnes),
        fill: 'var(--chart-2)',
        pct: Number(scope1.pctOfCompanyEmissions),
      },
      {
        scope: 'scope 2',
        emissions: Number(scope2.totalScopeEmissionsTonnes),
        fill: 'var(--chart-3)',
        pct: Number(scope2.pctOfCompanyEmissions),
      },
      {
        scope: 'scope 3',
        emissions: Number(scope3.totalScopeEmissionsTonnes),
        fill: 'var(--chart-4)',
        pct: Number(scope3.pctOfCompanyEmissions),
      },
    ],
  };

  const chartConfig = {
    emissions: {
      label: 'Emissions',
    },
    'scope 1': {
      label: 'Scope 1',
      color: 'var(--chart-2)',
    },
    'scope 2': {
      label: 'Scope 2',
      color: 'var(--chart-3)',
    },
    'scope 3': {
      label: 'Scope 3',
      color: 'var(--chart-4)',
    },
  } satisfies ChartConfig;

  return (
    <ReportContent>
      <>
        <Text variant="reportBody">
          Emissions values are recorded in tonnes of carbon dioxide equivalent
          (tCO<sub>2</sub>e), a standard unit used to measure the impact of
          greenhouse gas emissions, expressed in terms of the amount of CO₂.
        </Text>
        <CCFReportMetric
          title="Total emissions"
          value={roundNumberToNDecimalPlaces(data.total_emissions, 2)}
          unit="tCO2e"
          size="lg"
          className="my-8"
        />
      </>
      <>
        <Text variant="h3">Emissions by scope</Text>
        <div className="space-y-3">
          {data.emissions_by_scope.map((scope) => {
            return (
              <div key={scope.scope}>
                <CCFPieChartLegend
                  name={scope.scope}
                  emissions={scope.emissions}
                  percentage={scope.pct}
                  color={scope.fill}
                />
              </div>
            );
          })}
        </div>
        <SimplePieChart
          nameKey="scope"
          dataKey="emissions"
          chartData={data.emissions_by_scope.reverse()}
          chartConfig={chartConfig}
          innerRadius={80}
          className="h-96 w-full"
        />
      </>
    </ReportContent>
  );
}
