import Text from '@shared/components/content/text';
import { ChartConfig } from '@shared/components/ui/chart';
import { roundNumberToNDecimalPlaces } from '@shared/lib/utils';
import { useCCFReportResult } from '../../ccf-report-result-context';
import CCFReportMetric from '../../components/ccf-report-metric';
import SimpleStackedBarChart from '../../components/charts/simple-stacked-bar-chart';
import ReportContent from '../../components/report-content';

export default function Scope3() {
  const {
    result: { scope3 },
  } = useCCFReportResult();

  const data = {
    scope3_emissions: Number(scope3.totalScopeEmissionsTonnes),
    pct_of_total_emissions: Number(scope3.pctOfCompanyEmissions),
    emissions_by_category: scope3.categories
      .filter((category) => category.status === 'included')
      .map((category, idx) => {
        return {
          category: category.name,
          emissions: category.totalCategoryEmissionsTonnes,
          pct: category.pctOfScopeEmissions,
          fill: 'var(--chart-3)',
        };
      }),
  };

  const chartConfig = {
    emissions: {
      label: 'Emissions',
    },
    'stationary combustion': {
      label: 'Stationary combustion',
      color: 'var(--chart-1)',
    },
    'mobile combustion': {
      label: 'Mobile combustion',
      color: 'var(--chart-3)',
    },
    'direct gas emissions': {
      label: 'Direct gas emissions',
      color: 'var(--chart-4)',
    },
  } satisfies ChartConfig;

  return (
    <ReportContent>
      <>
        <Text variant="reportBody">
          Scope 3 includes all other activities from your value chain, separated
          into 15 categories. These categories cover both upstream and
          downstream activities. For food & drink companies, the majority of
          your emissions usually comes from your purchased food & drink.
        </Text>
        <CCFReportMetric
          title="Scope 3 emissions"
          value={roundNumberToNDecimalPlaces(data.scope3_emissions, 2)}
          unit="tCO2e"
          size="lg"
          className="my-8"
        />
        <CCFReportMetric
          title="Share of total emissions"
          value={data.pct_of_total_emissions}
          unit="%"
          size="md"
          className="my-8"
        />
      </>
      <>
        <Text variant="h3">Scope 3 emissions by category</Text>
        <SimpleStackedBarChart
          nameKey="category"
          dataKey="emissions"
          chartData={data.emissions_by_category}
          chartConfig={chartConfig}
          className="flex h-96 w-full"
          layout="vertical"
          margin={{ right: 80, left: 40 }}
          XAxisProps={{
            label: {
              value: 'tCO2e',
              position: 'right',
              offset: 20,
            },
          }}
        />
      </>
    </ReportContent>
  );
}
