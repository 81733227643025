import { ScopeDescription } from '@app/ccf/reports/utils';
import { Scope } from '@shared/api/types';
import Text from '@shared/components/content/text';
import Unit from '@shared/components/content/unit';
import {
  capitalizeFirstLetter,
  cn,
  removeUnderScore,
  roundNumberToNDecimalPlaces,
} from '@shared/lib/utils';
import { useCCFReportResult } from '../../ccf-report-result-context';
import ReportContent from '../../components/report-content';

const statusesToDisplay = ['included', 'no_emissions'];

function YFCRow({ scope, scopeName }: { scope: Scope; scopeName: string }) {
  const categoriesToDisplay =
    scopeName !== 'scope 3'
      ? scope.categories
      : scope.categories.filter((category) =>
          statusesToDisplay.includes(category.status)
        );
  return (
    <div
      className={cn(
        'col-span-full grid h-full grid-cols-subgrid items-stretch pb-10',
        {
          'border-b': scopeName !== 'scope 3',
        }
      )}
    >
      <div>
        <Text className="text-2xl font-medium text-primary-800">
          {capitalizeFirstLetter(removeUnderScore(scopeName))}
        </Text>
        <Text variant="reportBody" className="text-xl">
          {ScopeDescription[scopeName as 'scope_1' | 'scope_2' | 'scope_3']}
        </Text>
      </div>
      <div className="col-span-2">
        {categoriesToDisplay.map((category, idx) => {
          return (
            <div className="flex w-full justify-between" key={idx}>
              <Text variant="reportBody" className="text-lg  text-primary-600">
                {category.name}
              </Text>
              <Text variant="reportBody" className="text-lg  text-primary-600">
                {['included', 'no_emissions'].includes(category.status)
                  ? roundNumberToNDecimalPlaces(
                      category.totalCategoryEmissionsTonnes,
                      2
                    )
                  : 'Not included'}
              </Text>
            </div>
          );
        })}
      </div>
      <div className="flex flex-col items-end">
        <span className="flex flex-1 items-baseline justify-self-end">
          <Text className="mr-1 text-3xl font-medium text-primary-800">
            {roundNumberToNDecimalPlaces(
              Number(scope.totalScopeEmissionsTonnes!),
              2
            )}
          </Text>
          <Unit variant="tCO2e" />
        </span>
      </div>
    </div>
  );
}

export default function YourFullCalculation() {
  const {
    result,
    result: { scope1, scope2, scope3 },
  } = useCCFReportResult();
  const excludedScope3Categories = scope3.categories.filter(
    (category) => !statusesToDisplay.includes(category.status)
  );
  return (
    <ReportContent variant="appendix">
      <div className="grid grid-cols-4 gap-y-14">
        <div className="col-span-full grid grid-cols-subgrid">
          <div></div>
          <div className="col-span-2 -mb-16">
            <div className="flex w-full justify-between">
              <Text variant="reportBody" className="font-medium">
                Category
              </Text>
              <span className="flex items-baseline">
                <Text variant="reportBody" className="font-medium">
                  Emissions
                </Text>
                <Unit variant="tCO2e" className="ml-1" />
              </span>
            </div>
          </div>
        </div>

        <div className="col-span-full grid grid-cols-subgrid gap-y-10">
          <YFCRow scope={scope1} scopeName={'scope 1'} />
          <YFCRow scope={scope2} scopeName={'scope 2'} />
          <YFCRow scope={scope3} scopeName={'scope 3'} />
        </div>

        <div className="col-span-full grid grid-cols-subgrid">
          <div className="col-span-3">
            <Text variant="subtle" className="text-sm font-light">
              The following Scope 3 categories are excluded from this report
              because they are not deemed significant for the reporting
              business:{' '}
              {excludedScope3Categories
                .map((category) => category.name)
                .join(', ')}
              .
            </Text>
          </div>
          <div className="col-span-1 place-items-end text-teal-500">
            <Text>Total Carbon Footprint</Text>
            <span className="flex items-baseline">
              <Text className="text-3xl font-medium text-teal-500">
                {roundNumberToNDecimalPlaces(
                  Number(result.totalCompanyEmissionsTonnes),
                  2,
                  false
                )}
              </Text>
              <Unit variant="tCO2e" className="ml-1 text-teal-500" />
            </span>
          </div>
        </div>
      </div>
    </ReportContent>
  );
}
