import { useAssessProduct } from '@shared/api';
import { EmissionsCategoryEnum, ProductList } from '@shared/api/types';
import { Icons } from '@shared/components/content/icons';
import Text from '@shared/components/content/text';
import Unit from '@shared/components/content/unit';
import RatingsThresholdSlider from '@shared/components/ratings-thresholds-slider';
import RatingCloud from '@shared/components/ratings/rating-cloud';
import { Badge } from '@shared/components/ui/badge';
import { Button } from '@shared/components/ui/button';
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
} from '@shared/components/ui/card';
import { cn, getUserFriendlyDate } from '@shared/lib/utils';
import { ClassValue } from 'clsx';
import { useNavigate } from 'react-router-dom';
import { ProductTableRowActions } from './tables/product-table/product-table-row-actions';

interface ProductCardProps {
  product: ProductList;
  className: ClassValue;
  onClick?: () => void;
}

export default function ProductCard({
  product,
  className,
  onClick,
}: ProductCardProps) {
  const productInfoContent: ProductInfoProps[] = [
    {
      label: 'Created',
      value: getUserFriendlyDate(product.createdDate),
    },
    {
      label: 'Latest assessment',
      value: product.assessmentDate
        ? getUserFriendlyDate(product.assessmentDate)
        : '-',
      alignRight: true,
    },
    {
      label: 'Carbon intensity',
      value: product.totalEmissionsPerKg
        ? parseFloat(product.totalEmissionsPerKg).toFixed(2)
        : '-',
      unit: product.assessmentDate
        ? () => <Unit variant="kgCO2e/kgfood" className="leading-[18px]" />
        : undefined,
      alignRight: true,
    },
    {
      label: 'Total emissions',
      value: product.totalEmissions
        ? (parseFloat(product.totalEmissions) * 1000).toFixed(2)
        : '-',
      unit: product.totalEmissions
        ? () => <Unit variant="gCO2e" className="leading-[18px]" />
        : undefined,
      alignRight: true,
    },
  ];

  return (
    <div className={cn('p-[2px]', className)}>
      <Card className="min-w-96 border-primary-50 transition-all hover:border-primary-200 hover:shadow-sm">
        <div onClick={onClick}>
          <CardHeader className="space-y-1">
            <div className="flex flex-row items-center">
              <Text variant="cardTitle" className="mr-4 max-w-xl truncate">
                {product.name}
              </Text>
              {product.status === 'draft' && (
                <Badge className="ml-auto">Draft</Badge>
              )}
              {product.status === 'in progress' && (
                <Badge className="ml-auto">Submitted</Badge>
              )}
              {(product.status === 'complete' ||
                product.status === 'editing') && (
                <div className="ml-auto flex items-center justify-between ">
                  <div className="mr-3 mt-2 hidden w-40 md:block">
                    <RatingsThresholdSlider
                      value={parseFloat(
                        parseFloat(product.totalEmissionsPerKg).toFixed(2)
                      )}
                      methodologyVersion={2}
                      showValue={false}
                    />
                  </div>
                  <RatingCloud
                    className="min-w-12 shrink-0 "
                    rating={product.emissionsCategory as EmissionsCategoryEnum}
                  />
                </div>
              )}
            </div>
            {product.collections.length > 0 && (
              <div className="flex flex-wrap gap-1">
                {product.collections.map((collection) => {
                  return (
                    <Badge
                      key={collection.uuid}
                      variant="outline"
                      className="text-nowrap"
                    >
                      {collection.name}
                    </Badge>
                  );
                })}
              </div>
            )}
          </CardHeader>
          <CardContent className="flex flex-row">
            <div className="flex w-full max-w-xl flex-row justify-between space-x-2">
              {productInfoContent.map((info) => (
                <ProductInfo key={info.label} {...info} />
              ))}
            </div>
            <div className="ml-auto flex min-h-full items-end justify-end pl-4">
              <ProductTableRowActions product={product} />
            </div>
          </CardContent>
        </div>
        <CardFooter className="p-0">
          {['draft', 'expired', 'editing'].includes(product.status!) && (
            <ProductCardFooter
              variant={product.status! as ProductVariant}
              productUuid={product.uuid}
            />
          )}
        </CardFooter>
      </Card>
    </div>
  );
}

type ProductVariant = 'draft' | 'expired' | 'editing';
interface ProductCardFooterProps {
  variant: ProductVariant;
  productUuid: string;
}

const ProductCardFooter = ({
  variant,
  productUuid,
}: ProductCardFooterProps) => {
  const navigate = useNavigate();
  const { mutateAsync: assessProduct, isLoading: assessProductLoading } =
    useAssessProduct();
  return (
    <div
      className={cn(
        'size-full px-3 rounded-b-lg z-10 bg-primary-50 border-t border-primary-100',
        {
          'bg-warning-100 border-warning-200': variant === 'expired',
        }
      )}
    >
      {variant === 'draft' && (
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-2">
            <Icons.edit className="size-4" />
            <Text className=" text-sm font-medium text-primary-900/80">
              Draft in progress
            </Text>
          </div>
          <Button
            variant="link"
            onClick={() => navigate(`/products/${productUuid}/edit`)}
          >
            Continue editing
          </Button>
        </div>
      )}
      {variant === 'expired' && (
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-2">
            <Icons.expired className="size-4" />
            <Text className=" text-sm font-medium text-warning-900">
              This assessment has expired
            </Text>
          </div>
          <Button
            variant="link"
            className="text-warning-900"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              assessProduct({ productUuid: productUuid });
            }}
            disabled={assessProductLoading}
          >
            {assessProductLoading ? 'Reassessing...' : 'Reassess product'}
          </Button>
        </div>
      )}
      {variant === 'editing' && (
        <div className="flex flex-row items-center justify-between">
          <div className="flex flex-row items-center space-x-2">
            <Icons.edit className="size-4" />
            <Text className=" text-sm font-medium text-primary-900/80">
              Editing in progress
            </Text>
          </div>
          <Button
            variant="link"
            onClick={() => navigate(`/products/${productUuid}/edit`)}
          >
            Continue editing
          </Button>
        </div>
      )}
    </div>
  );
};

interface ProductInfoProps {
  label: string;
  value: string | number;
  alignRight?: boolean;
  unit?: () => JSX.Element;
}
const ProductInfo = ({ label, value, alignRight, unit }: ProductInfoProps) => (
  <div className="min-w-20">
    <Text variant="subtle">{label}</Text>
    <div className={cn('flex items-end', { 'justify-end': alignRight })}>
      <Text className="mr-1 text-sm">{value}</Text>
      {unit && unit()}
    </div>
  </div>
);
