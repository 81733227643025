import { reportSlugs } from '@app/ccf/reports/report/report-utils';
import { Icons } from '@shared/components/content/icons';
import Text from '@shared/components/content/text';
import { useCCFReportResult } from '../../ccf-report-result-context';
import CCFReportMetric from '../../components/ccf-report-metric';
import ReportContent from '../../components/report-content';

export default function Electricity() {
  const {
    result: { scope2, electricity },
  } = useCCFReportResult();

  const electricityCategory = scope2.categories.find(
    (category) => category.slug === reportSlugs.electricity
  );

  const totalElectricityEmissions =
    electricityCategory?.totalCategoryEmissionsTonnes;
  const shareOfScopeEmissions = electricityCategory?.pctOfScopeEmissions;
  const shareOfTotalEmissions = electricityCategory?.pctOfCompanyEmissions;
  const sitesWithCustomFactors = electricity?.sitesWithElectricitySupply;
  const sitesWithoutCustomFactors = electricity?.sitesWithoutElectricitySupply;
  const sitesWithoutRenewableEnergy =
    electricity?.sitesWithoutFullyRenewableElectricitySupply;
  const emissionsSaved =
    electricity?.locationBased?.totalElectricityEmissionsTonnes;
  const isFullyRenewable = !electricity?.sitesWithoutElectricitySupply;

  return (
    <ReportContent>
      <>
        <Text variant="reportBody">
          Electricity refers to the energy your business purchases from external
          sources to power operations.
        </Text>
        <CCFReportMetric
          title="Category emissions"
          value={totalElectricityEmissions!}
          unit="tCO2e"
          size="lg"
        />
        <div className="flex space-x-2">
          <CCFReportMetric
            title="Share of Scope 1 emissions"
            value={shareOfScopeEmissions!}
            unit="%"
            size="md"
            className="flex-1"
          />
          <CCFReportMetric
            title="Share of total emissions"
            value={shareOfTotalEmissions!}
            unit="%"
            size="md"
            className="flex-1"
          />
        </div>
        <div className="space-y-4">
          <Text variant="h4">Custom emission factors</Text>
          <Text variant="reportBody">
            For each of your sites, we recommend providing specific emission
            factors obtained from your energy suppliers, when not provided we
            use grid average emission factors based on the site location.
          </Text>
        </div>

        <div className="flex space-x-2">
          <CCFReportMetric
            title="Sites with custom factors"
            value={sitesWithCustomFactors!}
            size="md"
            className="flex-1"
          />
          <CCFReportMetric
            title="Sites using grid average"
            value={sitesWithoutCustomFactors!}
            size="md"
            className="flex-1"
          />
        </div>
      </>
      <>
        {isFullyRenewable ? (
          <>
            <div className="flex space-x-2">
              <Icons.leaf className="size-8 text-teal-400" />
              <Text variant="h3">You are 100% renewable!</Text>
            </div>
            <Text variant="reportBody">
              Great work, by consuming only 100% renewable energy supplies you
              have produced no emissions from purchased electricity!
            </Text>
            <CCFReportMetric
              title="Emissions saved"
              value={emissionsSaved!}
              unit="tCO2e"
              size="md"
            />
            <div>
              <Text variant="reportBody">
                This is our estimate for the emissions you would have produced
                if you had consumed the average grid supply.
              </Text>
            </div>
          </>
        ) : (
          <>
            <Text variant="h3">Switching to renewable energy</Text>
            <Text variant="reportBody">
              By switching to 100% renewable energy supplies and then adding
              your custom emission factors into our platform, you can reduce
              your reported emissions from purchased electricity to zero!
            </Text>
            <CCFReportMetric
              title="Sites without 100% renewable energy supply"
              value={sitesWithoutRenewableEnergy!}
              size="md"
            />
            <div>
              <CCFReportMetric
                title="Potential emissions reduction"
                value={totalElectricityEmissions!}
                unit="tCO2e"
                size="md"
              />
            </div>
          </>
        )}
      </>
    </ReportContent>
  );
}
