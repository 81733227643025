import { ccfRoutes } from '@app/ccf/ccf-routes';
import Navbar from '@app/components/layout/nav/navbar';
import Page from '@app/components/layout/page/page';
import { zodResolver } from '@hookform/resolvers/zod';
import { usePublishReport } from '@shared/api/hooks/ccf/activities/publish';
import {
  CompanyReportCategoryStatus,
  CompanyReportCategoryStatusStatusEnum,
  CompanyReportSummary,
  CompanyReportSummaryStatusEnum,
} from '@shared/api/types';
import { Form } from '@shared/components/ui/form';
import { useToast } from '@shared/components/ui/use-toast';
import sentry from '@shared/services/sentry';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { generatePath, Outlet, useNavigate } from 'react-router-dom';
import { z } from 'zod';
import { useCCF } from '../ccf-context';
import ReportPublishLoader from './components/report-publish-loader';
import { PublishReportContext } from './use-publish-report';

export const reasonOptions = [
  {
    value: CompanyReportCategoryStatusStatusEnum['no_emissions'],
    label: 'No emissions to report',
  },
  {
    value: CompanyReportCategoryStatusStatusEnum['not_required'],
    label: 'Not required',
  },
  {
    value: CompanyReportCategoryStatusStatusEnum['not_supported'],
    label: 'Not supported by the platform',
  },
];

const categoryStatuses = Object.values(
  CompanyReportCategoryStatusStatusEnum
).map((value) => value);

function createSchema(
  summary: readonly CompanyReportSummary[],
  statuses: string[]
) {
  return summary.reduce(
    (schema, item) =>
      schema.extend({
        [item.slug]: z.object({
          status: z.enum(statuses as [string, ...string[]], {
            required_error: 'Required',
            message: 'Please select a reason',
          }),
          activityType: z.string().uuid(),
        }),
      }),
    z.object({})
  );
}

function createDefaultValues(
  summary: readonly CompanyReportSummary[],
  categoryStatuses: readonly CompanyReportCategoryStatus[]
) {
  return summary.reduce(
    (acc, item) => {
      // Use existing status from previous publish if available
      let status = categoryStatuses.find(
        (status) => status.activityType === item.uuid
      )?.status;

      // If not, derive status from the category activity status
      if (!status) {
        status =
          !item.status ||
          item.status === CompanyReportSummaryStatusEnum.unstarted
            ? CompanyReportCategoryStatusStatusEnum.no_emissions
            : CompanyReportCategoryStatusStatusEnum.included;
      }

      acc[item.slug] = {
        status,
        activityType: item.uuid,
      };

      return acc;
    },
    {} as Record<
      string,
      {
        status: string;
        activityType: string;
      }
    >
  );
}

export default function ReportPublishLayout() {
  const navigate = useNavigate();
  const { toast } = useToast();
  const {
    uuid: reportUuid,
    summary,
    reportStatus,
    reportCategoryStatuses,
  } = useCCF();

  const { mutateAsync: publishReport } = usePublishReport();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isComplete, setIsComplete] = useState<boolean>(false);

  const publishSchema = summary
    ? createSchema(summary, categoryStatuses)
    : undefined;

  const form = useForm({
    resolver: publishSchema ? zodResolver(publishSchema) : undefined,
    defaultValues: summary
      ? createDefaultValues(summary, reportCategoryStatuses)
      : {},
  });

  useEffect(() => {
    if (summary) {
      form.reset({
        ...createDefaultValues(summary, reportCategoryStatuses),
      });
    }
  }, [form, summary, reportCategoryStatuses]);

  const onSubmit = async (data: z.infer<ReturnType<typeof createSchema>>) => {
    setIsLoading(true);
    try {
      const res = await publishReport({
        companyReportUuid: reportUuid as string,
        data: [
          ...Object.values(data).map((item: any) => ({
            activityType: item.activityType,
            status: item.status,
          })),
        ],
      });

      let timeoutId: NodeJS.Timeout;
      if (res) {
        timeoutId = setTimeout(() => {
          navigate(
            generatePath(ccfRoutes.REPORT_RESULT, {
              report_uuid: reportUuid,
            })
          );
          setIsComplete(true);
          setIsLoading(false);
        }, 4000);
      }

      return () => {
        if (timeoutId) clearTimeout(timeoutId);
      };
    } catch (error) {
      toast({
        title: 'Failed publishing report',
        description: 'Please try again or get in touch if the issue persists.',
        variant: 'destructive',
      });
      sentry.log(error);
      setIsLoading(false);
    }
  };

  const context: PublishReportContext = {
    onSubmit,
  };

  return (
    <Page
      name="Publish your report"
      page="layout"
      className="absolute left-0 top-0 z-10"
      status={reportStatus}
    >
      <div className="flex h-full min-h-screen flex-col bg-gradient-to-r from-teal-300  to-teal-100">
        <Navbar
          logo={{
            variant: 'name',
            color: 'dark',
          }}
          includeCloseButton
          closeButtonProps={{
            onClick: () => {
              navigate(
                generatePath(ccfRoutes.COMPANY_REPORT, {
                  report_uuid: reportUuid,
                })
              );
            },
          }}
        />
        {isLoading ? (
          <div className="flex flex-1 items-center justify-center">
            <ReportPublishLoader duration={4000} forceComplete={isComplete} />
          </div>
        ) : (
          <div className="grid flex-1 grid-cols-1 overflow-y-auto p-2">
            <Form {...form}>
              <form onSubmit={form.handleSubmit(onSubmit)}>
                <Outlet context={context} />
              </form>
            </Form>
          </div>
        )}
      </div>
    </Page>
  );
}
