import Text from '@shared/components/content/text';
import { ChartConfig } from '@shared/components/ui/chart';
import { roundNumberToNDecimalPlaces } from '@shared/lib/utils';
import { useCCFReportResult } from '../../ccf-report-result-context';
import CCFReportMetric from '../../components/ccf-report-metric';
import CCFPieChartLegend from '../../components/charts/ccf-pie-chart-legend';
import SimplePieChart from '../../components/charts/simple-pie-chart';
import ReportContent from '../../components/report-content';

export default function Scope2() {
  const {
    result: { scope2 },
  } = useCCFReportResult();

  const data = {
    scope2_emissions: Number(scope2.totalScopeEmissionsTonnes),
    pct_of_total_emissions: Number(scope2.pctOfCompanyEmissions),
    emissions_by_category: scope2.categories.map((category, idx) => {
      return {
        category: category.name,
        emissions: category.totalCategoryEmissionsTonnes,
        pct: category.pctOfScopeEmissions,
        fill: `var(--chart-${idx + 2})`,
      };
    }),
  };

  const chartConfig = {
    emissions: {
      label: 'Emissions',
    },
    'stationary combustion': {
      label: 'Stationary combustion',
      color: 'var(--chart-2)',
    },
    'mobile combustion': {
      label: 'Mobile combustion',
      color: 'var(--chart-3)',
    },
    'direct gas emissions': {
      label: 'Direct gas emissions',
      color: 'var(--chart-4)',
    },
  } satisfies ChartConfig;

  return (
    <ReportContent>
      <>
        <Text variant="reportBody">
          Scope 2 consists of indirect emissions from purchased energy, such as
          electricity, heating, or cooling. Though these emissions occur
          off-site, they’re tied to your company’s consumption and are
          influenced by your energy choices.
        </Text>
        <CCFReportMetric
          title="Scope 2 emissions"
          value={roundNumberToNDecimalPlaces(data.scope2_emissions, 2)}
          unit="tCO2e"
          size="lg"
          className="my-8"
        />
        <CCFReportMetric
          title="Share of total emissions"
          value={data.pct_of_total_emissions}
          unit="%"
          size="md"
          className="my-8"
        />
      </>
      <>
        <Text variant="h3">Scope 2 emissions by category</Text>
        <div className="space-y-3">
          {data.emissions_by_category.map((category) => {
            return (
              <div key={category.category}>
                <CCFPieChartLegend
                  name={category.category}
                  emissions={category.emissions}
                  percentage={category.pct}
                  color={category.fill}
                />
              </div>
            );
          })}
        </div>
        <SimplePieChart
          nameKey="category"
          dataKey="emissions"
          chartData={data.emissions_by_category.reverse()}
          chartConfig={chartConfig}
          innerRadius={80}
          className="flex h-96 w-full"
        />
      </>
    </ReportContent>
  );
}
