import { EmissionsCategoryEnum } from '@shared/api/types';
import Text from '@shared/components/content/text';
import Unit from '@shared/components/content/unit';
import RatingCloud from '@shared/components/ratings/rating-cloud';
import { Card } from '@shared/components/ui/card';
import { ChartConfig } from '@shared/components/ui/chart';
import {
  RatingToTextEnum,
  roundNumberToNDecimalPlaces,
} from '@shared/lib/utils';
import { useCCFReportResult } from '../../ccf-report-result-context';
import SimplePieChart from '../../components/charts/simple-pie-chart';
import ReportContent from '../../components/report-content';

function HighestEmissionsItemsCard({
  item,
  rating,
  totalPurchased,
  totalEmissions,
  pctOfEmissions,
}: {
  item: string;
  rating: EmissionsCategoryEnum;
  totalPurchased: number;
  totalEmissions: number;
  pctOfEmissions: number;
}) {
  return (
    <Card className="grid grid-cols-11 items-center gap-4 border-teal-400 p-3">
      <Text className="col-span-3 font-heading font-medium">{item}</Text>
      <RatingCloud rating={rating} className="col-span-2 justify-self-center" />
      <Text className="col-span-2 text-right">
        {roundNumberToNDecimalPlaces(totalPurchased, 2, false)}
      </Text>
      <Text className="col-span-2 text-right">
        {roundNumberToNDecimalPlaces(totalEmissions, 2)}
      </Text>
      <Text className="col-span-2 text-right">{pctOfEmissions}%</Text>
    </Card>
  );
}

export default function FoodAndDrinkItems() {
  const {
    result: { foodAndDrink },
  } = useCCFReportResult();
  const data = {
    highest_emissions_items: foodAndDrink?.highestEmissionFoods,
    items_by_rating: foodAndDrink?.itemsByRating,
    items_assessed: foodAndDrink?.itemsByRating.reduce(
      (acc, category) => acc + category.numberOfItems,
      0
    ),
  };

  const chartData = data.items_by_rating?.map((category) => {
    return {
      category: category.rating,
      items: category.numberOfItems,
      fill: `var(--rating-${category.rating.toLowerCase()})`,
    };
  });

  const chartConfig = {
    items: {
      label: 'Items',
    },
    A: {
      label: 'A',
      color: 'var(--rating-a)',
    },
    B: {
      label: 'B',
      color: 'var(--rating-b)',
    },
    C: {
      label: 'C',
      color: 'var(--rating-c)',
    },
    D: {
      label: 'D',
      color: 'var(--rating-d)',
    },
    E: {
      label: 'E',
      color: 'var(--rating-e)',
    },
  } satisfies ChartConfig;

  return (
    <ReportContent>
      <>
        <Text variant="reportBody">
          My Emissions uses an A - E grading to rate food and drinks items based
          on their carbon intensity, that amount of CO₂e emitted per kilogram of
          food.
        </Text>
        <Text variant="h4">Items by carbon rating</Text>
        <div className="flex items-center">
          <SimplePieChart
            dataKey="items"
            nameKey="category"
            chartConfig={chartConfig}
            chartData={chartData?.reverse()}
            innerRadius={60}
            centerLabel={{
              value: data.items_assessed!,
              label: 'Items assessed',
            }}
            className="size-72"
          />

          <div className="flex h-full flex-col gap-y-3">
            {(
              ['A', 'B', 'C', 'D', 'E'] as Array<keyof typeof RatingToTextEnum>
            ).map((rating, idx) => {
              return (
                <div key={idx} className="flex items-center gap-4">
                  <RatingCloud rating={rating as EmissionsCategoryEnum} />
                  <Text variant="reportBody">{RatingToTextEnum[rating]}</Text>
                </div>
              );
            })}
          </div>
        </div>
      </>
      <>
        <Text variant="h3">Items by highest total emissions</Text>
        <div className="space-y-3">
          <div className="grid grid-cols-11 gap-x-4">
            <Text className="col-span-3">Food or drink item</Text>
            <Text className="col-span-2 justify-self-center">Rating</Text>
            <Text className="col-span-2 text-right">
              Total purchased <Unit variant="tonnes" />
            </Text>
            <Text className="col-span-2 text-right">
              Total emissions <Unit variant="tCO2e" />
            </Text>
            <Text className="col-span-2 text-right">% of emissions</Text>
          </div>
          {data.highest_emissions_items?.map((item, idx) => {
            return (
              idx < 7 && (
                <HighestEmissionsItemsCard
                  key={idx}
                  item={item.name}
                  rating={item.rating as EmissionsCategoryEnum}
                  totalPurchased={item.weightKg}
                  totalEmissions={item.emissionsTonnes}
                  pctOfEmissions={item.pctOfCompanyEmissions}
                />
              )
            );
          })}
        </div>
      </>
    </ReportContent>
  );
}
