import Text from '@shared/components/content/text';
import {
  Sidebar,
  SidebarContent,
  SidebarGroup,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarMenuSub,
  SidebarMenuSubButton,
  SidebarMenuSubItem,
} from '@shared/components/ui/sidebar';
import { cn } from '@shared/lib/utils';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Link, scroller } from 'react-scroll';
import { useCCFReportResult } from '../ccf-report-result-context';

export type NavbarItem = {
  title: string;
  url: string;
  icon?: React.ReactNode;
  items?: NavbarItem[];
  onClick?: () => void;
  isActive?: boolean;
  show?: boolean;
};

interface CCFReportSidebarProps extends React.ComponentProps<typeof Sidebar> {
  data: NavbarItem[];
  activeItem?: string;
  scrollContainerId: string;
}

export function CCFReportSidebar({
  data,
  activeItem,
  scrollContainerId,
  ...props
}: CCFReportSidebarProps) {
  const { hash } = useLocation();
  const {
    result: {
      organization: { name },
      title,
    },
  } = useCCFReportResult();

  useEffect(() => {
    if (hash) {
      scroller.scrollTo(hash, {
        containerId: scrollContainerId,
        duration: 0,
        smooth: false,
      });
    }
  }, []);

  const renderScrollLink = (item: NavbarItem, isSection: boolean) => (
    <Link
      containerId={scrollContainerId}
      to={item.url}
      className={cn('cursor-pointer', { 'font-medium': isSection })}
      spy
      hashSpy
    >
      {item.title}
    </Link>
  );

  const [reportTitle, reportYear] = title.split(' - ');

  return (
    <Sidebar className="top-[--header-height] h-[--content-height]" {...props}>
      <SidebarHeader className="h-[--title-height] bg-teal-50 py-0">
        <div className="flex h-full flex-col justify-end p-4 pt-3">
          <Text
            variant="body"
            className="truncate font-medium text-primary-800"
          >
            {name}
          </Text>
          <Text variant="body" className="text-primary-600">
            {reportTitle}
          </Text>
          <Text variant="body" className="text-primary-600">
            {reportYear}
          </Text>
        </div>
      </SidebarHeader>
      <SidebarContent className="pl-3 pr-0 pt-7 font-light">
        <SidebarGroup>
          <SidebarMenu>
            {data.map((item) => {
              return (
                <SidebarMenuItem key={item.title}>
                  <SidebarMenuButton asChild isActive={item.url === activeItem}>
                    {renderScrollLink(item, true)}
                  </SidebarMenuButton>
                  {item.title === 'Appendix' &&
                    activeItem?.includes('appendix') &&
                    item.items?.length && (
                      <SidebarMenuSub className="border-l-0 px-0">
                        {item.items.map(
                          (item) =>
                            item.show && (
                              <SidebarMenuSubItem key={item.title}>
                                <SidebarMenuSubButton
                                  asChild
                                  isActive={item.url === activeItem}
                                  className="text-nowrap p-4 pl-2"
                                >
                                  {renderScrollLink(item, false)}
                                </SidebarMenuSubButton>
                              </SidebarMenuSubItem>
                            )
                        )}
                      </SidebarMenuSub>
                    )}
                  {item.title !== 'Appendix' &&
                    !activeItem?.includes('appendix') &&
                    item.items?.length && (
                      <SidebarMenuSub className="border-l-0 px-0">
                        {item.items.map(
                          (item) =>
                            item.show && (
                              <SidebarMenuSubItem key={item.title}>
                                <SidebarMenuSubButton
                                  asChild
                                  isActive={item.url === activeItem}
                                  className="text-nowrap p-4 pl-2"
                                >
                                  {renderScrollLink(item, false)}
                                </SidebarMenuSubButton>
                              </SidebarMenuSubItem>
                            )
                        )}
                      </SidebarMenuSub>
                    )}
                </SidebarMenuItem>
              );
            })}
          </SidebarMenu>
        </SidebarGroup>
      </SidebarContent>
    </Sidebar>
  );
}
