import Text from '@shared/components/content/text';
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@shared/components/ui/card';
import { Separator } from '@shared/components/ui/separator';
import { cn } from '@shared/lib/utils';

import ABWorldFoodsLogo from '@shared/assets/client-assets/client-logo-ABWF-stacked-logo.jpg';
import ConnectLogo95 from '@shared/assets/client-assets/client-logo-connect-logo-95w.webp';
import StAnneLogo from '@shared/assets/client-assets/client-logo-st-anne-logo.png';
import WahacaLogo from '@shared/assets/client-assets/client-logo-Wahaca-Logo.svg';

import CollectivFood from '@shared/assets/client-assets/clientLogo-collectiv-food.png';
import JETLogo from '@shared/assets/client-assets/clientLogo-just-eat-takeaway.png';
import TheGoodEatingCoLogo from '@shared/assets/client-assets/clientLogo-the-good-eating-co.png';
import SelfridgesLogo from '@shared/assets/client-assets/logo-selfridges-colour.png';
import UMassDiningLogo from '@shared/assets/client-assets/UMass-Dining_vert-cmyk.png';
import { Icons } from '@shared/components/content/icons';

interface SignupInfoProps extends React.HTMLAttributes<HTMLDivElement> {}

export default function SignupInfo({ className }: SignupInfoProps) {
  const clientLogos = [
    {
      src: JETLogo,
      alt: 'JET Logo',
    },
    {
      src: WahacaLogo,
      alt: 'Wahaca Logo',
    },
    {
      src: TheGoodEatingCoLogo,
      alt: 'The Good Eating Co Logo',
    },
    {
      src: ConnectLogo95,
      alt: 'Connect Catering Logo',
    },
    {
      src: StAnneLogo,
      alt: 'St Anne’s College Logo',
    },
    {
      src: UMassDiningLogo,
      alt: 'UMass Dining Logo',
    },
    {
      src: SelfridgesLogo,
      alt: 'Selfridges Logo',
    },
    {
      src: CollectivFood,
      alt: 'Collectiv Food Logo',
    },
    {
      src: ABWorldFoodsLogo,
      alt: 'AB World Foods Logo',
    },
  ];

  return (
    <Card className={cn('max-w-md p-4 py-6 md:max-w-lg', className)}>
      <CardHeader>
        <CardTitle className=" leading-7">
          Instantly Calculate Recipe Emissions
        </CardTitle>
      </CardHeader>
      <CardContent className="space-y-4">
        <Text>
          With our 7-day free plan, you get unlimited access to My Emissions,
          the easiest way to measure, understand, and reduce the carbon
          footprint of your recipes and products.
        </Text>
        <Text>Our free account includes:</Text>
        <div className="space-y-2">
          <div className="flex items-center space-x-3">
            <Icons.check className="text-secondary-600" />
            <Text>Instant carbon calculations.</Text>
          </div>
          <div className="flex items-center space-x-3">
            <Icons.check className="text-secondary-600" />
            <Text>Identify ways to reduce impact.</Text>
          </div>
          <div className="flex items-center space-x-3">
            <Icons.check className="text-secondary-600" />
            <Text>Generate carbon labels.</Text>
          </div>
          <div className="flex items-center space-x-3">
            <Icons.check className="text-secondary-600" />
            <Text>AI-powered recipe assessments.</Text>
          </div>
        </div>
      </CardContent>
      <Separator />
      <CardFooter className="mt-4">
        <div className="w-full space-y-4">
          <Text variant="subtle">
            Join leading food brands already using My Emissions to cut costs,
            reduce emissions, and lead the way in sustainability.
          </Text>
          <div className="mt-6 grid grid-cols-2 items-center justify-items-center gap-10 lg:grid-cols-3">
            {clientLogos.map((clientLogo, index) => (
              <img
                key={index}
                src={clientLogo.src}
                alt={clientLogo.alt}
                className={clientLogo.className!}
              />
            ))}
          </div>
        </div>
      </CardFooter>
    </Card>
  );
}
