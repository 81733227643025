import { CompanyReportResult } from '@shared/api/types';
import { useMemo } from 'react';
import { ReportSection } from './report-sections';

export const reportSlugs: Record<string, string> = {
  stationaryCombustion: 'stationary-combustion',
  mobileCombustion: 'mobile-combustion',
  directGasEmissions: 'direct-gas-emissions',
  electricity: 'electricity',
  heat: 'heat',
  purchasedGoodsAndServices: 'purchased-goods-and-services',
  foodAndDrink: 'food',
  fuelAndEnergy: 'fuel-and-energy-related-activities',
  scope1BySite: 'scope-1-by-site',
  scope2BySite: 'scope-2-by-site',
};

export const reportUrls = {
  introduction: '#introduction',
  calculatingEmissions: '#calculating-emissions',
  aboutYourReport: '#about-your-report',
  resultsOverview: '#results-overview',
  scope1: '#scope-1',
  scope1BySite: '#scope-1-by-site',
  stationaryCombustion: '#stationary-combustion',
  mobileCombustion: '#mobile-combustion',
  directGasEmissions: '#direct-gas-emissions',
  scope2: '#scope-2',
  scope2BySite: '#scope-2-by-site',
  electricity: '#electricity',
  scope3: '#scope-3',
  foodAndDrink: '#food-and-drink',
  foodAndDrinkItems: '#food-and-drink-items',
  fuelAndEnergy: '#fuel-and-energy',
  appendix: '#appendix',
  fullCalculation: '#full-calculation',
  methodologyOverview: '#appendix-methodology-overview',
  emissionsQuantification: '#appendix-emissions-quantification',
  scope1Coverage: '#appendix-scope-1-coverage',
  scope1Methodology: '#appendix-scope-1-methodology',
  scope2Coverage: '#appendix-scope-2-coverage',
  scope2Methodology: '#appendix-scope-2-methodology',
  electricityCalculation: '#appendix-electricity-calculation',
  scope3Coverage: '#appendix-scope-3-coverage',
  scope3Methodology: '#appendix-scope-3-methodology',
  outOfScopeEmissions: '#appendix-out-of-scope-emissions',
};

export function getCategoryStatus(result: CompanyReportResult) {
  const { scope1, scope2, scope3 } = result;

  const categories = scope1.categories.concat(
    scope2.categories,
    scope3.categories
  );

  const formattedCategories = categories.reduce(
    (acc, item) => {
      acc[item.slug] = {
        status: item.status,
        totalCategoryEmissionsTonnes: item.totalCategoryEmissionsTonnes,
      };

      if (item.subCategories) {
        item.subCategories.forEach((subItem) => {
          acc[subItem.slug] = {
            status: subItem.status,
            totalCategoryEmissionsTonnes:
              subItem.totalSubCategoryEmissionsTonnes,
          };
        });
      }

      return acc;
    },
    {} as Record<
      string,
      { status: string; totalCategoryEmissionsTonnes: number }
    >
  );

  const formattedCategoriesIncludingBySite = formattedCategories;

  formattedCategoriesIncludingBySite[reportSlugs.scope1BySite] = {
    status:
      scope1.emissionsBySite && scope1.emissionsBySite.length > 0
        ? 'included'
        : 'no_emissions',
    totalCategoryEmissionsTonnes: scope1.totalScopeEmissionsTonnes!,
  };
  formattedCategoriesIncludingBySite[reportSlugs.scope2BySite] = {
    status:
      scope2.emissionsBySite && scope2.emissionsBySite.length > 0
        ? 'included'
        : 'no_emissions',
    totalCategoryEmissionsTonnes: scope2.totalScopeEmissionsTonnes!,
  };

  return formattedCategoriesIncludingBySite;
}

export function useFormattedReportSections(
  reportSections: ReportSection[],
  result: CompanyReportResult
) {
  return useMemo(() => {
    if (!result) return [];
    const formattedCategoriesIncludingBySite = getCategoryStatus(result);

    return reportSections.reduce(
      (acc: ReportSection[], item: ReportSection) => {
        item.show && acc.push(item);

        if (item.items) {
          item.items.forEach((subItem: ReportSection) => {
            if (
              subItem.slug &&
              formattedCategoriesIncludingBySite[subItem.slug].status !==
                'included'
            ) {
              subItem.show = false;
            }
            item.show && acc.push(subItem);
          });
        }
        return acc;
      },
      []
    ) as ReportSection[];
  }, [reportSections, result]);
}
