import Text from '@shared/components/content/text';
import Loader from '@shared/components/loader';
import { cn } from '@shared/lib/utils';
import sentry from '@shared/services/sentry';
import { useCallback, useEffect, useRef } from 'react';
import generatePDF, { Resolution } from 'react-to-pdf';
import CCFReportIntro from '../ccf-report-intro';
import { useCCFReportResult } from './ccf-report-result-context';
import CcfReportLayoutTitle from './components/ccf-report-layout-title';
import { useReportSections } from './report-sections';
import { useFormattedReportSections } from './report-utils';

function PageLoader() {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = '';
    };
  }, []);

  return (
    <div
      className={cn(
        'fixed inset-0 flex items-center justify-center bg-primary-900 bg-opacity-50 z-50'
      )}
    >
      <div className="flex items-center space-x-3 rounded bg-gray-00 p-4 py-3">
        <Loader size="sm" color="primary" />
        <Text className="shrink-0" variant="subtle">
          Generating pdf
        </Text>
      </div>
    </div>
  );
}

export default function CCFReportDownload() {
  const { result, resultStatus } = useCCFReportResult();
  const contentRef = useRef<HTMLDivElement>(null);
  const reportSections = useReportSections(result?.reportUuid);

  const formattedData = useFormattedReportSections(reportSections, result);
  const generatePdf = useCallback(async (title: string) => {
    try {
      await generatePDF(contentRef, {
        filename: `${title}.pdf`,
        page: {
          orientation: 'l',
        },
        resolution: Resolution.NORMAL,
      });
    } catch (error) {
      sentry.log(error);
    } finally {
      window.close();
    }
  }, []);

  useEffect(() => {
    result &&
      setTimeout(() => {
        generatePdf(result.title);
      }, 2500);
  }, [generatePdf, result]);

  if (resultStatus === 'loading') {
    return <PageLoader />;
  }

  return (
    <div className="relative">
      <PageLoader />
      <main
        className={`
          w-[--page-width]
          [--content-height:calc(var(--page-height)-var(--title-height))]
          [--page-height:21cm] [--page-width:29.7cm] [--title-height:3cm]
        `}
        ref={contentRef}
      >
        <CCFReportIntro />
        {formattedData.map((item, idx) => {
          return (
            <div
              key={idx}
              className={cn(
                `w-full h-[--page-height] flex flex-col text-md font-light`
              )}
            >
              <CcfReportLayoutTitle
                title={item.title}
                preTitle={item.preTitle}
                link={item.link}
              />
              {item.component}
            </div>
          );
        })}
      </main>
    </div>
  );
}
