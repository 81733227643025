import EmptyPageContent from '@app/ccf/components/empty-page-content';
import { useGetCompanyReportsList } from '@shared/api';
import Text from '@shared/components/content/text';
import { DataTable } from '@shared/components/data-table/data-table';
import { useServerDataTable } from '@shared/components/data-table/hooks/use-server-data-table';
import { DataTableProps } from '@shared/components/data-table/types';
import Loader from '@shared/components/loader';
import { cn } from '@shared/lib/utils';
import { generatePath, useNavigate, useSearchParams } from 'react-router-dom';
import { ccfRoutes } from '../../../ccf-routes';
import { reportTableColumns } from '../../components/ccf-list-table-columns';
import { schema } from '../../components/scope-3/ccf-food-table';
import CCFReportCard from './ccf-report-card';

interface CCFReportTableProps extends Partial<DataTableProps> {
  type?: 'table' | 'card';
}

const CCFReportsTableSchema = schema.extend({});

export default function CCFReportTable({
  enableRowClick = true,
  type = 'table',
}: CCFReportTableProps) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const search = CCFReportsTableSchema.parse(Object.fromEntries(searchParams));

  const { data, status } = useGetCompanyReportsList({
    page: search.page,
    page_size: search.page_size,
  });

  const { results: companyReports, count } = data || {};
  const pageCount = count ? Math.ceil(count / (search.page_size || 10)) : 0;

  const { table } = useServerDataTable({
    data: companyReports || [],
    columns: reportTableColumns,
    pageCount,
    defaultPerPage: 10,
    columnVisibilityState: {
      uuid: false,
    },
    rowId: 'uuid',
  });

  if (status === 'loading') return <Loader />;
  if (status === 'error') throw Error();

  if (companyReports?.length === 0) {
    return (
      <EmptyPageContent
        title="Create your first company report"
        description={
          <div className="space-y-4">
            <Text className="text-center">
              A Company Carbon Footprint represents the total greenhouse gas
              emissions associated with your company&rsquo;s operations. This
              includes everything from energy and fuel use to supply chain
              activities and waste disposal, providing a full picture of your
              business&rsquo;s carbon impact.
            </Text>
            <Text className="text-center">
              You don&rsquo;t have any Company Carbon Footprint reports yet.
              Once you&rsquo;ve started your first report you&rsquo;ll see it
              listed here.
            </Text>
          </div>
        }
        primaryAction={() =>
          navigate(generatePath(ccfRoutes.REPORT_SETUP_WELCOME))
        }
        primaryActionText="Start your first report"
        supportArticle="whatIsCCF"
      />
    );
  }

  return (
    <DataTable
      table={table}
      showHeader={type === 'card' ? false : true}
      onRowClick={(row) => {
        if (!enableRowClick) return;
        const uuid = row?.original.uuid;
        navigate(generatePath(ccfRoutes.COMPANY_REPORT, { report_uuid: uuid }));
      }}
      rowClassName={() => cn('', { 'bg-gray-25 border-none': type === 'card' })}
      enablePagination
      customRowItem={
        type === 'card'
          ? (row) => (
              <CCFReportCard
                report={row.original}
                className="mb-3 cursor-pointer"
              />
            )
          : undefined
      }
    />
  );
}
