import { useAxiosConfig } from '@shared/api/AxiosConfigProvider';
import {
  getCompanyReportsRetrieveQueryKey,
  useCompanyReportsPublishCreate,
} from '@shared/api/lib/company-reports/company-reports';
import { useQueryClient } from '@tanstack/react-query';

export const usePublishReport = () => {
  const queryClient = useQueryClient();
  return useCompanyReportsPublishCreate({
    request: useAxiosConfig(),
    mutation: {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries([
          getCompanyReportsRetrieveQueryKey(variables.companyReportUuid),
        ]);
      },
    },
  });
};
