import { useAxiosConfig } from '@shared/api/AxiosConfigProvider';
import {
  useCompanyReportsResultsDownloadRetrieve,
  useCompanyReportsResultsRetrieve,
} from '@shared/api/lib/company-reports/company-reports';

export const useGetReportResults = (reportUuid: string) =>
  useCompanyReportsResultsRetrieve(reportUuid, {
    request: useAxiosConfig(),
    query: {
      enabled: !!reportUuid,
    },
  });

export const useDownloadReportResults = (
  reportUuid: string,
  { enabled = false }
) =>
  useCompanyReportsResultsDownloadRetrieve(reportUuid, {
    request: useAxiosConfig(),
    query: {
      enabled: enabled,
    },
  });
