import { ccfRoutes } from '@app/ccf/ccf-routes';
import EmptyPageContent from '@app/ccf/components/empty-page-content';
import { DataTable } from '@shared/components/data-table/data-table';

import { useCCF } from '@app/ccf/reports/ccf-context';
import { useGetCompanyReportPeriods } from '@shared/api';
import { useGetFoodImports } from '@shared/api/hooks/ccf/imports';
import Text from '@shared/components/content/text';
import { DataTableToolbar } from '@shared/components/data-table/data-table-toolbar';
import { useServerDataTable } from '@shared/components/data-table/hooks/use-server-data-table';
import { baseTableSchema } from '@shared/components/data-table/schema';
import Loader from '@shared/components/loader';
import { generatePath, useNavigate, useSearchParams } from 'react-router-dom';
import { foodImportTableColumns } from './food-import-columns';

const schema = baseTableSchema.extend({});

export default function CCFFoodImportTable() {
  const { uuid: reportUuid } = useCCF();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const search = schema.parse(Object.fromEntries(searchParams));

  const { data, status } = useGetFoodImports(reportUuid!, {
    page: search.page,
    page_size: search.page_size,
    polling: true,
  });

  const { data: periods, isLoading: isPeriodsLoading } =
    useGetCompanyReportPeriods(reportUuid!);

  const { results: foodImports, count } = data || {};
  const pageCount = count ? Math.ceil(count / (search.page_size || 10)) : 0;

  const { table } = useServerDataTable({
    data: foodImports || [],
    columns: foodImportTableColumns(periods!),
    pageCount,
    defaultPerPage: 10,
    columnVisibilityState: {
      uuid: false,
    },
    rowId: 'uuid',
  });

  if (status === 'loading' || isPeriodsLoading) return <Loader />;

  if (foodImports?.length === 0) {
    return (
      <EmptyPageContent
        description={
          <div className="mt-20 space-y-4">
            <Text className="text-center">
              You have no imports yet. Get started by uploading your first food
              and drink import.
            </Text>
          </div>
        }
        primaryAction={() =>
          navigate(
            generatePath(ccfRoutes.IMPORT_DOWNLOAD, { report_uuid: reportUuid })
          )
        }
        primaryActionText="Start import"
      />
    );
  }

  return (
    <DataTable table={table} className="bg-gray-00" enablePagination>
      <DataTableToolbar table={table} />
    </DataTable>
  );
}
