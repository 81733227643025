import { IntercomArticle } from '@app/services/intercom/content';
import { SupportButton } from '@shared/components/buttons/support-button';
import Scope3CategoryBadge from '@shared/components/content/scope-3-category-badge';
import ScopeBadge from '@shared/components/content/scope-badge';
import Text from '@shared/components/content/text';
import { Button, ButtonProps } from '@shared/components/ui/button';
import { ReactNode } from 'react';
import { ScopeCategorySlug, useScopeCategories } from '../scopes';

interface CCFCategoryIntroPageProps {
  categorySlug: ScopeCategorySlug;
  description: string | ReactNode;
  primaryActionProps?: ButtonProps;
  supportArticle?: IntercomArticle;
  primaryActionText?: string;
}

export default function CCFCategoryIntroPage({
  categorySlug,
  description,
  primaryActionProps,
  primaryActionText = 'Add data',
  supportArticle,
}: CCFCategoryIntroPageProps) {
  const scopeCategories = useScopeCategories();
  const category = scopeCategories?.find(
    (category) => category.slug === categorySlug
  );
  if (!category) {
    return null;
  }

  return (
    <div className=" max-w-content-center space-y-6">
      <div className="space-y-2">
        <div className="flex flex-row items-center space-x-2">
          {category.icon}
          <Text variant="section">{category.name}</Text>
        </div>
        <div className="flex flex-row space-x-2">
          <ScopeBadge scope={category.scope} />
          {category.scope3Category && (
            <Scope3CategoryBadge category={category.scope3Category} />
          )}
        </div>
      </div>
      {typeof description === 'string' ? (
        <Text>{description}</Text>
      ) : (
        <div>{description}</div>
      )}
      <div className="mx-auto flex w-full justify-between">
        {supportArticle && <SupportButton article={supportArticle} />}
        <Button className="bg-primary-700" {...primaryActionProps}>
          {primaryActionText}
        </Button>
      </div>
    </div>
  );
}
