import Text from '@shared/components/content/text';
import TextList from '@shared/components/content/text-list';
import ReportContent from '../../components/report-content';

export default function Appendix() {
  return (
    <ReportContent variant="appendix" size="md">
      <Text variant="reportBody">
        The remainder of this report is an appendix, which includes:
      </Text>
      <TextList className="space-y-4 text-md font-light">
        <li>
          An overview of our methodology and an explanation of how the emissions
          results are calculated.
        </li>
        <li>
          For each scope, an outline of what has been included and details of
          the calculation methodology, emission factors, and data quality.
        </li>
        <li>
          Additional information required by the GHG Protocol, including
          information on the approach to calculating electricity emissions and a
          total for out-of-scope emissions.
        </li>
      </TextList>
    </ReportContent>
  );
}
