import { SupportButton } from '@shared/components/buttons/support-button';
import { Button } from '@shared/components/ui/button';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { ccfRoutes } from '../../../ccf-routes';

export default function CCFFoodTableToolbar() {
  const navigate = useNavigate();
  const { report_uuid: reportUuid } = useParams();

  return (
    <div className="flex items-center gap-2">
      <Button
        size="sm"
        variant="ghost"
        onClick={() =>
          navigate(
            generatePath(ccfRoutes.FOOD_IMPORTS_LIST, {
              report_uuid: reportUuid,
            })
          )
        }
      >
        Manage imports
      </Button>
      <SupportButton article="food" size="sm" />
      <Button
        size="sm"
        onClick={() =>
          navigate(
            generatePath(ccfRoutes.IMPORT_DOWNLOAD, { report_uuid: reportUuid })
          )
        }
      >
        Add data
      </Button>
    </div>
  );
}
