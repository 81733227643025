import { ColumnSort, Table } from '@tanstack/react-table';

import { Button } from '@shared/components/ui/button';
import { Input } from '@shared/components/ui/input';
import { DataTableViewOptions } from './data-table-view-options';

import { SortOption } from '@app/components/tables/product-table/product-table';
import { cn } from '@shared/lib/utils';
import { useMemo } from 'react';
import { Icons } from '../content/icons';
import { DataTableBooleanFilter } from './data-table-boolean-filter';
import { DataTableFacetedFilter } from './data-table-faceted-filter';
import DataTableSortBySelect from './data-table-sortby-select';
import { DataTableFilterField } from './types';

interface DataTableToolbarProps<TData>
  extends React.HTMLAttributes<HTMLDivElement> {
  table: Table<TData>;
  enableViewSelector?: boolean;
  filterFields?: DataTableFilterField<TData>[];
  sortOptions?: SortOption[];
}

export function DataTableToolbar<TData>({
  table,
  enableViewSelector = false,
  filterFields = [],
  children,
  className,
  sortOptions = [],
  ...props
}: DataTableToolbarProps<TData>) {
  const isFiltered = table.getState().columnFilters.length > 0;

  // Memoize computation of searchableColumns and filterableColumns
  const { searchableColumns, filterableColumns, filterableBooleanColumns } =
    useMemo(() => {
      return {
        searchableColumns: filterFields.filter(
          (field) => !field.options && !field.isBoolean
        ),
        filterableColumns: filterFields.filter((field) => field.options),
        filterableBooleanColumns: filterFields.filter(
          (field) => !field.options && field.isBoolean
        ),
      };
    }, [filterFields]);

  return (
    <div
      className={cn(
        'flex w-full items-center justify-between space-x-2 overflow-auto py-1',
        className
      )}
      {...props}
    >
      <div className="flex flex-1 items-center space-x-2">
        {searchableColumns.length > 0 &&
          searchableColumns.map(
            (column) =>
              table.getColumn(column.value ? String(column.value) : '') && (
                <Input
                  key={String(column.value)}
                  placeholder={column.placeholder}
                  value={
                    (table
                      .getColumn(String(column.value))
                      ?.getFilterValue() as string) ?? ''
                  }
                  onChange={(event) =>
                    table
                      .getColumn(String(column.value))
                      ?.setFilterValue(event.target.value)
                  }
                  className="h-8 w-40 lg:w-64"
                />
              )
          )}
        {filterableColumns.length > 0 &&
          filterableColumns.map(
            (column) =>
              table.getColumn(column.value ? String(column.value) : '') && (
                <DataTableFacetedFilter
                  key={String(column.value)}
                  column={table.getColumn(
                    column.value ? String(column.value) : ''
                  )}
                  title={column.label}
                  options={column.options ?? []}
                />
              )
          )}
        {filterableBooleanColumns.length > 0 &&
          filterableBooleanColumns.map(
            (column) =>
              table.getColumn(column.value ? String(column.value) : '') && (
                <DataTableBooleanFilter
                  key={String(column.value)}
                  column={table.getColumn(
                    column.value ? String(column.value) : ''
                  )}
                  title={column.label}
                  table={table}
                />
              )
          )}
        {sortOptions.length > 0 && (
          <DataTableSortBySelect
            sortBy={table.getState().sorting[0] as ColumnSort}
            setSortBy={(sortBy) =>
              table.setSorting([{ id: sortBy.id, desc: sortBy.desc }])
            }
            sortOptions={sortOptions}
          />
        )}
        {isFiltered && (
          <Button
            aria-label="Reset filters"
            variant="ghost"
            className="h-8 px-2 lg:px-3"
            onClick={() => table.resetColumnFilters()}
          >
            Reset
            <Icons.x className="ml-2 size-4" aria-hidden="true" />
          </Button>
        )}
      </div>
      <div className="flex items-center gap-2">
        {children}
        {enableViewSelector && <DataTableViewOptions table={table} />}
      </div>
    </div>
  );
}
