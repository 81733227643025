import { roundNumberToNDecimalPlaces } from '@app/utils/helpers';
import { ReportingMethodEnum } from '@shared/api/types';
import { Icons } from '@shared/components/content/icons';
import Text from '@shared/components/content/text';
import TextList from '@shared/components/content/text-list';
import { cn } from '@shared/lib/utils';
import { useCCFReportResult } from '../../ccf-report-result-context';
import CCFReportMetric from '../../components/ccf-report-metric';
import ReportContent from '../../components/report-content';

const reportingMethodEnum = {
  location_based: 'location-based',
  market_based: 'market-based',
};

export function ElectricityCalculationAppendix() {
  const {
    result: { electricity },
  } = useCCFReportResult();
  if (!electricity) return null;

  const { reportingMethod, locationBased, marketBased } = electricity;

  const isLocationBased =
    reportingMethod === ReportingMethodEnum.location_based;

  return (
    <ReportContent
      variant={isLocationBased ? 'appendix' : 'column'}
      size={isLocationBased ? 'md' : undefined}
    >
      <>
        <Text variant="reportBody">
          There are two possible methods for calculating Scope 2 emissions:
        </Text>
        <TextList className="text-md font-light">
          <li>
            The <span className="font-semibold">location-based</span> approach
            calculates Scope 2 emissions based on the average emissions
            intensity of the local grid, reflecting the region’s energy mix.
          </li>
          <li>
            The <span className="font-semibold">market-based</span> approach
            calculates emissions based on the specific electricity purchased by
            the company, such as from renewable energy sources. This approach
            gives more control over emissions, as the company can select
            lower-emission electricity providers.
          </li>
        </TextList>
        <Text variant="reportBody">
          While entering their electricity data, users have the option to
          incorporate supplier-specific electricity values for each site of the
          reporting company. This approach is recommended by My Emissions to
          ensure alignment with the GHG Protocol Scope 2 guidelines.
        </Text>
        {isLocationBased && (
          <Text variant="reportBody">
            Your report has been calculated using the{' '}
            {reportingMethodEnum[reportingMethod]} approach, as no custom
            electricity supply data was added.
          </Text>
        )}
      </>
      {!isLocationBased && (
        <>
          <Text variant="h3">Location-based comparison</Text>
          <Text variant="reportBody">
            Your report has been calculated using the{' '}
            <span className="font-medium">market-based</span> approach. Below,
            we compare your Scope 2 and total company emissions, calculated
            using the <span className="font-medium">location-based</span>{' '}
            approach and highlight the difference in your results.
          </Text>
          <div className="grid grid-cols-12 gap-y-6">
            <div className="col-span-7 space-y-4">
              <Text variant="h4">Location-based</Text>
              <div className="space-y-4">
                <div className="flex space-x-2">
                  <CCFReportMetric
                    title="Scope 2 emissions"
                    value={roundNumberToNDecimalPlaces(
                      electricity.locationBased
                        ?.totalScope2EmissionsTonnes as number,
                      2
                    )}
                    unit="tCO2e"
                    size="md"
                  />
                  {locationBased!.pctDiffScope2Emissions !== 0 && (
                    <div
                      className={cn(
                        'mt-auto flex items-center text-secondary',
                        {
                          'text-orange-600':
                            locationBased!.pctDiffScope2Emissions > 0,
                        }
                      )}
                    >
                      {locationBased!.pctDiffScope2Emissions > 0 ? (
                        <Icons.arrowUp className="flex items-baseline" />
                      ) : (
                        <Icons.arrowDown className="flex items-baseline" />
                      )}
                      <Text className="text-xl ">
                        {roundNumberToNDecimalPlaces(
                          electricity?.locationBased
                            ?.pctDiffScope2Emissions as number,
                          2,
                          true
                        )}
                        %
                      </Text>
                    </div>
                  )}
                </div>
                <div className="flex space-x-2">
                  <CCFReportMetric
                    title="Total emissions"
                    value={roundNumberToNDecimalPlaces(
                      electricity?.locationBased
                        ?.totalCompanyEmissionsTonnes as number,
                      2
                    )}
                    unit="tCO2e"
                    size="md"
                  />
                  <div
                    className={cn('mt-auto flex items-center text-secondary', {
                      'text-orange-600':
                        locationBased &&
                        locationBased.pctDiffCompanyEmissions > 0,
                    })}
                  >
                    {locationBased &&
                    locationBased.pctDiffCompanyEmissions > 0 ? (
                      <Icons.arrowUp className="flex items-baseline" />
                    ) : (
                      <Icons.arrowDown className="flex items-baseline" />
                    )}
                    <Text className="text-xl">
                      {roundNumberToNDecimalPlaces(
                        electricity?.locationBased
                          ?.pctDiffCompanyEmissions as number,
                        2,
                        true
                      )}
                      %
                    </Text>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-4 space-y-4">
              <Text variant="h4">Market-based</Text>
              <div className="space-y-4">
                <CCFReportMetric
                  title="Scope 2 emissions"
                  value={roundNumberToNDecimalPlaces(
                    marketBased?.totalScope2EmissionsTonnes as number,
                    2
                  )}
                  unit="tCO2e"
                  size="md"
                />

                <CCFReportMetric
                  title="Total emissions"
                  value={roundNumberToNDecimalPlaces(
                    marketBased?.totalCompanyEmissionsTonnes as number,
                    2,
                    true
                  )}
                  unit="tCO2e"
                  size="md"
                />
              </div>
            </div>
          </div>
        </>
      )}
    </ReportContent>
  );
}
