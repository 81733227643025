import { ccfRoutes } from '@app/ccf/ccf-routes';
import { generatePath } from 'react-router-dom';
import { scrollTo } from '../utils';
import Appendix from './pages/appendix/appendix';
import { ElectricityCalculationAppendix } from './pages/appendix/electricity-calculation-appendix';
import { EmissionsQuantification } from './pages/appendix/emissions-quantification';
import MethodologyOverview from './pages/appendix/methodology-overview';
import OutOfScopeEmissionsAppendix from './pages/appendix/out-of-scope-emissions-appendix';
import Scope1CoverageAppendix from './pages/appendix/scope-1-coverage-appendix';
import { Scope1MethodologyAppendix } from './pages/appendix/scope-1-methodology-appendix';
import Scope2CoverageAppendix from './pages/appendix/scope-2-coverage-appendix';
import { Scope2MethodologyAppendix } from './pages/appendix/scope-2-methodology';
import Scope3CoverageAppendix from './pages/appendix/scope-3-coverage-appendix';
import Scope3MethodologyAppendix from './pages/appendix/scope-3-methodology-appendix';
import YourFullCalculation from './pages/appendix/your-full-calculation';
import AboutYourReport from './pages/introduction/about-your-report';
import CalculatingEmissions from './pages/introduction/calculating-emissions';
import Introduction from './pages/introduction/introduction';
import ResultsSummary from './pages/introduction/results-summary';
import DirectGasEmissions from './pages/scope1/direct-gas-emissions';
import MobileCombustion from './pages/scope1/mobile-combustion';
import Scope1 from './pages/scope1/scope-1';
import Scope1BySite from './pages/scope1/scope-1-by-site';
import StationaryCombustion from './pages/scope1/stationary-combustion';
import Electricity from './pages/scope2/electricity';
import Scope2 from './pages/scope2/scope-2';
import Scope2BySite from './pages/scope2/scope-2-by-site';
import FoodAndDrink from './pages/scope3/food-and-drink';
import FoodAndDrinkItems from './pages/scope3/food-and-drink-items';
import FuelAndEnergy from './pages/scope3/fuel-and-energy';
import Scope3 from './pages/scope3/scope-3';
import { reportSlugs, reportUrls } from './report-utils';

export type ReportSection = {
  title: string;
  url: string;
  slug?: string;
  preTitle: string;
  onClick: () => void;
  component?: JSX.Element;
  items?: ReportSection[];
  show: boolean;
  link?: {
    title: string;
    scrollTo?: string;
    linkTo?: string;
  };
};

export function useReportSections(reportUuid?: string): ReportSection[] {
  if (!reportUuid) return [];
  return [
    {
      title: 'Introduction',
      url: reportUrls.introduction,
      preTitle: '',
      onClick: () => scrollTo(reportUrls.introduction),
      component: <Introduction />,
      show: true,
      items: [
        {
          title: 'Calculating emissions',
          preTitle: 'Introduction',
          component: <CalculatingEmissions />,
          url: reportUrls.calculatingEmissions,
          onClick: () => scrollTo(reportUrls.calculatingEmissions),
          show: true,
        },
        {
          title: 'About your report',
          preTitle: 'Introduction',
          url: reportUrls.aboutYourReport,
          onClick: () => scrollTo(reportUrls.aboutYourReport),
          show: true,
          component: <AboutYourReport />,
          link: {
            title: 'View methodology appendix',
            scrollTo: reportUrls.methodologyOverview,
          },
        },
      ],
    },
    {
      title: 'Results overview',
      url: reportUrls.resultsOverview,
      preTitle: '',
      onClick: () => scrollTo(reportUrls.resultsOverview),
      component: <ResultsSummary />,
      show: true,
      items: [
        {
          title: 'Full calculation',
          url: reportUrls.fullCalculation,
          onClick: () => scrollTo(reportUrls.fullCalculation),
          preTitle: 'Results overview',
          component: <YourFullCalculation />,
          show: true,
        },
      ],
    },
    {
      title: 'Scope 1',
      url: reportUrls.scope1,
      onClick: () => scrollTo(reportUrls.scope1),
      component: <Scope1 />,
      preTitle: '',
      show: true,
      items: [
        {
          title: 'Scope 1 by site',
          preTitle: 'Scope 1',
          slug: reportSlugs.scope1BySite,
          component: <Scope1BySite />,
          url: reportUrls.scope1BySite,
          onClick: () => scrollTo(reportUrls.scope1BySite),
          show: true,
        },
        {
          title: 'Stationary combustion',
          preTitle: 'Scope 1',
          slug: reportSlugs.stationaryCombustion,
          component: <StationaryCombustion />,
          show: true,
          url: reportUrls.stationaryCombustion,
          onClick: () => scrollTo(reportUrls.stationaryCombustion),
          link: {
            title: 'View input data',
            scrollTo: reportUrls.scope1Coverage,
            linkTo: generatePath(ccfRoutes.STATIONARY_COMBUSTION, {
              report_uuid: reportUuid,
            }),
          },
        },
        {
          title: 'Mobile combustion',
          url: reportUrls.mobileCombustion,
          slug: reportSlugs.mobileCombustion,
          onClick: () => scrollTo(reportUrls.mobileCombustion),
          component: <MobileCombustion />,
          preTitle: 'Scope 1',
          show: true,
          link: {
            title: 'View scope 1 appendix',
            linkTo: generatePath(ccfRoutes.MOBILE_COMBUSTION, {
              report_uuid: reportUuid,
            }),
          },
        },
        {
          title: 'Direct gas emissions',
          url: reportUrls.directGasEmissions,
          slug: reportSlugs.directGasEmissions,
          onClick: () => scrollTo(reportUrls.directGasEmissions),
          preTitle: 'Scope 1',
          show: true,
          component: <DirectGasEmissions />,
          link: {
            title: 'View input data',
            scrollTo: reportUrls.scope1Coverage,
            linkTo: generatePath(ccfRoutes.OTHER_GAS, {
              report_uuid: reportUuid,
            }),
          },
        },
      ],
    },
    {
      title: 'Scope 2',
      url: reportUrls.scope2,
      onClick: () => scrollTo(reportUrls.scope2),
      preTitle: '',
      component: <Scope2 />,
      link: {
        title: 'View scope 2 appendix',
        scrollTo: reportUrls.scope2Coverage,
      },
      show: true,
      items: [
        {
          title: 'Scope 2 by site',
          url: reportUrls.scope2BySite,
          slug: reportSlugs.scope2BySite,
          onClick: () => scrollTo(reportUrls.scope2BySite),
          preTitle: 'Scope 2',
          component: <Scope2BySite />,
          show: true,
        },
        {
          title: 'Electricity',
          url: reportUrls.electricity,
          slug: reportSlugs.electricity,
          onClick: () => scrollTo(reportUrls.electricity),
          preTitle: 'Scope 2',
          component: <Electricity />,
          link: {
            title: 'View input data',
            scrollTo: reportUrls.scope1Coverage,
            linkTo: generatePath(ccfRoutes.ELECTRICITY, {
              report_uuid: reportUuid,
            }),
          },
          show: true,
        },
      ],
    },
    {
      title: 'Scope 3',
      url: reportUrls.scope3,
      onClick: () => scrollTo(reportUrls.scope3),
      preTitle: '',
      component: <Scope3 />,
      link: {
        title: 'View scope 3 appendix',
        scrollTo: reportUrls.scope3Coverage,
      },
      show: true,
      items: [
        {
          title: 'Food and drink',
          url: reportUrls.foodAndDrink,
          slug: reportSlugs.foodAndDrink,
          onClick: () => scrollTo(reportUrls.foodAndDrink),
          preTitle: 'Scope 3: Purchased goods and services',
          component: <FoodAndDrink />,
          link: {
            title: 'View input data',
            scrollTo: reportUrls.scope1Coverage,
            linkTo: generatePath(ccfRoutes.FOOD, {
              report_uuid: reportUuid,
            }),
          },
          show: true,
        },
        {
          title: 'Food and drink: items',
          url: reportUrls.foodAndDrinkItems,
          slug: reportSlugs.foodAndDrink,
          onClick: () => scrollTo(reportUrls.foodAndDrinkItems),
          preTitle: 'Scope 3: Purchased goods and services',
          component: <FoodAndDrinkItems />,
          show: true,
          link: {
            title: 'View input data',
            scrollTo: reportUrls.scope1Coverage,
            linkTo: generatePath(ccfRoutes.FOOD, {
              report_uuid: reportUuid,
            }),
          },
        },
        {
          title: 'Fuel and energy',
          url: reportUrls.fuelAndEnergy,
          slug: reportSlugs.fuelAndEnergy,
          onClick: () => scrollTo(reportUrls.fuelAndEnergy),
          preTitle: 'Scope 3',
          component: <FuelAndEnergy />,
          show: true,
          link: {
            title: 'View scope 3 appendix',
            scrollTo: reportUrls.scope3Coverage,
          },
        },
      ],
    },
    {
      title: 'Appendix',
      url: reportUrls.appendix,
      onClick: () => scrollTo(reportUrls.appendix),
      preTitle: '',
      component: <Appendix />,
      show: true,
      items: [
        {
          title: 'Methodology overview',
          url: reportUrls.methodologyOverview,
          onClick: () => scrollTo(reportUrls.methodologyOverview),
          preTitle: 'Appendix',
          component: <MethodologyOverview />,
          show: true,
        },
        {
          title: 'Emissions quantification',
          url: reportUrls.emissionsQuantification,
          onClick: () => scrollTo(reportUrls.emissionsQuantification),
          preTitle: 'Appendix',
          component: <EmissionsQuantification />,
          show: true,
        },
        {
          title: 'Scope 1 coverage',
          url: reportUrls.scope1Coverage,
          onClick: () => scrollTo(reportUrls.scope1Coverage),
          preTitle: 'Appendix',
          component: <Scope1CoverageAppendix />,
          show: true,
        },
        {
          title: 'Scope 1 methodology',
          url: reportUrls.scope1Methodology,
          onClick: () => scrollTo(reportUrls.scope1Methodology),
          preTitle: 'Appendix',
          component: <Scope1MethodologyAppendix />,
          show: true,
        },
        {
          title: 'Scope 2 coverage',
          url: reportUrls.scope2Coverage,
          onClick: () => scrollTo(reportUrls.scope2Coverage),
          preTitle: 'Appendix',
          component: <Scope2CoverageAppendix />,
          show: true,
        },
        {
          title: 'Scope 2 methodology',
          onClick: () => scrollTo(reportUrls.scope2Methodology),
          url: reportUrls.scope2Methodology,
          preTitle: 'Appendix',
          component: <Scope2MethodologyAppendix />,
          show: true,
        },
        {
          title: 'Electricity calculation',
          url: reportUrls.electricityCalculation,
          onClick: () => scrollTo(reportUrls.electricityCalculation),

          preTitle: 'Appendix',
          component: <ElectricityCalculationAppendix />,
          show: true,
        },
        {
          title: 'Scope 3 coverage',
          url: reportUrls.scope3Coverage,
          onClick: () => scrollTo(reportUrls.scope3Coverage),
          preTitle: 'Appendix',
          component: <Scope3CoverageAppendix />,
          show: true,
        },
        {
          title: 'Scope 3 methodology',
          url: reportUrls.scope3Methodology,
          onClick: () => scrollTo(reportUrls.scope3Methodology),
          preTitle: 'Appendix',
          component: <Scope3MethodologyAppendix />,
          show: true,
        },
        {
          title: 'Out-of-scope emissions',
          url: reportUrls.outOfScopeEmissions,
          onClick: () => scrollTo(reportUrls.outOfScopeEmissions),
          preTitle: 'Appendix',
          component: <OutOfScopeEmissionsAppendix />,
          show: true,
        },
      ],
    },
  ];
}
