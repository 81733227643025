import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@shared/components/ui/dialog';
import { cn } from '@shared/lib/utils';
import { ClassValue } from 'clsx';
import { useCallback, useState } from 'react';

const useSimpleDialog = (onClose?: () => void) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const showSimpleDialog = useCallback(() => {
    setIsDialogOpen(true);
  }, []);

  const closeSimpleDialog = useCallback(() => {
    setIsDialogOpen(false);
    if (onClose) onClose();
  }, [onClose]);

  const handleOpenChange = useCallback(
    (open: boolean) => {
      setIsDialogOpen(open);
      if (!open && onClose) onClose();
    },
    [onClose, setIsDialogOpen]
  );

  const SimpleDialogComponent = (params: SimpleDialogProps) => (
    <SimpleDialog open={isDialogOpen} setOpen={handleOpenChange} {...params} />
  );

  return {
    showSimpleDialog,
    closeSimpleDialog,
    SimpleDialogComponent,
  };
};

interface SimpleDialogProps {
  trigger?: React.ReactElement;
  header?: string;
  description?: React.ReactElement;
  content: React.ReactElement;
  footer?: React.ReactElement;
  contentClassName?: ClassValue;
  titleClassName?: ClassValue;
  open?: boolean;
  onClose?: () => void;
  setOpen?: (open: boolean) => void;
}

export function SimpleDialog({
  header,
  description,
  content,
  trigger,
  footer,
  contentClassName,
  titleClassName,
  open,
  setOpen,
}: SimpleDialogProps) {
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>{trigger}</DialogTrigger>
      <DialogContent
        className={cn(
          'max-w-2xl overflow-auto min-w-[400px] max-h-[80vh]',
          contentClassName
        )}
        onInteractOutside={(e) => e.preventDefault()}
      >
        <DialogHeader className="space-y-4">
          <DialogTitle className={cn('', titleClassName)}>{header}</DialogTitle>
        </DialogHeader>
        <DialogDescription>{description}</DialogDescription>
        {content}
        <DialogFooter>{footer}</DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

export default useSimpleDialog;
