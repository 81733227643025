import Text from '@shared/components/content/text';
import TextList from '@shared/components/content/text-list';
import { useCCFReportResult } from '../../ccf-report-result-context';
import CCFReportMetric from '../../components/ccf-report-metric';
import ReportContent from '../../components/report-content';

export default function OutOfScopeEmissionsAppendix() {
  const {
    result: { outOfScope },
  } = useCCFReportResult();

  const outOfScopeEmissions = outOfScope.totalEmissionsTonnes;

  return (
    <ReportContent variant="appendix">
      <div className="max-w-3xl space-y-7">
        <Text variant="reportBody" className="mb-8">
          Out-of-scope emissions refer to greenhouse gas emissions that fall
          outside the direct scope of your Scope 1, 2, or 3 emissions as defined
          by the GHG Protocol. These do not contribute to your total company
          emissions.
        </Text>
        <CCFReportMetric
          title="Out-of-scope emissions"
          value={outOfScopeEmissions}
          unit="tCO2e"
          size="lg"
        />
        <div className="space-y-4">
          <Text variant="h4">How are out-of-scope emissions generated?</Text>
          <TextList className="space-y-2 text-md">
            <li>
              <Text variant="reportBody">
                <span className="font-medium">Biogenic CO₂ Emissions:</span> CO₂
                emissions from the combustion of biomass and biofuels
              </Text>
            </li>
          </TextList>
        </div>
      </div>
    </ReportContent>
  );
}
