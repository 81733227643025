import { ccfRoutes } from '@app/ccf/ccf-routes';
import { CompanyReport } from '@shared/api/types';
import { Icons } from '@shared/components/content/icons';
import Text from '@shared/components/content/text';
import Unit from '@shared/components/content/unit';
import { Button } from '@shared/components/ui/button';
import { Card, CardContent, CardFooter } from '@shared/components/ui/card';
import { cn, formatDate, roundNumberToNDecimalPlaces } from '@shared/lib/utils';
import React from 'react';
import { generatePath, useNavigate } from 'react-router-dom';

interface CCFReportCardProps extends React.HTMLAttributes<HTMLDivElement> {
  report: CompanyReport;
}

export default function CCFReportCard({
  report,
  className,
  onClick,
}: CCFReportCardProps) {
  const published = report.status === 'published' || report.status === 'edited';
  const navigate = useNavigate();

  const reportInfoContent: ReportInfoProps[] = [
    {
      label: 'Created',
      value: formatDate(report.created, 'do MMMM yyyy'),
    },

    ...(report.lastPublishedAt
      ? [
          {
            label: 'Last Published',
            value: formatDate(report.lastPublishedAt, 'do MMMM yyyy'),
          },
        ]
      : []),
  ];

  return (
    <div className={cn('p-0.5', className)}>
      <Card
        className={cn(
          'min-w-96 mr-0 border-primary-50 transition-all cursor-default',
          {
            'hover:border-primary-200 hover:shadow-sm cursor-pointer': onClick,
          }
        )}
      >
        <CardContent className="flex flex-row items-center justify-between px-3 pb-2 pt-4">
          <div>
            <Text variant="cardTitle" className="ml-2">
              {report.title}
            </Text>
            <Text variant="subtle" className="ml-2 mt-1">
              {formatDate(report.startDate, 'do MMMM yyyy')} -{' '}
              {formatDate(report.endDate, 'do MMMM yyyy')}
            </Text>
          </div>
          {published && (
            <div className="flex items-baseline space-x-2">
              <Text className="text-3xl font-medium text-primary">
                {roundNumberToNDecimalPlaces(
                  report.totalEmissionsTonnes!,
                  2,
                  true
                )}
              </Text>
              <Unit variant="tCO2e" className="text-xl" />
            </div>
          )}
        </CardContent>
        <CardContent className="flex items-center justify-between space-x-2 px-3 pt-2 ">
          <div className="flex w-full max-w-xl flex-row space-x-4">
            {reportInfoContent.map((info) => (
              <ReportInfo key={info.label} {...info} />
            ))}
          </div>

          <div className="flex space-x-2">
            {report.status === 'published' && (
              <Button
                variant="outline"
                onClick={() =>
                  navigate(
                    generatePath(ccfRoutes.COMPANY_REPORT, {
                      report_uuid: report.uuid,
                    })
                  )
                }
              >
                Edit
              </Button>
            )}

            {published && (
              <Button
                className="bg-teal"
                onClick={() =>
                  navigate(
                    generatePath(ccfRoutes.REPORT_RESULT, {
                      report_uuid: report.uuid,
                    })
                  )
                }
              >
                View report
              </Button>
            )}
          </div>
        </CardContent>
        <CardFooter className="p-0">
          {['edited', 'Unpublished', 'editing'].includes(report.status!) && (
            <ReportCardFooter report={report} />
          )}
        </CardFooter>
      </Card>
    </div>
  );
}

interface ReportInfoProps {
  label: string;
  value: string | number;
  alignRight?: boolean;
  unit?: () => JSX.Element;
}
const ReportInfo = ({ label, value, alignRight, unit }: ReportInfoProps) => (
  <div className="ml-2 min-w-20 ">
    <Text variant="subtle">{label}</Text>
    <div className={cn('flex items-end', { 'justify-end': alignRight })}>
      <Text className="mr-1 text-sm">{value}</Text>
      {unit && unit()}
    </div>
  </div>
);

const ReportCardFooter = ({ report }: { report: CompanyReport }) => {
  const navigate = useNavigate();
  return (
    <div
      className={cn(
        'size-full rounded-b-lg px-4 z-10 bg-primary-50 border-t border-primary-100'
      )}
    >
      {report.status === 'Unpublished' && (
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-2">
            <Icons.edit className="size-4" />
            <Text className=" text-sm font-medium text-primary-900/80">
              Report in progress
            </Text>
          </div>
          <Button
            variant="link"
            onClick={() =>
              navigate(
                generatePath(ccfRoutes.COMPANY_REPORT, {
                  report_uuid: report.uuid,
                })
              )
            }
          >
            Continue editing
          </Button>
        </div>
      )}
      {report.status === 'edited' && (
        <div className="flex flex-row items-center justify-between">
          <div className="flex flex-row items-center space-x-2">
            <Icons.edit className="size-4" />
            <Text className=" text-sm font-medium text-primary-900/80">
              Editing in progress
            </Text>
          </div>
          <Button
            variant="link"
            onClick={() =>
              navigate(
                generatePath(ccfRoutes.COMPANY_REPORT, {
                  report_uuid: report.uuid,
                })
              )
            }
          >
            Continue editing
          </Button>
        </div>
      )}
    </div>
  );
};
