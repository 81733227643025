/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * My Emissions API
 * We calculate food carbon footprints
 * OpenAPI spec version: 1.0.0 (v1)
 */

/**
 * * `started` - Started
* `unstarted` - Unstarted
* `completed` - Completed
 */
export type CompanyReportSummaryStatusEnum = typeof CompanyReportSummaryStatusEnum[keyof typeof CompanyReportSummaryStatusEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyReportSummaryStatusEnum = {
  started: 'started',
  unstarted: 'unstarted',
  completed: 'completed',
} as const;
