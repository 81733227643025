import { ccfRoutes } from '@app/ccf/ccf-routes';
import { useFoodImport } from '@app/ccf/food-import/food-import-layout';
import useSimpleDialog from '@app/hooks/use-simple-dialog';
import { useUploadImport } from '@shared/api/hooks/ccf/imports';
import { ImportRowRetrieve } from '@shared/api/types';
import { DataTable } from '@shared/components/data-table/data-table';
import { DataTableToolbar } from '@shared/components/data-table/data-table-toolbar';
import { useDataTable } from '@shared/components/data-table/hooks/use-data-table';
import { DataTableFilterField } from '@shared/components/data-table/types';
import { useToast } from '@shared/components/ui/use-toast';
import sentry from '@shared/services/sentry';
import { Row } from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { foodImportReviewColumns } from './food-import-review-columns';
import FoodImportReviewEditForm from './food-import-review-edit-form';
import { FoodImportReviewTableToolbarActions } from './food-import-review-table-toolbar';

interface CleanedDateTableProps {
  cleanedData: ImportRowRetrieve[];
  importUuid: string;
  showHelp: () => void;
}

export type FoodImportReviewRow = ImportRowRetrieve & {
  missingWeight: boolean;
};

export const FoodImportReviewTable = ({
  cleanedData,
  importUuid,
  showHelp,
}: CleanedDateTableProps) => {
  const { toast } = useToast();
  const [data, setData] = useState<FoodImportReviewRow[]>([]);
  const [selectedRow, setSelectedRow] = useState<
    Row<FoodImportReviewRow> | undefined
  >();

  const { mutateAsync: uploadImport, isLoading: uploadingImport } =
    useUploadImport();

  const { reportUuid, setImportUuid, setNumberOfActivities } = useFoodImport();
  const navigate = useNavigate();
  const { showSimpleDialog, SimpleDialogComponent, closeSimpleDialog } =
    useSimpleDialog();

  const startImport = async () => {
    const importData = {
      companyReportUuid: reportUuid,
      importUuid,
      data: {
        rows: data.map((row) => ({
          ...row,
        })),
      },
    };

    try {
      await uploadImport(importData);
      setNumberOfActivities(data.length);
      setImportUuid(importUuid);
      navigate(
        generatePath(ccfRoutes.IMPORTING_FOOD, { report_uuid: reportUuid })
      );
    } catch (error) {
      sentry.log(error);
      toast({
        title: 'Import failed',
        description: 'Please check the data for any errors and try again',
        variant: 'destructive',
      });
    }
  };

  const filterFields: DataTableFilterField<ImportRowRetrieve>[] = [
    {
      label: 'Name',
      value: 'name',
      placeholder: 'Search name...',
    },
    {
      label: 'Show only rows with errors',
      value: 'missingWeight',
      isBoolean: true,
    },
  ];

  useEffect(() => {
    const processedData: FoodImportReviewRow[] = cleanedData.map((row) => {
      const { inputMode } = row;

      if (inputMode === 'packs') {
        const { quantity, itemMassOrVolume, itemsPerPack } = row;
        return {
          ...row,
          missingWeight: !itemMassOrVolume || !itemsPerPack || !quantity,
        };
      }

      const { inputTotalWeight, totalMassOrVolume } = row;
      return {
        ...row,
        quantity: Number(inputTotalWeight),
        missingWeight: !totalMassOrVolume,
      };
    });

    setData(processedData);
  }, [cleanedData]);

  const { table } = useDataTable({
    data,
    setData,
    columns: foodImportReviewColumns(),
    columnVisibilityState: {
      id: false,
      supplier: false,
      remoteId: false,
      actions: false,
      itemsPerPack: false,
      itemUnitWeight: false,
      itemUnit: false,
      isEstimated: false,
      missingWeight: false,
      inputMode: false,
    },
    resetPageIndexOnUpdate: false,
  });

  return (
    <>
      <DataTable
        table={table}
        enablePagination
        className="bg-gray-00 text-left shadow-lg"
        isLoading={uploadingImport}
        onRowClick={(row) => {
          setSelectedRow(row);
          showSimpleDialog();
        }}
        rowClassName={(row) => {
          return {
            'hover:bg-gray-25': true,
            'bg-warning-50 hover:bg-warning-100':
              !row.original.missingWeight && row.original.isEstimated,
            'bg-error-50 hover:bg-error-100': row.original.missingWeight,
          };
        }}
        columnClassName={{
          inputUnit: 'border-r',
          uploadedData: 'border-r',
        }}
      >
        <DataTableToolbar table={table} filterFields={filterFields}>
          <FoodImportReviewTableToolbarActions
            table={table}
            handleImport={startImport}
            loading={uploadingImport}
            showHelp={showHelp}
          />
        </DataTableToolbar>
      </DataTable>
      {SimpleDialogComponent({
        header: 'Edit import row',
        contentClassName: 'max-w-3xl',
        content: (
          <FoodImportReviewEditForm
            table={table}
            closeDialog={closeSimpleDialog}
            data={data}
            row={selectedRow}
            mode={selectedRow?.original.inputMode || 'total-weight'}
          />
        ),
      })}
    </>
  );
};
