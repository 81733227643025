import { CompanyReportSummary } from '@shared/api/types';
import { Icons } from '@shared/components/content/icons';
import Text from '@shared/components/content/text';
import SelectFormField from '@shared/components/form/select-form-field';
import { Card, CardContent, CardHeader } from '@shared/components/ui/card';
import { reasonOptions } from '../report-publish-layout';

export function ScopeReviewRow({
  icon,
  name,
  description,
  slug,
  status,
  statusDescription,
  isSupported,
}: {
  icon: React.ReactNode;
  name: string;
  description: string;
  slug: CompanyReportSummary['slug'];
  status: string;
  statusDescription: string;
  isSupported?: boolean;
}) {
  const options = isSupported
    ? reasonOptions.filter((option) => option.value !== 'not_supported')
    : reasonOptions;

  const categoryOmitted = status === 'unstarted' || !isSupported;

  return (
    <Card>
      <CardHeader className="flex flex-row items-center justify-between space-y-0 px-4 py-3">
        <div>{icon}</div>
        <span className="ml-2 text-md">{name}</span>
        <div className="ml-auto flex items-baseline">
          <span className="flex items-center">
            {status === 'unstarted' && (
              <>
                <Icons.minus className="mr-1 text-gray-400" size={14} />
                <Text variant="subtle">No data added</Text>
              </>
            )}
            {status === 'started' && (
              <>
                <Icons.loader2 className="mr-1 text-orange-300" size={14} />
                <Text variant="subtle">{statusDescription}</Text>
              </>
            )}
            {status === 'completed' && (
              <>
                <Icons.check className="mr-1 text-secondary-400" size={14} />
                <Text variant="subtle">{statusDescription}</Text>
              </>
            )}
          </span>
        </div>
      </CardHeader>
      {categoryOmitted && (
        <CardContent className="space-y-2 p-4 pt-0">
          <Text className="w-full text-left" variant="subtle">
            {description}
          </Text>
          <div className="flex w-full space-x-4 ">
            <Text className="mt-[10px] text-nowrap text-right">
              Reason for omitting category:
            </Text>
            <SelectFormField
              name={`${slug}.status`}
              className="flex-1 text-left"
              options={options}
              includeErrorMessage
            />
          </div>
        </CardContent>
      )}
    </Card>
  );
}
