import { zodResolver } from '@hookform/resolvers/zod';

import { ApiError } from '@app/api/errors';
import { useGetCompanyReportPeriods, useGetSites } from '@shared/api';
import {
  useCreateHeatActivity,
  useDeleteHeatActivity,
  useGetHeatActivity,
  useUpdateHeatActivity,
} from '@shared/api/hooks/ccf/activities/heating';
import { useGetActivityTypeUnits } from '@shared/api/hooks/ccf/units';
import { TimePeriodTypeEnum } from '@shared/api/types';
import { RepeatableFormRows } from '@shared/components/form/repeatable-form-rows';
import SelectFormField from '@shared/components/form/select-form-field';
import Loader from '@shared/components/loader';
import SimpleSelect from '@shared/components/simple-select';
import { Button } from '@shared/components/ui/button';
import { Form } from '@shared/components/ui/form';
import { useToast } from '@shared/components/ui/use-toast';
import sentry from '@shared/services/sentry';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { array, object, string, z } from 'zod';
import { useCCF } from '../../ccf-context';
import { emptyReadingRow, renderRows, useUnitOptions } from '../../utils';
import HeatReadingRow from './heating-reading-row';

const heatingFormSchema = object({
  site: string({
    required_error: 'Please provide a site',
  }).min(1),
  unit: string().min(1),
  readings: array(
    object({
      uuid: string().optional(),
      quantity: z.coerce.number().min(0),
      timePeriod: string().min(1),
    })
  ),
});

interface HeatEditFormProps {
  activityUuid?: string;
  closeDialog: () => void;
}

export default function HeatEditForm({
  activityUuid,
  closeDialog,
}: HeatEditFormProps) {
  const { uuid: reportUuid } = useCCF();
  const { toast } = useToast();
  const [timePeriod, setTimePeriod] = useState<string>('Year');

  const { data: units, isLoading: unitsIsLoading } =
    useGetActivityTypeUnits('heat');

  const unitOptions = useUnitOptions(units!);

  const { mutateAsync: createHeatActivity, isLoading: isCreating } =
    useCreateHeatActivity();
  const { mutateAsync: updateHeatActivity, isLoading: isUpdating } =
    useUpdateHeatActivity();
  const { mutateAsync: deleteHeatActivity, isLoading: isDeleting } =
    useDeleteHeatActivity();

  const isSubmitting = isCreating || isUpdating;
  const siteHasActivity = !!activityUuid;

  const { data: activity, isLoading: isActivityLoading } = useGetHeatActivity({
    reportUuid,
    heatingActivityUuid: activityUuid,
  });

  const { data: sites, isLoading: isSitesLoading } = useGetSites({
    page: 1,
    page_size: 1000,
  });

  const { data: periodOptions, isLoading: isPeriodsLoading } =
    useGetCompanyReportPeriods(reportUuid!);

  const form = useForm<z.infer<typeof heatingFormSchema>>({
    resolver: zodResolver(heatingFormSchema),
  });

  const { getValues, reset, handleSubmit } = form;

  const onSubmit = async (data: z.infer<typeof heatingFormSchema>) => {
    try {
      if (siteHasActivity) {
        await updateHeatActivity({
          companyReportUuid: reportUuid!,
          activityUuid,
          data: {
            records: data.readings.map((reading) => ({
              uuid: reading.uuid!,
              quantity: reading.quantity,
              unit: data.unit,
              timePeriod: reading.timePeriod,
            })),
          },
        });
      } else {
        await createHeatActivity({
          companyReportUuid: reportUuid!,
          data: {
            site: data.site,
            records: data.readings.map((reading) => ({
              quantity: reading.quantity,
              unit: data.unit,
              timePeriod: reading.timePeriod,
            })),
          },
        });
      }
      toast({
        title: `${siteHasActivity ? 'Updated' : 'Added'} heating data for site`,
        variant: 'success',
      });
      closeDialog();
    } catch (error) {
      sentry.log(error);
      let message;

      if (
        error instanceof ApiError &&
        error.type === 'activity-already-exists'
      ) {
        message =
          'This site already has electricity data, please update the existing records instead';
      }

      toast({
        title: `Failed to ${siteHasActivity ? 'update' : 'add'} heating and steam data`,
        description: message,
        variant: 'destructive',
      });
    }
  };

  const handleDelete = async () => {
    if (!activityUuid) return;
    try {
      await deleteHeatActivity({
        companyReportUuid: reportUuid!,
        activityUuid,
      });
      toast({
        title: 'Deleted heating data',
        variant: 'success',
      });
      closeDialog();
    } catch (error) {
      sentry.log(error);
      toast({
        title: 'Failed to delete heating data',
        variant: 'destructive',
      });
    }
  };

  const formattedSites = useMemo(
    () =>
      sites?.results?.map((site) => ({
        label: site.name,
        value: site.uuid,
      })),
    [sites]
  );

  const getRowTimePeriods = useCallback(
    (timePeriod: string) => {
      return periodOptions?.filter((period) => period.type === timePeriod);
    },
    [periodOptions]
  );

  useEffect(() => {
    if (timePeriod) {
      const rowTimePeriods = getRowTimePeriods(timePeriod);
      reset({
        site: getValues('site'),
        unit: getValues('unit'),
        readings: renderRows({
          timePeriod: timePeriod,
          unit: getValues('unit'),
          timePeriods: rowTimePeriods!,
        }),
      });
    }
  }, [getRowTimePeriods, getValues, reset, timePeriod]);

  useEffect(() => {
    if (activity) {
      reset({
        site: activity.site.uuid,
        unit: activity.records[0].unit.uuid,
        readings: activity.records.map((record) => ({
          uuid: record.uuid,
          quantity: record.quantity,
          unit: record.unit.uuid,
          timePeriod: record.timePeriod.uuid,
        })),
      });
    }
  }, [activity, activityUuid, reset]);

  if (
    isSitesLoading ||
    unitsIsLoading ||
    isPeriodsLoading ||
    (siteHasActivity && isActivityLoading)
  ) {
    return <Loader />;
  }

  return (
    <Form {...form}>
      <form className="mt-2 space-y-6" onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-12 gap-2">
          <SelectFormField
            name="site"
            label="Site"
            options={formattedSites!}
            className={'col-span-4'}
            disabled={siteHasActivity}
          />
          {!siteHasActivity && (
            <SimpleSelect
              options={Object.keys(TimePeriodTypeEnum)}
              disabled={siteHasActivity}
              value={timePeriod}
              onValueChange={(value) => {
                setTimePeriod(value);
              }}
              className="col-span-4"
              label="Time period"
            />
          )}

          <SelectFormField
            name="unit"
            label="Unit"
            options={unitOptions}
            className="col-span-4"
            disabled={siteHasActivity}
          />
        </div>
        <div>
          {(timePeriod || siteHasActivity) && (
            <RepeatableFormRows
              name="readings"
              includeErrorMessage
              showAddButton={false}
              component={HeatReadingRow}
              newRowObject={emptyReadingRow}
            />
          )}
        </div>
        <div className="mt-12 flex justify-between">
          <div className="col-span-4">
            {activityUuid && (
              <Button
                type="button"
                size="lg"
                variant="outline-destructive"
                loading={isDeleting}
                disabled={isSubmitting}
                onClick={handleDelete}
              >
                Delete heating data
              </Button>
            )}
          </div>
          <div className="flex space-x-2">
            <div className="col-span-4">
              <Button
                type="button"
                size="lg"
                variant="secondary"
                onClick={() => {
                  closeDialog();
                  reset();
                }}
                disabled={isSubmitting || isDeleting}
              >
                Cancel
              </Button>
            </div>
            <div className="col-span-4">
              <Button
                type="submit"
                size="lg"
                loading={isSubmitting}
                disabled={isSubmitting || isDeleting}
              >
                {siteHasActivity ? 'Update' : 'Add'}
              </Button>
            </div>
          </div>
        </div>
      </form>
    </Form>
  );
}
