import { Button } from '@shared/components/ui/button';
import { cn } from '@shared/lib/utils';
import { Column } from '@tanstack/react-table';
import { ClassValue } from 'clsx';

import { ArrowDown, ArrowUp } from 'lucide-react';
import { ReactNode } from 'react';

interface HeaderCellProps<TData, TValue> {
  column: Column<TData, TValue>;
  name: string | ReactNode;
  unit?: ReactNode;
  badge?: ReactNode;
  className?: ClassValue;
  textWhite?: boolean;
  numberColumn?: boolean;
}

export function HeaderCell<TData, TValue>({
  column,
  name,
  className,
  unit,
  textWhite = false,
  numberColumn = false,
  badge,
}: HeaderCellProps<TData, TValue>) {
  const headerContent = !column.getCanSort() ? (
    <div
      className={cn(
        'flex flex-wrap items-center justify-start space-x-1',
        {
          'text-gray-00': textWhite,
          'justify-end': numberColumn,
        },
        className
      )}
    >
      <p>{name}</p>
      {badge}
    </div>
  ) : (
    <Button
      variant="link"
      onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
      className={cn(
        'items-center p-0 text-muted-foreground hover:text-muted-foreground/75 hover:no-underline h-auto',
        {
          'font-bold':
            column.getIsSorted() === 'desc' || column.getIsSorted() === 'asc',
          'text-gray-00': textWhite,
        },
        className
      )}
    >
      <div
        className={cn(
          'flex flex-wrap w-full items-center justify-start space-x-1',
          {
            'justify-end': numberColumn,
          }
        )}
      >
        <p>{name}</p>
        {badge}
      </div>
      {column.getIsSorted() === 'desc' && (
        <ArrowDown strokeWidth={2.5} className="ml-2 size-4 font-bold" />
      )}
      {column.getIsSorted() === 'asc' && (
        <ArrowUp strokeWidth={2.5} className="ml-2 size-4 font-bold" />
      )}
    </Button>
  );

  return (
    <div
      className={cn('flex flex-col pb-1 pt-5', {
        'items-center': !!unit,
        'justify-end': numberColumn,
      })}
    >
      <div
        className={cn('h-5 ', {
          'text-right': numberColumn,
        })}
      >
        {headerContent}
      </div>
      <div className="h-3">{unit}</div>
    </div>
  );
}
