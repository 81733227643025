import { useCCF } from '@app/ccf/reports/ccf-context';
import Page from '@app/components/layout/page/page';
import useSimpleDialog from '@app/hooks/use-simple-dialog';
import { useGetFoodImport } from '@shared/api/hooks/ccf/imports';
import Text from '@shared/components/content/text';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FoodImportReviewTable } from '../components/table/food-import-review-table';

const ImportReviewInstructions = () => (
  <div className="space-y-3">
    <Text>
      Before completing your import, please check that the correct quantity and
      item weights have been extracted or calculated for each row.
    </Text>
    <Text>
      The <span className="font-semibold">Quantity</span>,{' '}
      <span className="font-semibold">Total weight</span> and{' '}
      <span className="font-semibold">Unit</span> columns are taken from your
      upload, the <span className="font-semibold">Items purchased</span> and{' '}
      <span className="font-semibold">Item weight</span> columns are the values
      we will actually record on your report. Highlighted rows indicate an
      estimated unit weight.
    </Text>
    <Text>
      For each row, you should:
      <ul className="list-disc pl-6">
        <li>
          Check that the <span className="font-semibold">Items purchased</span>{' '}
          and <span className="font-semibold">Item weight</span> columns are
          correct.
        </li>
        <li>
          If there is an issue with the row, click on it to view and edit the
          data, or delete the row from the import
        </li>
      </ul>
    </Text>
    <Text>
      Once you are happy with the data, click the{' '}
      <span className="font-semibold">Import data</span> button to complete your
      import.
    </Text>
  </div>
);

export default function FoodImportReviewPage() {
  const { import_uuid: importUuid } = useParams();
  const { uuid: reportUuid } = useCCF();
  const { showSimpleDialog, SimpleDialogComponent, closeSimpleDialog } =
    useSimpleDialog();
  const { data, status } = useGetFoodImport({
    importUuid: importUuid!,
    companyReportUuid: reportUuid,
  });

  useEffect(() => {
    if (status !== 'success') return;
    showSimpleDialog();
    return closeSimpleDialog;
  }, [closeSimpleDialog, status, showSimpleDialog]);

  return (
    <Page name="Scope 3 - Food and drink import" status={status}>
      <div className="mx-auto max-w-5xl">
        <FoodImportReviewTable
          cleanedData={data?.importRows || []}
          importUuid={importUuid!}
          showHelp={showSimpleDialog}
        />
      </div>
      {SimpleDialogComponent({
        header: 'Review food and drink import',
        contentClassName: 'max-w-lg',
        content: <ImportReviewInstructions />,
      })}
    </Page>
  );
}
