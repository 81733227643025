import Text from '@shared/components/content/text';
import TextList from '@shared/components/content/text-list';
import ReportContent from '../../components/report-content';

export default function Scope2CoverageAppendix() {
  return (
    <ReportContent variant="appendix" size="md">
      <>
        <Text variant="reportBody">
          Scope 2 covers indirect greenhouse gas emissions resulting from the
          consumption of purchased electricity, steam, and heating, which are
          generated off-site but used within the company’s operations.
        </Text>
        <Text variant="h4">Scope 2 categories</Text>
        <TextList className="space-y-4 text-md font-light">
          <li>
            <span className="font-medium">Electricity</span> refers to
            electricity purchased from external sources to power company
            operations. These emissions occur during the production of
            electricity and are attributed to the business based on its energy
            consumption.
          </li>
          <li>
            <span className="font-medium">Heating and steam</span> refers the
            energy purchased externally to heat buildings, support processes
            like production, or provide steam for operations. These emissions
            are generated during the production of heating or steam.
          </li>
          <li>
            <span className="font-medium">Purchased cooling</span> The My
            Emissions platform does not support emissions from purchased
            cooling. For this report, it is assumed that purchased cooling does
            not apply to the reporting company.
          </li>
        </TextList>
      </>
    </ReportContent>
  );
}
