import Text from '@shared/components/content/text';
import { capitalizeFirstLetter, cn } from '@shared/lib/utils';
import React from 'react';

interface CCFChartLegendProps extends React.HTMLAttributes<HTMLDivElement> {
  name: string;
  color: string;
}

export default function CCFChartLegend({ name, color }: CCFChartLegendProps) {
  return (
    <div className="flex items-center space-x-2">
      <div
        style={{ backgroundColor: color }}
        className={cn(`size-6 rounded-sm`)}
      ></div>
      <Text className="text-md">{capitalizeFirstLetter(name)}</Text>
    </div>
  );
}
