import { ApiError } from '@app/api/errors';
import { zodResolver } from '@hookform/resolvers/zod';
import { useGetCompanyReportPeriods, useGetSites } from '@shared/api';

import {
  useCreateMobileCombustionActivity,
  useDeleteMobileCombustionActivity,
  useGetMobileCombustionActivity,
  useUpdateMobileCombustionActivity,
} from '@shared/api/hooks/ccf/activities/mobile-combustion';
import { useGetFuels } from '@shared/api/hooks/ccf/fuels';
import { useGetActivityTypeUnits } from '@shared/api/hooks/ccf/units';
import { TimePeriodTypeEnum } from '@shared/api/types';
import { RepeatableFormRows } from '@shared/components/form/repeatable-form-rows';
import SelectFormField from '@shared/components/form/select-form-field';
import Loader from '@shared/components/loader';
import SimpleSelect from '@shared/components/simple-select';
import { Button } from '@shared/components/ui/button';
import { Form } from '@shared/components/ui/form';
import { useToast } from '@shared/components/ui/use-toast';
import sentry from '@shared/services/sentry';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { array, object, string, z } from 'zod';
import { useCCF } from '../../ccf-context';
import {
  emptyReadingRow,
  renderRows,
  useFormattedFuels,
  useFormattedSites,
  useGetRowTimePeriods,
  useUnitOptions,
} from '../../utils';
import MobileCombustionReadingRow from './mobile-combustion-records-row';

const mobileGasFormSchema = object({
  site: string().min(1).optional(),
  fuel: string().min(1),
  unit: string().min(1),
  records: array(
    object({
      uuid: string().optional(),
      quantity: z.coerce.number().min(0),
      timePeriod: string().min(1),
    })
  ),
});

interface MobileCombustionEditFormProps {
  activityUuid?: string;
  closeDialog: () => void;
}

export default function MobileCombustionEditForm({
  activityUuid,
  closeDialog,
}: MobileCombustionEditFormProps) {
  const { uuid: reportUuid } = useCCF();
  const { toast } = useToast();
  const [timePeriod, setTimePeriod] = useState<string>('Year');

  const { mutateAsync: createMobileCombustionActivity, isLoading: isCreating } =
    useCreateMobileCombustionActivity();
  const { mutateAsync: updateMobileCombustionActivity, isLoading: isUpdating } =
    useUpdateMobileCombustionActivity();
  const { mutateAsync: deleteMobileCombustionActivity, isLoading: isDeleting } =
    useDeleteMobileCombustionActivity();

  const isSubmitting = isCreating || isUpdating;
  const siteHasActivity = !!activityUuid;

  const form = useForm<z.infer<typeof mobileGasFormSchema>>({
    resolver: zodResolver(mobileGasFormSchema),
  });
  const { getValues, reset, handleSubmit, watch } = form;
  const site = watch('site');
  const fuel = watch('fuel');

  const { data: activity, isLoading: isActivityLoading } =
    useGetMobileCombustionActivity({
      reportUuid,
      mobileCombustionActivityUuid: activityUuid,
    });

  const { data: periodOptions, isLoading: isPeriodsLoading } =
    useGetCompanyReportPeriods(reportUuid!);
  const { data: sites, isLoading: isSitesLoading } = useGetSites({
    page: 1,
    page_size: 1000,
  });

  const { data: fuels, isLoading: isFuelsLoading } = useGetFuels({
    site,
    include_mobile_combustion: true,
  });

  const { data: units, isLoading: isUnitsLoading } = useGetActivityTypeUnits(
    'mobile-combustion',
    {
      site,
      fuel,
    },
    {
      enabled: !!fuel || siteHasActivity,
    }
  );

  const onSubmit = async (data: z.infer<typeof mobileGasFormSchema>) => {
    try {
      if (siteHasActivity) {
        await updateMobileCombustionActivity({
          companyReportUuid: reportUuid!,
          activityUuid,
          data: {
            records: data.records.map((reading) => ({
              uuid: reading.uuid!,
              quantity: reading.quantity,
              unit: data.unit,
              timePeriod: reading.timePeriod,
            })),
          },
        });
      } else {
        await createMobileCombustionActivity({
          companyReportUuid: reportUuid!,
          data: {
            site: data.site,
            fuel: data.fuel,
            unit: data.unit,
            records: data.records.map((reading) => ({
              quantity: reading.quantity,
              unit: data.unit,
              timePeriod: reading.timePeriod,
            })),
          },
        });
      }

      toast({
        title: `${siteHasActivity ? 'Updated' : 'Added'} mobile combustion data for site`,
        variant: 'success',
      });
      closeDialog();
    } catch (error) {
      sentry.log(error);
      let message;
      if (
        error instanceof ApiError &&
        error.type === 'activity-already-exists'
      ) {
        message =
          'A record already exists for this site/fuel, please amend that instead';
      }

      toast({
        title: `Failed to ${siteHasActivity ? 'update' : 'add'} mobile combustion data`,
        description: message,
        variant: 'destructive',
      });
    }
  };

  const handleDelete = async () => {
    if (!activityUuid) return;
    try {
      await deleteMobileCombustionActivity({
        companyReportUuid: reportUuid!,
        activityUuid,
      });
      toast({
        title: 'Deleted mobile combustion data',
        variant: 'success',
      });
      closeDialog();
    } catch (error) {
      sentry.log(error);
      toast({
        title: 'Failed to delete mobile combustion data',
        variant: 'destructive',
      });
    }
  };

  const formattedSites = useFormattedSites(sites?.results || []);
  const rowTimePeriods = useGetRowTimePeriods(timePeriod, periodOptions!);
  const formattedFuels = useFormattedFuels(fuels!);
  const formattedUnits = useUnitOptions(units!);

  useEffect(() => {
    if (timePeriod) {
      reset({
        site: getValues('site'),
        fuel: getValues('fuel'),
        unit: getValues('unit'),
        records: renderRows({
          timePeriod: timePeriod,
          unit: getValues('unit'),
          timePeriods: rowTimePeriods!,
        }),
      });
    }
  }, [getValues, reset, rowTimePeriods, timePeriod]);

  useEffect(() => {
    if (activity) {
      reset({
        site: activity.site?.uuid,
        fuel: activity.fuel.uuid,
        unit: activity.unit.uuid,
        records: activity.records.map((record) => ({
          uuid: record.uuid,
          quantity: record.quantity,
          unit: activity.unit.uuid,
          timePeriod: record.timePeriod.uuid,
        })),
      });
    }
  }, [activity, activityUuid, reset]);

  useEffect(() => {
    const selectedFuelNotInFuelsList =
      fuel && fuels && !fuels.find((f) => f.uuid === fuel);
    if (selectedFuelNotInFuelsList) {
      reset({
        site,
        fuel: null,
        unit: null,
        records: renderRows({
          timePeriod: timePeriod,
          unit: null,
          timePeriods: rowTimePeriods!,
        }),
      });
    }
  }, [fuels, fuel, reset, rowTimePeriods, timePeriod, site]);

  if (
    isSitesLoading ||
    isPeriodsLoading ||
    (siteHasActivity && (isActivityLoading || isFuelsLoading || isUnitsLoading))
  ) {
    return <Loader />;
  }

  const noFuelsAvailable = !isFuelsLoading && fuels?.length === 0;
  const noFuelSelected = !noFuelsAvailable && !fuel;

  return (
    <Form {...form}>
      <form className="mt-2 space-y-6" onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-12 gap-2">
          <SelectFormField
            name="site"
            label="Site"
            options={formattedSites!}
            className="col-span-4"
            disabled={siteHasActivity}
            optional
          />
          <SelectFormField
            name="fuel"
            label="Fuel"
            options={formattedFuels}
            disabled={siteHasActivity || isFuelsLoading || noFuelsAvailable}
            placeholder={noFuelsAvailable ? 'No fuels available' : ''}
            className="col-span-4"
          />
          {!siteHasActivity && (
            <SelectFormField
              name="unit"
              label="Unit"
              options={formattedUnits}
              className="col-span-4"
              loading={!!fuel && isUnitsLoading}
              disabled={
                siteHasActivity ||
                noFuelsAvailable ||
                noFuelSelected ||
                isUnitsLoading ||
                !units
              }
              placeholder={
                noFuelsAvailable || noFuelSelected ? 'Select a fuel first' : ''
              }
            />
          )}
        </div>
        {!siteHasActivity && (
          <div className="grid grid-cols-12 gap-2">
            <SimpleSelect
              options={Object.keys(TimePeriodTypeEnum)}
              disabled={siteHasActivity}
              value={timePeriod}
              onValueChange={(value) => {
                setTimePeriod(value);
              }}
              className="col-span-4"
              label="Time period"
            />
          </div>
        )}
        <div>
          {(timePeriod || siteHasActivity) && (
            <RepeatableFormRows
              name="records"
              includeErrorMessage
              showAddButton={false}
              component={MobileCombustionReadingRow}
              newRowObject={emptyReadingRow}
            />
          )}
        </div>
        <div className="mt-12 flex justify-between">
          <div className="col-span-4">
            {activityUuid && (
              <Button
                type="button"
                size="lg"
                variant="outline-destructive"
                loading={isDeleting}
                disabled={isSubmitting}
                onClick={handleDelete}
              >
                Delete mobile combustion data
              </Button>
            )}
          </div>
          <div className="flex space-x-2">
            <div className="col-span-4">
              <Button
                type="button"
                size="lg"
                variant="secondary"
                onClick={() => {
                  closeDialog();
                  reset();
                }}
                disabled={isSubmitting || isDeleting}
              >
                Cancel
              </Button>
            </div>
            <div className="col-span-4">
              <Button
                type="submit"
                size="lg"
                loading={isSubmitting}
                disabled={isSubmitting || isDeleting}
              >
                {siteHasActivity ? 'Update' : 'Add'}
              </Button>
            </div>
          </div>
        </div>
      </form>
    </Form>
  );
}
