import { reportSlugs } from '@app/ccf/reports/report/report-utils';
import Text from '@shared/components/content/text';
import { ChartConfig } from '@shared/components/ui/chart';
import { useCCFReportResult } from '../../ccf-report-result-context';
import CCFReportMetric from '../../components/ccf-report-metric';
import SimpleStackedBarChart from '../../components/charts/simple-stacked-bar-chart';
import ReportContent from '../../components/report-content';

export default function StationaryCombustion() {
  const {
    result: { scope1 },
  } = useCCFReportResult();

  const stationaryCombustion = scope1.categories.find(
    (category) => category.slug === reportSlugs.stationaryCombustion
  );

  const data = {
    stationary_combustion_emissions:
      stationaryCombustion?.totalCategoryEmissionsTonnes,
    share_of_scope_emissions: stationaryCombustion?.pctOfScopeEmissions,
    share_of_total_emissions: stationaryCombustion?.pctOfCompanyEmissions,
    highest_emissions_fuels:
      stationaryCombustion?.highestEmissionActivities.map((fuel) => {
        return {
          fuel: fuel.name,
          emissions: fuel.emissionsTonnes,
          fill: 'var(--chart-3)',
        };
      }),
  };

  const chartConfig = {
    emissions: {
      label: 'Emissions',
      color: 'hsl(var(--chart-1))',
    },
  } satisfies ChartConfig;

  return (
    <ReportContent>
      <>
        <Text variant="reportBody">
          Stationary combustion refers to the burning of fuels, such as natural
          gas, diesel, or propane, to produce energy on your business
          premises.{' '}
        </Text>
        <CCFReportMetric
          title="Category emissions"
          value={data.stationary_combustion_emissions!}
          unit="tCO2e"
          size="lg"
        />
        <div className="flex space-x-2">
          <CCFReportMetric
            title="Share of Scope 1 emissions"
            value={data.share_of_scope_emissions!}
            unit="%"
            size="md"
            className="flex-1"
          />
          <CCFReportMetric
            title="Share of total emissions"
            value={data.share_of_total_emissions!}
            unit="%"
            size="md"
            className="flex-1"
          />
        </div>
      </>
      <>
        <Text variant="h3">Highest emissions fuels</Text>
        <SimpleStackedBarChart
          nameKey="fuel"
          dataKey="emissions"
          chartData={data.highest_emissions_fuels}
          chartConfig={chartConfig}
          className="h-min max-h-[calc(var(--content-height)-15rem)] w-full"
          layout="vertical"
          margin={{ right: 80, left: 40 }}
          XAxisProps={{
            label: {
              value: 'tCO2e',
              position: 'right',
              offset: 20,
            },
          }}
        />
      </>
    </ReportContent>
  );
}
