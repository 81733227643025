import {
  CompanyReportCategoryStatusStatusEnum,
  CompanyReportSummaryStatusEnum,
  ScopeEnum,
} from '@shared/api/types';
import { Icons } from '@shared/components/content/icons';
import { generatePath } from 'react-router-dom';
import { ccfRoutes } from '../ccf-routes';
import { useCCF } from './ccf-context';

export type Scope3Category = 'purchased-goods';

export type ScopeCategorySlug =
  | 'stationary-combustion'
  | 'mobile-combustion'
  | 'direct-gas-emissions'
  | 'electricity'
  | 'heat'
  | 'cooling'
  | 'food';

export type ScopeCategory = {
  scope: ScopeEnum;
  scope3Category?: Scope3Category;
  name: string;
  slug: ScopeCategorySlug;
  description: string;
  href: string;
  icon: React.ReactNode;
  cardStatus: 'disabled' | 'default' | 'excluded';
  totalEmissions?: number;
  isSupported?: boolean;
  inclusionStatus?: CompanyReportCategoryStatusStatusEnum;
  status?: CompanyReportSummaryStatusEnum;
  statusDescription?: string;
  uuid?: string;
  index?: number;
};

export function useScopeCategories(): ScopeCategory[] {
  const { uuid: reportUuid } = useCCF();
  return [
    {
      scope: '1',
      name: 'Stationary combustion',
      slug: 'stationary-combustion',
      description: 'Fuels combusted directly on your sites',
      href: generatePath(ccfRoutes.STATIONARY_COMBUSTION, {
        report_uuid: reportUuid,
      }),
      icon: <Icons.flame className=" text-orange-400" size={20} />,
      cardStatus: 'disabled',
    },
    {
      scope: '1',
      name: 'Mobile combustion',
      slug: 'mobile-combustion',
      description: 'Fuels combusted in your vehicles',
      href: generatePath(ccfRoutes.MOBILE_COMBUSTION, {
        report_uuid: reportUuid,
      }),
      icon: <Icons.truck className=" text-orange-400" size={20} />,
      cardStatus: 'disabled',
    },
    {
      scope: '1',
      name: 'Direct gas emissions',
      slug: 'direct-gas-emissions',
      description: 'From fridges, AC, extinguishers and fermentation',
      href: generatePath(ccfRoutes.OTHER_GAS, {
        report_uuid: reportUuid,
      }),
      icon: <Icons.refrigerator className=" text-orange-400" size={20} />,
      cardStatus: 'disabled',
    },

    {
      scope: '2',
      name: 'Electricity',
      slug: 'electricity',
      description: 'Electricity consumed across your sites',
      href: generatePath(ccfRoutes.ELECTRICITY, {
        report_uuid: reportUuid,
      }),
      icon: <Icons.zap className=" text-orange-400" size={20} />,
      cardStatus: 'disabled',
    },
    {
      scope: '2',
      name: 'Heating and steam',
      slug: 'heat',
      description: 'Heating or steam supplied to you by an external provider',
      href: generatePath(ccfRoutes.HEATING, {
        report_uuid: reportUuid,
      }),
      icon: <Icons.thermometer className=" text-orange-400" size={20} />,
      cardStatus: 'disabled',
    },
    {
      scope: '2',
      name: 'Cooling',
      slug: 'cooling',
      description: 'Cooling supplied to you by an external provider',
      href: generatePath(ccfRoutes.HEATING, {
        report_uuid: reportUuid,
      }),
      icon: <Icons.snowflake className=" text-orange-400" size={20} />,
      cardStatus: 'disabled',
    },
    {
      scope: '3',
      scope3Category: 'purchased-goods',
      name: 'Food and drink',
      slug: 'food',
      description: 'Food and drink you have purchased',
      href: generatePath(ccfRoutes.FOOD, {
        report_uuid: reportUuid,
      }),
      icon: <Icons.carrot className=" text-orange-400" size={20} />,
      cardStatus: 'disabled',
    },
  ];
}
