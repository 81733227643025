import Text from '@shared/components/content/text';
import TextList from '@shared/components/content/text-list';
import ReportContent from '../../components/report-content';

export default function Scope3CoverageAppendix() {
  return (
    <ReportContent>
      <>
        <Text variant="reportBody">
          Scope 3 encompasses all indirect emissions that are not covered under
          Scope 2, occurring across the entire value chain. This includes both
          upstream and downstream activities, such as supply chain operations,
          business travel, and the end-of-life treatment of sold products.
          <br />
          <br />
          This report specifically addresses Scope 3, Category 1 – Purchased
          Goods and Services. This category represents indirect emissions
          resulting from the procurement of goods and services, with a
          particular focus on food and beverages. These emissions are often the
          most significant contributor to the overall carbon footprint of
          food-related businesses.
          <br />
          <br />
          The decision to concentrate on this category is in line with the WRAP
          Scope 3 GHG Measurement & Reporting Protocols: Sector Guidance for
          Food and Drink Businesses, which focuses mainly on purchased food and
          beverages as the primary source of emissions within the food sector.
        </Text>
      </>
      <>
        <Text variant="h3">Scope 3 categories</Text>
        <div className="space-y-4">
          <Text variant="reportBody">
            In line with WRAP&apos;s guidance, this report excludes certain
            Scope 3 categories that are less material to the company&apos;s
            overall carbon footprint. The following categories are excluded:
          </Text>
          <TextList className="space-y-2 text-sm">
            <li>
              <span className="font-medium">Category 2</span> - Capital goods
            </li>
            <li>
              <span className="font-medium">Category 4</span> - Upstream
              transport and distribution
            </li>
            <li>
              <span className="font-medium">Category 5</span> - Waste generated
              in operations
            </li>
            <li>
              <span className="font-medium">Category 6</span> - Business travel
            </li>
            <li>
              <span className="font-medium">Category 7</span> - Employee
              commuting
            </li>
            <li>
              <span className="font-medium">Category 8</span> - Upstream leased
              assets
            </li>
            <li>
              <span className="font-medium">Category 9</span> - Downstream
              transport and distribution
            </li>
            <li>
              <span className="font-medium">Category 10</span> - Processing of
              sold products
            </li>
            <li>
              <span className="font-medium">Category 11</span> - Use of sold
              products
            </li>
            <li>
              <span className="font-medium">Category 12</span> - End-of-life
              treatment of sold products
            </li>
            <li>
              <span className="font-medium">Category 13</span> - Downstream
              leased assets
            </li>
            <li>
              <span className="font-medium">Category 14</span> - Franchises
            </li>
            <li>
              <span className="font-medium">Category 15</span> - Investments
            </li>
          </TextList>
        </div>
      </>
    </ReportContent>
  );
}
