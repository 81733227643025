import { ccfRoutes } from '@app/ccf/ccf-routes';
import GuideTemplate from '@shared/components/guide-template';
import { useFormContext } from 'react-hook-form';
import { generatePath, useNavigate } from 'react-router-dom';
import { useCCF } from '../ccf-context';
import { useProcessScopes } from '../utils';
import { ScopeReviewRow } from './components/scope-review-row';

export default function ReportReviewScope1() {
  const navigate = useNavigate();
  const { uuid: reportUuid } = useCCF();

  const { scope1 } = useProcessScopes();
  const { trigger } = useFormContext();

  const validateScope1 = async () => {
    const isValid = await trigger(scope1.map((item) => item.slug));

    if (isValid) {
      navigate(
        generatePath(ccfRoutes.PUBLISH_REVIEW_SCOPE_2, {
          report_uuid: reportUuid,
        })
      );
    }
  };

  return (
    <GuideTemplate
      preTitle=""
      title="Review Scope 1"
      description="For each category, if there is no data please select a reason from the dropdown. For categories with data, you should check that all the relevant data has been included."
      primaryActionText="Next"
      primaryActionProps={{
        onClick: validateScope1,
      }}
      secondaryActionText="Back"
      secondaryActionProps={{
        onClick: () => {
          navigate(
            generatePath(ccfRoutes.PUBLISH, {
              report_uuid: reportUuid,
            })
          );
        },
      }}
    >
      <div className="w-[2000px] max-w-lg space-y-4">
        {scope1.map((item) => {
          return (
            <ScopeReviewRow
              key={item.slug}
              icon={item.icon}
              name={item.name}
              description={item.description}
              slug={item.slug}
              status={item.status!}
              statusDescription={item.statusDescription!}
              isSupported={item.isSupported}
            />
          );
        })}
      </div>
    </GuideTemplate>
  );
}
