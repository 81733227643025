import { ccfRoutes } from '@app/ccf/ccf-routes';
import { Icons } from '@shared/components/content/icons';
import Text from '@shared/components/content/text';
import GuideTemplate from '@shared/components/guide-template';
import { generatePath, useNavigate } from 'react-router-dom';
import { useCCF } from '../ccf-context';

function ListItem({
  icon,
  children,
}: {
  icon: React.ReactNode;
  children: React.ReactNode;
}) {
  return (
    <div className="flex items-center space-x-3 rounded-lg bg-teal-50 px-4 py-2 shadow-sm">
      <div className="shrink-0">{icon}</div>
      <Text className="text-center font-light">{children}</Text>
    </div>
  );
}

export default function ReportPublish() {
  const navigate = useNavigate();
  const { uuid: reportUuid } = useCCF();

  return (
    <GuideTemplate
      preTitle=""
      title="Publish your report"
      description={
        <Text>
          Publishing your report will take a snapshot of your data and generate
          a full report document for you to view and share.
        </Text>
      }
      primaryActionText="Get started"
      primaryActionProps={{
        onClick: () => {
          navigate(
            generatePath(ccfRoutes.PUBLISH_REVIEW_SCOPE_1, {
              report_uuid: reportUuid,
            })
          );
        },
      }}
    >
      <div className="my-4 max-w-md space-y-5">
        <ListItem icon={<Icons.barChart2 className="size-6 text-teal-500" />}>
          View your report online, or download and share with others. Download a
          full export of all your emissions data.
        </ListItem>
        <ListItem icon={<Icons.edit className="size-6 text-teal-500" />}>
          Amend your data without affecting your published report, then
          republish when you want to generate a new version.
        </ListItem>
        <ListItem icon={<Icons.calendar className=" size-6 text-teal-500" />}>
          Track the date of original publication, as well as the date the report
          was last updated.
        </ListItem>
      </div>
      <Text className="mt-10 max-w-md">
        Before publishing your report, we will ask you to review what is
        included in the report. This can help improve your compliance level.
      </Text>
    </GuideTemplate>
  );
}
