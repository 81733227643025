import { cn } from '@shared/lib/utils';
import { ClassValue } from 'clsx';
import React, { ReactNode } from 'react';

type TextListVariant = 'bullet';

interface VariantStyle {
  className: ClassValue;
}

const variantStyles: Record<TextListVariant, VariantStyle> = {
  bullet: { className: 'text-base list-disc pl-5' },
};

interface TextListProps {
  className?: ClassValue;
  children: ReactNode | ReactNode[];
  variant?: TextListVariant;
}

const TextList: React.FC<TextListProps> = ({
  className,
  children,
  variant = 'bullet',
}) => {
  const { className: variantStyle } = variantStyles[variant];
  const style = cn(variantStyle, className);
  return <ul className={style}>{children}</ul>;
};

export default TextList;
