import { PageHeader } from '@app/components/layout/page/components/page-header';
import Page from '@app/components/layout/page/page';
import { PageTabs } from '@shared/components/page-tabs';
import { formatDate } from 'date-fns';
import { generatePath, Outlet, useParams } from 'react-router-dom';
import { ccfRoutes } from '../ccf-routes';
import { useCCF } from './ccf-context';
import CCFLayoutToolbar from './components/ccf-layout-toolbar';
import { companyReportTabs } from './tabs';

export default function CCFLayout() {
  const params = useParams();
  const companyReportUuid = params.report_uuid;
  const tabs = companyReportTabs(companyReportUuid!);

  const { title, startDate, endDate, reportStatus } = useCCF();

  return (
    <Page
      name="Company carbon footprint hub"
      page="layout"
      status={reportStatus}
      flexFullHeight
    >
      <PageHeader
        name={title!}
        description={
          startDate && endDate
            ? `${formatDate(startDate, 'do MMMM yyyy')} - ${formatDate(endDate, 'do MMMM yyyy')}`
            : 'Date not available'
        }
        toolbar={<CCFLayoutToolbar />}
        tabs={<PageTabs tabs={tabs} className="mt-8" />}
        breadcrumbs={[
          {
            label: 'Company reports',
            url: generatePath(ccfRoutes.COMPANY_REPORTS),
          },
          {
            label: `${title}`,
            url: generatePath(ccfRoutes.COMPANY_REPORT, {
              report_uuid: companyReportUuid,
            }),
          },
        ]}
      />
      <Outlet />
    </Page>
  );
}
