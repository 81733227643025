import { ccfRoutes } from '@app/ccf/ccf-routes';
import { useFoodImport } from '@app/ccf/food-import/food-import-layout';
import Page from '@app/components/layout/page/page';
import { zodResolver } from '@hookform/resolvers/zod';
import { useGetCompanyReportPeriods } from '@shared/api';
import { useProcessFoodImport } from '@shared/api/hooks/ccf/imports';
import { TimePeriodTypeEnum } from '@shared/api/types';
import Text from '@shared/components/content/text';
import SelectFormField, {
  SelectOption,
} from '@shared/components/form/select-form-field';
import GuideTemplate from '@shared/components/guide-template';
import Loader from '@shared/components/loader';
import SimpleSelect from '@shared/components/simple-select';
import { Form, FormLabel } from '@shared/components/ui/form';
import sentry from '@shared/services/sentry';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { generatePath, useNavigate } from 'react-router-dom';
import { object, z } from 'zod';
import CsvImporterFood from '../components/csv-importer-food';
import { FoodImportData } from '../types';

const periodSchema = object({
  periodUuid: z.string().min(1),
});

export default function FoodImportPeriodPage() {
  const [timePeriod, setTimePeriod] = useState<string>(TimePeriodTypeEnum.year);
  const { reportUuid, reportTitle } = useFoodImport();
  const { data: periods, isLoading: isPeriodsLoading } =
    useGetCompanyReportPeriods(reportUuid);

  const { mutateAsync: processFood } = useProcessFoodImport();

  const navigate = useNavigate();

  const form = useForm<z.infer<typeof periodSchema>>({
    resolver: zodResolver(periodSchema),
  });

  const { setValue, handleSubmit } = form;

  const [importerOpen, setImporterOpen] = useState(false);
  const openImporter = () => {
    setImporterOpen(true);
  };

  const closeImporter = () => setImporterOpen(false);

  const getPeriodOptions = useMemo(() => {
    return periods
      ?.filter((period) => period.type === timePeriod)
      .map(({ uuid, name }) => ({
        value: uuid,
        label: name,
      })) as SelectOption[] | [];
  }, [periods, timePeriod]);

  const startCleanup = async (rows: FoodImportData) => {
    try {
      const importData = {
        companyReportUuid: reportUuid,
        data: {
          timePeriod: form.getValues('periodUuid'),
          rows: rows.map((row) => ({
            name: row.name,
            inputQuantity: row.quantity,
            inputTotalWeight: row.totalWeight,
            inputUnit: row.unit,
            supplier: row.supplier,
            remoteId: row.remoteId,
          })),
        },
      };

      await processFood(importData);

      navigate(
        generatePath(ccfRoutes.FOOD_IMPORTS_LIST, { report_uuid: reportUuid })
      );
    } catch (error) {
      sentry.log('Error during cleanup:', error);
    }
  };

  useEffect(() => {
    if (timePeriod == 'year' && getPeriodOptions) {
      setValue('periodUuid', getPeriodOptions[0].value);
    }
  }, [timePeriod, getPeriodOptions, form, setValue]);

  return (
    <Page name={`Food and drink - Import | ${reportTitle}`}>
      <GuideTemplate
        className="w-full overflow-visible"
        preTitle="Import food and drink purchases"
        title="Upload your spreadsheet"
        description={
          <div className="space-y-4">
            <Text>
              Assign your data to a specific time period. If not organised by
              period, upload it for the full year. You can upload data multiple
              times, even for the same period, so there is no need to do it all
              in one go.
            </Text>
          </div>
        }
        primaryActionProps={{
          disabled: isPeriodsLoading,
          onClick: handleSubmit(openImporter),
        }}
        primaryActionText="Begin upload"
        secondaryActionText="Back"
        secondaryActionProps={{
          disabled: isPeriodsLoading,
          onClick: () =>
            navigate(
              generatePath(ccfRoutes.IMPORT_DOWNLOAD, {
                report_uuid: reportUuid,
              })
            ),
        }}
      >
        {periods && (
          <Form {...form}>
            <form onSubmit={handleSubmit(openImporter)} className="text-left">
              <FormLabel>Period</FormLabel>
              <div className="mt-1 flex items-end space-x-2">
                <SimpleSelect
                  options={Object.keys(TimePeriodTypeEnum)}
                  value={timePeriod}
                  onValueChange={(value) => {
                    setTimePeriod(value);
                    setValue('periodUuid', '');
                  }}
                  disabled={isPeriodsLoading}
                  className="w-[180px]"
                />
                <SelectFormField
                  name="periodUuid"
                  className="w-[260px]"
                  options={getPeriodOptions}
                  disabled={isPeriodsLoading}
                />
              </div>
            </form>
          </Form>
        )}
        {isPeriodsLoading && <Loader />}
        <CsvImporterFood
          isOpen={importerOpen}
          onClose={closeImporter}
          handleSubmit={startCleanup}
        />
      </GuideTemplate>
    </Page>
  );
}
