import Text from '@shared/components/content/text';
import TextList from '@shared/components/content/text-list';
import ReportContent from '../../components/report-content';

export default function MethodologyOverview() {
  return (
    <ReportContent variant="column">
      <>
        <Text variant="reportBody">
          This carbon footprint assessment was completed using the{' '}
          <span className="font-semibold">My Emissions</span> platform. This
          platform complies with the{' '}
          <span className="font-semibold">
            Greenhouse Gas Protocol - Corporate Standard
          </span>{' '}
          for emissions accounting.
        </Text>
        <div className="space-y-2">
          <Text variant="reportBody">
            The assessment considers emissions across three scopes:
          </Text>
          <TextList className="space-y-2 text-md font-light">
            <li>
              <span className="font-medium">Scope 1:</span> Direct emissions
              from owned or controlled sources.
            </li>
            <li>
              <span className="font-medium">Scope 2:</span> Indirect emissions
              from the generation of purchased electricity.
            </li>
            <li>
              <span className="font-medium">Scope 3:</span> Indirect emissions
              from the supply chain, specifically focusing on purchased foods
              and drinks.
            </li>
          </TextList>
        </div>
        <div className="space-y-4">
          <Text variant="h4">Organisational boundaries</Text>
          <Text variant="reportBody">
            The assessment defines the organisational boundaries following the{' '}
            <span className="font-medium">Operational Control Approach</span>,
            which means emissions are accounted for based on the company&apos;s
            ability to direct and manage operations at its facilities. This
            ensures that emissions are attributed accurately to the
            organisation, reflecting its actual control over operations, and
            promoting consistency in reporting.
          </Text>
        </div>
      </>
      <>
        <Text variant="h3">Benefits of this methodology</Text>
        <Text variant="reportBody">
          This carbon footprint assessment methodology offers a comprehensive
          and consistent framework for measuring and reporting GHG emissions
          across all relevant scopes. It provides valuable insights into the
          company’s emissions profile and helps prioritise actions for reducing
          its environmental impact, especially in the supply chain.
        </Text>
        <TextList className="text-md font-light">
          <li>
            Provides a clear and robust accounting of emissions, supporting
            accurate emissions reporting
          </li>
          <li>
            Helps to identify areas for improvement, particularly within the
            supply chain, with a focus on food and beverage procurement.
          </li>
          <li>
            Ensures consistency and transparency in emissions reporting by
            adhering to recognized standards, such as the Greenhouse Gas
            Protocol
          </li>
        </TextList>
      </>
    </ReportContent>
  );
}
