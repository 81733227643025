/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * My Emissions API
 * We calculate food carbon footprints
 * OpenAPI spec version: 1.0.0 (v1)
 */

/**
 * * `location_based` - Location based
* `market_based` - Market based
 */
export type ReportingMethodEnum = typeof ReportingMethodEnum[keyof typeof ReportingMethodEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ReportingMethodEnum = {
  location_based: 'location_based',
  market_based: 'market_based',
} as const;
