/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * My Emissions API
 * We calculate food carbon footprints
 * OpenAPI spec version: 1.0.0 (v1)
 */

/**
 * * `included` - Included
* `no_emissions` - No emissions to report
* `not_required` - Not required to report emissions
* `not_supported` - Not supported by platform
 */
export type CompanyReportCategoryStatusStatusEnum = typeof CompanyReportCategoryStatusStatusEnum[keyof typeof CompanyReportCategoryStatusStatusEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyReportCategoryStatusStatusEnum = {
  included: 'included',
  no_emissions: 'no_emissions',
  not_required: 'not_required',
  not_supported: 'not_supported',
} as const;
